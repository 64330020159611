var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isMobile)?_c('div',{staticClass:"pa-10 pt-0"},[(_vm.$store.getters.requestType === 'DHT')?_c('div',{staticClass:"ml-4 mt-4"},[_c('b',[_vm._v("Buat Permohonan Karyawan Berhenti")])]):_vm._e(),(_vm.$store.getters.requestType === 'PRO')?_c('div',{staticClass:"ml-4 mt-4"},[_c('b',[_vm._v("Buat Permohonan Promosi Karyawan")])]):_vm._e(),(
        _vm.$store.getters.requestType === 'PRM' ||
        _vm.$store.getters.requestType === 'MUT'
      )?_c('div',{staticClass:"ml-4 mt-4"},[_c('b',[_vm._v("Buat Permohonan Mutasi Karyawan")])]):_vm._e(),_c('v-row',[_c('v-col',[_c('div',{staticClass:"mt-4 ml-4"},[_c('b',[_vm._v("DATA KARYAWAN")])])]),_c('v-col',[(_vm.$store.getters.requestType === 'PRO')?_c('div',{staticClass:"mt-4 ml-4"},[_c('b',[_vm._v("DATA USULAN PROMOSI KARYAWAN")])]):_vm._e(),(_vm.$store.getters.requestType === 'DHT')?_c('div',{staticClass:"mt-4 ml-4"},[_c('b',[_vm._v("USULAN BERHENTI")])]):_vm._e(),(
            _vm.$store.getters.requestType === 'PRM' ||
            _vm.$store.getters.requestType === 'MUT'
          )?_c('div',{staticClass:"mt-4 ml-4"},[_c('b',[_vm._v("DATA USULAN MUTASI KARYAWAN")])]):_vm._e()])],1),_c('v-row',{staticClass:"pa-5"},[_c('v-col',{staticClass:"to-be-center"},_vm._l((_vm.desktopVariables),function(variable,index){return _c('v-row',{key:index},[_c('v-col',[_vm._v(" "+_vm._s(variable.type)+" ")]),_c('v-col',[_c('v-text-field',{staticClass:"b-disabled",attrs:{"value":variable.value,"dense":"","outlined":""}})],1)],1)}),1),_c('v-col',{staticClass:"to-be-center"},[_c('v-row',[_c('v-col',[_vm._v(" TANGGAL EFEKTIF ")]),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":""}})],1)],1),(
            _vm.$store.getters.requestType === 'MUT' ||
            _vm.$store.getters.requestType === 'PRM'
          )?_c('v-row',[_c('v-col',[_vm._v(" UNIT ")]),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":""}})],1)],1):_vm._e(),(
            _vm.$store.getters.requestType === 'MUT' ||
            _vm.$store.getters.requestType === 'PRM'
          )?_c('v-row',[_c('v-col',[_vm._v(" CABANG ")]),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":""}})],1)],1):_vm._e(),(
            _vm.$store.getters.requestType === 'MUT' ||
            _vm.$store.getters.requestType === 'PRM'
          )?_c('v-row',[_c('v-col',[_vm._v(" LEVEL BAGIAN ")]),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":""}})],1)],1):_vm._e(),(_vm.$store.getters.requestType === 'PRO')?_c('v-row',[_c('v-col',[_vm._v(" JABATAN ")]),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":""}})],1)],1):_vm._e(),(
            _vm.$store.getters.requestType === 'PRO' ||
            _vm.$store.getters.requestType === 'PRM'
          )?_c('v-row',[_c('v-col',[_vm._v(" JOB CLASS ")]),_c('v-col',[_c('v-autocomplete',{attrs:{"dense":"","outlined":""}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_vm._v(" HARI KERJA ")]),_c('v-col',[_c('v-autocomplete',{attrs:{"dense":"","outlined":""}})],1)],1),_c('v-row',[_c('v-col',[_vm._v(" KETERANGAN ")]),_c('v-col',[_c('v-textarea',{attrs:{"dense":"","outlined":""}})],1)],1)],1)],1)],1):_c('div',{staticClass:"mt-10 pa-10 mobile-flex"},[(_vm.$store.getters.requestType === 'DHT')?_c('div',{staticClass:"ml-4 mt-4"},[_c('b',[_vm._v("Buat Permohonan Karyawan Berhenti")])]):_vm._e(),(_vm.$store.getters.requestType === 'PRO')?_c('div',{staticClass:"ml-4 mt-4"},[_c('b',[_vm._v("Buat Permohonan Promosi Karyawan")])]):_vm._e(),(
        _vm.$store.getters.requestType === 'PRM' ||
        _vm.$store.getters.requestType === 'MUT'
      )?_c('div',{staticClass:"ml-4 mt-4"},[_c('b',[_vm._v("Buat Permohonan Mutasi Karyawan")])]):_vm._e(),_c('div',{staticClass:"mobile-form"},[_vm._l((_vm.desktopVariables),function(variable,index){return _c('div',{key:index},[_c('v-row',{staticClass:"mt-6"},[_vm._v(" "+_vm._s(variable.type)+" ")]),_c('v-row',[_c('v-text-field',{staticClass:"b-disabled",attrs:{"dense":"","outlined":""}})],1)],1)}),(_vm.$store.getters.requestType === 'PRO')?_c('div',{staticClass:"mt-4 ml-4"},[_c('b',[_vm._v("DATA USULAN PROMOSI KARYAWAN")])]):_vm._e(),(_vm.$store.getters.requestType === 'DHT')?_c('div',{staticClass:"mt-4 ml-4"},[_c('b',[_vm._v("USULAN BERHENTI")])]):_vm._e(),(
          _vm.$store.getters.requestType === 'PRM' ||
          _vm.$store.getters.requestType === 'MUT'
        )?_c('div',{staticClass:"mt-4 ml-4"},[_c('b',[_vm._v("DATA USULAN MUTASI KARYAWAN")])]):_vm._e(),_c('v-row',[_c('v-col',[_vm._v(" TANGGAL EFEKTIF ")]),_c('v-col')],1),_c('v-row',[_c('v-text-field',{attrs:{"dense":"","outlined":""}})],1),(
          _vm.$store.getters.requestType === 'MUT' ||
          _vm.$store.getters.requestType === 'PRM'
        )?_c('v-row',[_c('v-col',[_vm._v(" UNIT ")])],1):_vm._e(),(
          _vm.$store.getters.requestType === 'MUT' ||
          _vm.$store.getters.requestType === 'PRM'
        )?_c('v-row'):_vm._e(),(
          _vm.$store.getters.requestType === 'MUT' ||
          _vm.$store.getters.requestType === 'PRM'
        )?_c('v-row',[_c('v-text-field',{attrs:{"dense":"","outlined":""}})],1):_vm._e(),(
          _vm.$store.getters.requestType === 'MUT' ||
          _vm.$store.getters.requestType === 'PRM'
        )?_c('v-row',[_c('v-col',[_vm._v(" CABANG ")])],1):_vm._e(),(
          _vm.$store.getters.requestType === 'MUT' ||
          _vm.$store.getters.requestType === 'PRM'
        )?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":""}})],1)],1):_vm._e(),(
          _vm.$store.getters.requestType === 'MUT' ||
          _vm.$store.getters.requestType === 'PRM'
        )?_c('v-row',[_c('v-col',[_vm._v(" LEVEL BAGIAN ")])],1):_vm._e(),(
          _vm.$store.getters.requestType === 'MUT' ||
          _vm.$store.getters.requestType === 'PRM'
        )?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":""}})],1)],1):_vm._e(),(_vm.$store.getters.requestType === 'PRO')?_c('v-row',[_c('v-col',[_vm._v(" JABATAN ")])],1):_vm._e(),(_vm.$store.getters.requestType === 'PRO')?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":""}})],1)],1):_vm._e(),(
          _vm.$store.getters.requestType === 'PRO' ||
          _vm.$store.getters.requestType === 'PRM'
        )?_c('v-row',[_c('v-col',[_vm._v(" JOB CLASS ")])],1):_vm._e(),(
          _vm.$store.getters.requestType === 'PRO' ||
          _vm.$store.getters.requestType === 'PRM'
        )?_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"dense":"","outlined":""}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_vm._v(" HARI KERJA ")])],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"dense":"","outlined":""}})],1)],1),_c('v-row',[_c('v-col',[_vm._v(" KETERANGAN ")])],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"dense":"","outlined":""}})],1)],1)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }