<template>
  <div>
    <div class="ma-10">
      <h2>PENGATURAN</h2>
    </div>

    <div>
      <v-tabs v-model="tabs" centered slider-color="yellow">
        <v-tab> PENGATURAN </v-tab>
        <v-tab> JENIS REQUEST </v-tab>
        <v-tab> OTORISASI </v-tab>
      </v-tabs>
      <v-tabs-items class="ml-16" v-model="tabs">
        <v-tab-item>
          <master />
        </v-tab-item>
        <v-tab-item>
          <type-request />
        </v-tab-item>
        <v-tab-item>
          <authorization />
        </v-tab-item>
      </v-tabs-items>

    </div>
  </div>
</template>

<script>
import TypeRequest from "./setting/TypeRequest.vue";
import Master from "./setting/Master.vue";
import Authorization from "./setting/Authorization.vue";
export default {
  components: {
    TypeRequest,
    Authorization,
    Master
  },
  beforeDestroy() {
    if (typeof window === 'undefined') return

    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  mounted() {
    this.onResize()

    window.addEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
  },
  data() {
    return {
      tabs: null,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      search: "",
      isMobile: false
    };
  },
};
</script>
