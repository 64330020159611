<template>
  <div class="w-100">
    <v-row class="ma-8 mt-4 mb-0 d-flex align-center">
      <v-col class="d-flex align-center justify-end" cols="12" sm="6" md="2">
        Jenis Permohonan
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-autocomplete v-model="requestType" :items="typeRequest" outlined>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="ma-8 mt-0 mb-0 d-flex align-center">
      <v-col class="d-flex align-center justify-end" cols="12" sm="6" md="2">
        Periode
      </v-col>
      <v-col class="d-flex" cols="12" md="6">
        <v-dialog
          ref="dialog1"
          v-model="startDateModal"
          :return-value.sync="startDate"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              label="Pilih Tanggal"
              prepend-icon="mdi-calendar"
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker @change="maxDate()" v-model="startDate" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="startDateModal = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.dialog1.save(startDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-dialog
          ref="dialog2"
          v-model="endDateModal"
          :return-value.sync="endDate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              label="Pilih Tanggal"
              prepend-icon="mdi-calendar"
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            :max="maxEndDate"
            :min="startDate"
            v-model="endDate"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="endDateModal = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.dialog2.save(endDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col col="12" sm="2"> </v-col>
    </v-row>
    <v-row class="ma-8 mt-0 mb-0 d-flex align-center">
      <v-col cols="12" sm="2"> </v-col>
      <v-col cols="12" sm="4">
        <v-btn
          :disabled="disabledRequest"
          color="primary"
          @click="searchAllRequest()"
          >Cari</v-btn
        >
      </v-col>
    </v-row>
    <div id="some-container" class="w-100">
      <v-card class="ma-4">
        <v-card-title class="f-14">
          <div class="d-flex justify-space-between w-100">
            <div>
              <span class="f-14">Lihat Request </span>
            </div>

            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                dense
              ></v-text-field>
            </div>
          </div>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="requestDataTable"
        >
          <template v-slot:item.requestor_number="{ item }">
            <a href="#" @click="checkForm(item.requestor_number)" dark>
              {{ item.requestor_number }}
            </a>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  setup() {},
  created() {
    this.maxDate();
  },
  methods: {
    maxDate() {
      this.endDate = null;
      let startDate = this.startDate;
      let nextThreeMonth = moment(startDate).add(3, "M").calendar();
      this.maxEndDate = moment(nextThreeMonth).format("YYYY-MM-DD");
    },
    scrollToSomeDiv() {
      document.getElementById("some-container").scrollIntoView({
        behavior: "smooth",
      });
    },
    checkForm(requestNumber) {
      return this.$router.push({ path: "ViewDetail/" + requestNumber });
    },
    searchAllRequest() {
      this.requestDataTable = [];
      this.disabledRequest = true;
      this.$store
        .dispatch("callApiPost", {
          link: "Status",
          raw: {
            code: "1",
            nikPejabat: this.$store.getters.nik,
            typereq: "SJL",
            dari: this.startDate,
            ke: this.endDate,
            role: this.$store.getters.role,
          },
        })
        .then((response) => {
          this.disabledRequest = false;
          let requestData = response.Message;
          for (let i = 0; i < requestData.length; i++) {
            this.requestDataTable.push({
              no: i + 1,
              requestor_number: requestData[i].id_dhr,
              requestor: requestData[i].pemohon,
              created_on: requestData[i].createdon,
              requestor_for: requestData[i].termohon,
              position: requestData[i].jabatan,
              department: requestData[i].bagian,
              status: requestData[i].status,
              next_pic: requestData[i].next_pic,
            });
          }
          this.scrollToSomeDiv;
        });
    },
  },
  data() {
    return {
      items: ["foo", "bar", "fizz", "buzz"],
      values: ["foo", "bar"],
      typeRequest: ["Promosi ke SJL"],
      requestType: "Promosi ke SJL",
      disabledRequest: false,
      value: null,
      startDateModal: false,
      endDateModal: false,
      search: "",
      menu: false,
      e1: 1,
      date: new Date().toISOString().substr(0, 10),
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      maxEndDate: new Date().toISOString().substr(0, 10),
      requestDataTable: [],
      headers: [
        { text: "No", value: "no" },
        { text: "No Permohonan", value: "requestor_number" },
        { text: "Pemohon", value: "requestor" },
        { text: "Tanggal Permohonan", value: "created_on" },
        { text: "Permohonan untuk", value: "requestor_for" },
        { text: "Jabatan", value: "position" },
        { text: "Bagian", value: "department" },
        { text: "Status", value: "status" },
        { text: "PIC Approval", value: "next_pic" },
      ],
      scoreHeaders: [
        { text: "Nomor", value: "calories" },
        { text: "Periode Penilaian", value: "fat" },
        { text: "Kategori", value: "carbs" },
      ],
      trainingHeaders: [
        { text: "No", value: "calories" },
        { text: "Tanggal Training", value: "fat" },
        { text: "Judul Training", value: "carbs" },
        { text: "Nilai", value: "protein" },
        { text: "Keterangan", value: "iron" },
      ],
      desserts: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: "1%",
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: "1%",
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: "7%",
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: "8%",
        },
        {
          name: "Gingerbread",
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: "16%",
        },
        {
          name: "Jelly bean",
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: "0%",
        },
        {
          name: "Lollipop",
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: "2%",
        },
        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: "45%",
        },
        {
          name: "Donut",
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: "22%",
        },
        {
          name: "KitKat",
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: "6%",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.shrink.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none !important;
}
.w-20 {
  width: 20%;
}
.w-51 {
  width: 51%;
}
body {
  font-size: 12px;
}
.f-14 {
  font-size: 14px !important;
}
.f-12 {
  font-size: 12px !important;
}
.choose-date.v-input__slot {
  width: 50% !important;
}
.v-text-field__slot input {
  font-size: 12px !important;
}
.choose-date.v-autocomplete {
  width: 75% !important;
}
.v-text-field__slot label {
  font-size: 10px !important;
}
</style>