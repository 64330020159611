<template>
  <div>
    <h3>MASTER JENIS REQUEST</h3>

    <v-row class="ma-4">
      <v-col class="d-flex align-center" md="2"> ID Jenis Request</v-col>
      <v-col md="4"> <v-combobox dense outlined></v-combobox></v-col>
    </v-row>
    <v-row class="ma-4">
      <v-col class="d-flex align-center" md="2">Jenis Permohonan</v-col>
      <v-col md="4"> <v-text-field outlined dense></v-text-field></v-col>
    </v-row>
    <v-row class="ma-4">
      <v-col class="d-flex align-center" md="2"> Group</v-col>
      <v-col md="4"> <v-text-field outlined dense></v-text-field></v-col>
    </v-row>
    <v-row class="ma-4">
      <v-col class="d-flex align-center" md="2"></v-col>
      <v-col md="4">
        <v-btn tile color="success">
          <v-icon left> mdi-content-save </v-icon>
          Simpan
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-end">
      <v-col md="10"> </v-col>
      <v-col class="ma-4">
        <v-text-field
          dense
          outlined
          v-model="search"
          append-icon="mdi-magnify"
          label="Pencarian"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :search="search"
        ></v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: null,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      search: "",
      headers: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "ID Jenis", value: "calories" },
        { text: "Jenis Permohonan", value: "fat" },
        { text: "Group", value: "carbs" },
        { text: "Tanggal Input", value: "protein" },
        { text: "Tanggal Update", value: "iron" },
        { text: "Opsi Action", value: "iron" },
      ],
    };
  },
};
</script>
