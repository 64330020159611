<template>
  <div>
    <div v-if="!isMobile">
      <EmployeeDetailForm :employeeDetail="employeeDetail">
      </EmployeeDetailForm>
      <div>
        <div class="silver pa-1 pl-4 mt-4 d-flex justify-center">
          <span class="font-weight-bold white--text mr-14"
            >Data Usulan Perubahan</span
          >
        </div>
        <v-row class="mt-2" col="12">
          <v-col class="d-flex" md="6">
            <v-row cols="6">
              <v-col class="d-flex justify-center" md="6">
                <span>Data Saat Ini</span>
              </v-col>
              <v-col md="3">
                <span></span>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="d-flex justify-center" md="6">
            <v-row cols="6">
              <v-col class="d-flex" md="3">
                <span>Data Usulan</span>
              </v-col>
              <v-col md="3">
                <span></span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div class="d-flex">
          <div class="col-5">
            <div class="d-flex mb-4 align-center align-center">
              <div class="text-right w-40">Status Kerja:</div>

              <v-text-field
                class="b-disabled"
                :value="employeeDetail.STATUS_KERJA"
                dense
                disabled
                outlined
              >
              </v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Hari Kerja:</div>
              <v-text-field
                class="b-disabled"
                :value="changeShiftValue(employeeDetail.ID_GROUP_SHIFT)"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Unit:</div>
              <v-text-field
                class="b-disabled"
                :value="employeeDetail.Unit"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Bagian:</div>
              <v-text-field
                class="b-disabled"
                :value="employeeDetail.BAGIAN + '-' + employeeDetail.NAMA_TOKO"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Kelas Toko</div>
              <v-text-field
                class="b-disabled"
                v-model="firstStoreStatus"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Jabatan:</div>
              <v-text-field
                :value="employeeDetail.JABATAN"
                class="b-disabled"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>

            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Job Class:</div>
              <v-text-field
                class="b-disabled"
                :value="employeeDetail.JOB_CLASS"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Area Manager:</div>
              <v-text-field
                class="b-disabled"
                :value="employeeDetail.NAMA_AM"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
          </div>
          <div class="col-5">
            <div class="d-flex mb-4 align-center align-center">
              <div class="text-right w-40">Status Kerja:</div>

              <v-combobox
                outlined
                v-if="showFormEdit()"
                disabled
                :v-model="contractType"
                :value="$store.getters.employeeData.status_kerja"
                placeholder="Pilih Status Kerja"
                :items="$store.getters.contractTypeSelect"
                item-text="name"
                dense
                solo
              ></v-combobox>
              <v-text-field
                class="b-disabled"
                outlined
                dense
                v-if="showFormDisabled()"
                :value="$store.getters.employeeData.status_kerja"
                disabled
              />
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Hari Kerja:</div>
              <v-autocomplete
                v-if="showFormEdit()"
                outlined
                v-model="shift"
                :disabled="$store.getters.role != 'USER'"
                placeholder="Pilih Hari Kerja"
                :items="$store.getters.shiftSelect"
                item-text="name"
                item-value="value"
                dense
                solo
              ></v-autocomplete>
              <v-text-field
                v-if="showFormDisabled()"
                :value="
                  changeShiftValue($store.getters.employeeData.hari_kerja)
                "
                :disabled="true"
                dense
                outlined
                class="b-disabled"
              >
              </v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Unit:</div>
              <v-autocomplete
                v-if="showFormEdit()"
                outlined
                :disabled="$store.getters.role != 'USER'"
                :v-model="unit"
                placeholder="Pilih Unit"
                v-bind:value="$store.getters.employeeData.unit"
                :items="$store.getters.unitSelect"
                item-value="value"
                item-text="name"
                dense
                solo
              ></v-autocomplete>
              <v-text-field
                v-if="showFormDisabled()"
                v-bind:value="
                  changeCompanyName($store.getters.employeeData.unit)
                "
                :disabled="true"
                dense
                outlined
                class="b-disabled"
              >
              </v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Bagian:</div>
              <v-text-field
                v-if="showFormEdit()"
                v-model="storeCodeFinal"
                @click:append="dialog = true"
                append-icon="mdi-magnify"
                @mouseover="showBranch = true"
                @mouseleave="showBranch = false"
                :disabled="$store.getters.role != 'USER'"
                dense
                outlined
                class="b-disabled"
              >
              </v-text-field>
              <v-text-field
                v-if="showFormDisabled()"
                v-model="storeCodeFinal"
                @mouseover="showBranch = true"
                @mouseleave="showBranch = false"
                :disabled="true"
                dense
                outlined
                class="b-disabled"
              >
              </v-text-field>
              <div>
                <v-row justify="center">
                  <v-dialog v-model="dialog" scrollable max-width="40rem">
                    <v-card>
                      <v-card-title>Pilih Bagian</v-card-title>
                      <v-divider class="mb-4"></v-divider>
                      <v-card-text style="height: 60vh">
                        <div class="d-flex align-center">
                          <div class="d-flex justify-end w-20">Kode Toko</div>
                          <v-text-field
                            label="Ketik Nama/ Kode Toko"
                            class="ml-2"
                            v-model="storeCode"
                            dense
                            outlined
                          >
                          </v-text-field>
                        </div>
                        <div class="d-flex align-center">
                          <div class="d-flex justify-end w-20">Cabang</div>
                          <v-autocomplete
                            outlined
                            class="choose-branch mt-2 ml-2"
                            label="Pilih Cabang"
                            @change="changeDisabled"
                            v-model="branchIndex"
                            item-value="id"
                            return-object
                            item-text="name"
                            :items="branches"
                            dense
                          >
                          </v-autocomplete>
                        </div>
                        <div class="w-20 d-flex mt-4 w-34 justify-end">
                          <v-btn
                            :disabled="isBranchEmpty"
                            @click="searchDepartment"
                            color="primary mt-2 rounded-lg "
                          >
                            Cari</v-btn
                          >
                        </div>
                        <div class="overflow-table">
                          <v-row class="border-table">
                            <v-col cols="12" md="4"> Kode Toko </v-col>
                            <v-col cols="12" md="8"> Nama Toko </v-col>
                          </v-row>
                          <v-row
                            v-if="isBranchLoading"
                            class="d-flex justify-center mt-10"
                          >
                            <v-progress-circular
                              :size="150"
                              color="primary"
                              indeterminate
                            >
                            </v-progress-circular>
                          </v-row>
                          <v-row
                            v-for="(code, index) in storeCodeBranches"
                            v-bind:key="index"
                          >
                            <v-col cols="12" md="4">{{ code.KODETOKO }}</v-col>
                            <v-col
                              class="d-flex justify-space-between align-center"
                              cols="12"
                              md="8"
                            >
                              <div>
                                <span>{{ code.NAMATOKO }}</span>
                              </div>
                              <v-btn @click="saveDepartment(code)">
                                Pilih
                              </v-btn>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialog = false"
                        >
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </div>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Kelas Toko:</div>
              <v-text-field
                class="b-disabled"
                v-model="nextStoreStatus"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
            <div v-show="showBranch" class="hover-class">
              <p>{{ storeCodeFinal }}</p>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Jabatan:</div>
              <v-combobox
                @change="checkIsTrained()"
                v-if="isComboBox"
                oulined
                v-model="position"
                :items="positionType"
                item-text="name"
                item-value="name"
                solo
                :return-object="false"
              ></v-combobox>
              <v-text-field
                v-if="!isComboBox"
                class="b-disabled"
                v-model="position"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>

            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Job Class:</div>
              <v-autocomplete
                v-if="showFormEdit()"
                outlined
                v-model="jobClass"
                disabled
                placeholder="Pilih Job Class"
                :items="$store.getters.jobClassSelect"
                item-text="name"
                item-value="name"
                dense
                solo
              ></v-autocomplete>
              <v-text-field
                v-if="showFormDisabled()"
                class="b-disabled"
                v-model="jobClass"
                dense
                disabled
                outlined
              >
              </v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Tanggal Efektif:</div>
              <div @click="giveWarning()">
                <v-dialog ref="dialog" v-model="modal" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-if="showFormEdit()"
                      :value="computedDateFormattedMomentjs"
                      :disabled="disableDate || $store.getters.role != 'USER'"
                      label="Picker in dialog"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :min="minDate()"
                    :max="maxDate()"
                    @change="isLastDay()"
                    v-model="effectiveDate"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </div>
              <v-text-field
                v-if="showFormDisabled()"
                class="b-disabled"
                v-model="effectiveDate"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-height" v-if="isMobile">
      <div>
        <EmployeeDetailFormMobile :employeeDetail="employeeDetail">
        </EmployeeDetailFormMobile>
      </div>
      <div class="silver pa-1 pl-4 mt-4 d-flex">
        <span class="font-weight-bold white--text mr-14"
          >Data Usulan Perubahan</span
        >
      </div>
      <div class="pb-5 mt-4">Data Saat Ini</div>
      <v-row>
        <v-col class="align-center d-flex" cols="12" sm="3" md="2">
          <div>
            <span class="font-weight-bold"> Status Kerja</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class="align-center d-flex">
          <v-text-field
            class="b-disabled text-right-form"
            :value="employeeDetail.STATUS_KERJA"
            dense
            disabled
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex" cols="12" sm="3" md="2">
          <div>
            <span class="font-weight-bold"> Hari Kerja </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class="align-center d-flex">
          <v-text-field
            class="b-disabled text-right-form"
            :value="changeShiftValue(employeeDetail.ID_GROUP_SHIFT)"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex" cols="12" sm="3" md="2">
          <div>
            <span class="font-weight-bold"> Unit </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class="align-center d-flex">
          <v-text-field
            class="b-disabled text-right-form"
            :value="employeeDetail.Unit"
            dense
            disabled
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex" cols="12" sm="3" md="2">
          <div>
            <span class="font-weight-bold"> Bagian </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class="align-center d-flex">
          <v-text-field
            class="b-disabled text-right-form"
            :value="employeeDetail.BAGIAN + '-' + employeeDetail.NAMA_TOKO"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex" cols="12" sm="3" md="2">
          <div>
            <span class="font-weight-bold"> Kelas Toko </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class="align-center d-flex">
          <v-text-field
            class="b-disabled text-right-form"
            v-model="firstStoreStatus"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="align-center d-flex" cols="12" sm="3" md="2">
          <div>
            <span class="font-weight-bold"> Jabatan </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class="align-center d-flex">
          <v-text-field
            class="b-disabled text-right-form"
            :value="employeeDetail.JABATAN"
            dense
            disabled
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex" cols="12" sm="3" md="2">
          <div>
            <span class="font-weight-bold"> Job Class </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class="align-center d-flex">
          <v-text-field
            class="b-disabled text-right-form"
            :value="employeeDetail.JOB_CLASS"
            dense
            disabled
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex" cols="12" sm="3" md="2">
          <div>
            <span class="font-weight-bold"> Area Manajer </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class="align-center d-flex">
          <v-text-field
            class="b-disabled text-right-form"
            :value="employeeDetail.NAMA_AM"
            dense
            disabled
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <div class="pa-8 pl-2">Data Usulan</div>
      <v-row>
        <v-col class="align-center d-flex" cols="12" sm="3" md="2">
          <div>
            <span class="font-weight-bold"> Hari Kerja </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class="align-center d-flex">
          <v-combobox
            outlined
            v-if="showFormEdit()"
            disabled
            :v-model="contractType"
            :value="$store.getters.employeeData.status_kerja"
            placeholder="Pilih Status Kerja"
            :items="$store.getters.contractTypeSelect"
            item-text="name"
            dense
            solo
          ></v-combobox>
          <v-text-field
            class="b-disabled text-right-form"
            outlined
            dense
            v-if="showFormDisabled()"
            :value="$store.getters.employeeData.status_kerja"
            disabled
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex" cols="12" sm="3" md="2">
          <div>
            <span class="font-weight-bold"> Unit </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class="align-center d-flex">
          <v-autocomplete
            v-if="showFormEdit()"
            outlined
            :disabled="$store.getters.role != 'USER'"
            :v-model="unit"
            placeholder="Pilih Unit"
            v-bind:value="$store.getters.employeeData.unit"
            :items="$store.getters.unitSelect"
            item-value="value"
            item-text="name"
            dense
            solo
          ></v-autocomplete>
          <v-text-field
            v-if="showFormDisabled()"
            v-bind:value="changeCompanyName($store.getters.employeeData.unit)"
            :disabled="true"
            dense
            outlined
            class="b-disabled"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex" cols="12" sm="3" md="2">
          <div>
            <span class="font-weight-bold"> Bagian </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class="align-center d-flex">
          <v-text-field
            v-if="showFormEdit()"
            v-model="storeCodeFinal"
            @click:append="dialog = true"
            append-icon="mdi-magnify"
            @mouseover="showBranch = true"
            @mouseleave="showBranch = false"
            :disabled="$store.getters.role != 'USER'"
            dense
            outlined
            class="b-disabled text-right-form"
          >
          </v-text-field>
          <v-text-field
            v-if="showFormDisabled()"
            v-model="storeCodeFinal"
            @mouseover="showBranch = true"
            @mouseleave="showBranch = false"
            :disabled="true"
            dense
            outlined
            class="b-disabled text-right-form"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="dialog" width="15rem" scrollable>
          <v-card>
            <v-card-title>Pilih Bagian</v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 40vh">
              <v-row>
                <div class="d-flex mt-10">Kode Toko</div>
              </v-row>
              <v-row>
                <v-text-field
                  label="Ketik Nama/ Kode Toko"
                  class="ml-2"
                  v-model="storeCode"
                  dense
                  outlined
                >
                </v-text-field>
              </v-row>
              <v-row class="d-flex align-center">
                <div class="d-flex">Cabang</div>
              </v-row>
              <v-row>
                <v-autocomplete
                  outlined
                  class="choose-branch mt-2 ml-2"
                  label="Pilih Cabang"
                  @change="changeDisabled"
                  v-model="branchIndex"
                  item-value="id"
                  return-object
                  item-text="name"
                  :items="branches"
                  dense
                >
                </v-autocomplete>
              </v-row>
              <div class="w-20 d-flex mt-4 w-34">
                <v-btn
                  :disabled="isBranchEmpty"
                  @click="searchDepartment"
                  color="primary mt-2 rounded-lg "
                  >Cari</v-btn
                >
              </div>
              <div class="overflow-table">
                <v-row class="border-table">
                  <v-col> Kode Toko </v-col>
                  <v-col> Nama Toko </v-col>
                </v-row>
                <v-row
                  v-if="isBranchLoading"
                  class="d-flex justify-center mt-10"
                >
                  <v-progress-circular
                    :size="150"
                    color="primary"
                    indeterminate
                  >
                  </v-progress-circular>
                </v-row>
                <v-row
                  v-for="(code, index) in storeCodeBranches"
                  v-bind:key="index"
                >
                  <v-col cols="12" md="4">{{ code.KODETOKO }}</v-col>
                  <v-col
                    class="d-flex justify-space-between align-center"
                    cols="12"
                    md="8"
                  >
                    <div>
                      <span>{{ code.NAMATOKO }}</span>
                    </div>
                    <v-btn @click="saveDepartment(code)"> Pilih </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex" cols="12" sm="3" md="2">
          <div>
            <span class="font-weight-bold"> Kelas Toko </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class="align-center d-flex">
          <v-text-field
            class="b-disabled text-right-form"
            v-model="nextStoreStatus"
            dense
            disabled
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex" cols="12" sm="3" md="2">
          <div>
            <span class="font-weight-bold"> Jabatan</span>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="3" md="3" class="align-center d-flex">
          <v-combobox
            @change="checkIsTrained()"
            v-if="isComboBox"
            oulined
            v-model="position"
            :items="positionType"
            item-text="name"
            item-value="name"
            solo
            :return-object="false"
          ></v-combobox>
          <v-text-field
            v-else
            class="b-disabled text-right-form"
            v-model="position"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex" cols="12" sm="3" md="2">
          <div>
            <span class="font-weight-bold"> Job Class </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class="align-center d-flex">
          <v-autocomplete
            v-if="showFormEdit()"
            outlined
            v-model="jobClass"
            disabled
            placeholder="Pilih Job Class"
            :items="$store.getters.jobClassSelect"
            item-text="name"
            item-value="name"
            dense
            solo
          ></v-autocomplete>
          <v-text-field
            v-if="showFormDisabled()"
            class="b-disabled"
            v-model="jobClass"
            dense
            disabled
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex" cols="12" sm="3" md="2">
          <div>
            <span class="font-weight-bold"> Tanggal Efektif</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class="align-center d-flex">
          <div @click="giveWarning()">
            <v-dialog ref="dialog" v-model="modal" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-if="showFormEdit()"
                  :value="computedDateFormattedMomentjs"
                  :disabled="disableDate || $store.getters.role != 'USER'"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="minDate()"
                :max="maxDate()"
                @change="isLastDay()"
                v-model="effectiveDate"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>
          <v-text-field
            v-if="showFormDisabled()"
            class="b-disabled text-right-form"
            v-model="effectiveDate"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import EmployeeDetailForm from "../employee/DetailForm.vue";
import EmployeeDetailFormMobile from "../employee/DetailFormMobile.vue";
import moment from "moment";
import { mapActions } from "vuex";
export default {
  computed: {
    computedDateFormattedMomentjs: {
      cache: false,
      get() {
        return this.effectiveDate
          ? moment(this.effectiveDate).format("DD MMMM YYYY")
          : "";
      },
    },
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  data() {
    return {
      contractType: "",
      work_status: "",
      positionType: [
        { value: "Store Jr. Leader", name: "Store Jr. Leader" },
        { value: "Store Jr. Leader (Ss)", name: "Store Jr. Leader (Ss)" },
      ],
      shift: this.$store.getters.employeeData.hari_kerja,
      dialog: false,
      disableDate: false,
      newPosition: "",
      codeTitle:
        this.$store.getters.employeeData.jabatan == "Store Jr. Leader (Ss)"
          ? "N7K"
          : "N7",
      nextStoreStatus: "",
      branchIndex: "",
      isSpec: false,
      isSpecial: 0,
      isBranchEmpty: true,
      isSJLUpdated: false,
      isMobile: false,
      isDefault: true,
      jobClass: "",
      unit: "",
      position: this.$store.getters.employeeData.jabatan,
      overlay: true,
      storeCodeBranches: "",
      showBranch: false,
      storeCode: "",
      modal: false,
      branches: [],
      isBranchLoading: false,
      firstStoreStatus: "",
      isComboBox: false,
    };
  },
  components: {
    EmployeeDetailForm,
    EmployeeDetailFormMobile,
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  props: ["employeeDetail", "DHRData"],
  methods: {
    ...mapActions(["setEfectiveDate", "setStoreCodeFinal"]),
    isComboboxActive() {
      if (
        (this.employeeDetail.JABATAN == "Store Crew Boy" ||
          this.employeeDetail.JABATAN == "Store Crew Girl") &&
        this.nextStoreStatus != "Special Store" &&
        this.$store.getters.role == "USER"
      ) {
        console.log("masukkkk");
        this.isComboBox = true;
      } else {
        this.isComboBox = false;
      }
    },
    giveWarning() {
      if (this.disableDate === true && this.$store.getters.role == "USER") {
        this.$swal(
          "Tanggal tidak bisa diubah, karena bagian tidak berubah, Permohonan SJL hanya dianggap sebagai transaksi Promosi dengan tanggal efektif setiap tanggal 1 awal bulan"
        );
      }
    },
    checkIsTrained() {
      let self = this;
      self.isSJLUpdated = true;
      if (self.position == "Store Jr. Leader (Ss)") {
        this.$store
          .dispatch("callApiPost", {
            link: "Karyawan",
            raw: {
              code: "3",
              nik: this.employeeDetail.NIK,
            },
          })
          .then((response) => {
            self.isTrained = response.Message;
            if (this.isTrained == "") {
              this.$store.dispatch("setIsTrained", false);
              return this.$swal({
                title: "Peringatan !",
                html:
                  "Karyawan " +
                  this.employeeDetail.NAMA +
                  " dengan NIK " +
                  this.employeeDetail.NIK +
                  " Belum ada data history training SS untuk dimutasikan ke Special Store " +
                  "<strong>" +
                  this.storeCodeFinal +
                  "</strong>" +
                  "<br/> , " +
                  "Jika karyawan sudah mengikuti training, harap update data history training melalui menu upload training di DHR Online (Lama), kemudian proses ulang untuk NIK tersebut <br/>  ",
                showCloseButton: true,
              });
            } else {
              this.$store.dispatch("setIsTrained", true);
            }
          });
      } else {
        this.$store.dispatch("setIsTrained", true);
      }
    },
    checkIfStoreIsSpecial() {
      this.$store
        .dispatch("callApiPost", {
          link: "Bagian",
          raw: {
            code: "3",
            keyword: this.$store.getters.employeeData.bagian_id,
          },
        })
        .then((response) => {
          console.log(response.Message[0]);
          if (
            response.Message[0].Tipe === "spec" &&
            (this.employeeDetail.JABATAN == "Store Crew Boy (Ss)" ||
              this.employeeDetail.JABATAN == "Store Crew Girl (Ss)")
          ) {
            this.isSpec = false;
            this.nextStoreStatus = "Special Store";
            this.position = "Store Jr.Leader (Ss)";
            this.$store.dispatch("setIsNextStoreSpecial", 1);
            this.checkIfCurrentEmployeeHasWorkedFor6Months();
          } else if (
            response.Message[0].Tipe === "spec" &&
            (this.employeeDetail.JABATAN == "Store Crew Boy" ||
              this.employeeDetail.JABATAN == "Store Crew Girl")
          ) {
            this.isSpec = true;
            this.nextStoreStatus = "Special Store";
            this.position = "Store Jr.Leader (Ss)";
            this.$store.dispatch("setIsNextStoreSpecial", 1);
            this.checkIfCurrentEmployeeHasWorkedFor6Months();
          } else {
            this.nextStoreStatus = "Non Special Store";
            this.$store.dispatch("setIsNextStoreSpecial", 0);
          }
        });
    },
    firstStoreStatusMethod() {
      if (this.employeeDetail.SpecialStore === "Y") {
        this.firstStoreStatus = "Special Store";
      } else if (this.employeeDetail.SpecialStore === "N") {
        this.firstStoreStatus = "Non Special Store";
      } else {
        this.firstStoreStatus = "-";
      }
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    changeCompanyName(companyName) {
      if (companyName === "IDM") {
        return "PT INDOMARCO PRISMATAMA";
      } else {
        return "PT ALBANY CORONA LESTARI";
      }
    },
    showFormDisabled() {
      if (
        this.$route.name === "ViewDetail" ||
        this.$store.getters.role != "USER"
      ) {
        return true;
      }
      return false;
    },

    showFormEdit() {
      if (
        this.$route.name !== "ViewDetail" &&
        this.$store.getters.role === "USER"
      ) {
        return true;
      }
      return false;
    },
    changeShiftValue(shift) {
      if (shift === "6S") {
        return "6 Hari-Shift";
      } else if (shift === "5S") {
        return "5 Hari Shift";
      } else {
        return shift;
      }
    },
    getAllCabang() {
      this.$store
        .dispatch("callApiPost", {
          link: "Bagian",
          raw: {
            code: "2",
          },
        })
        .then((response) => {
          let branches = response.Message;

          for (let i = 0; i < branches.length; i++) {
            this.branches.push({
              name: branches[i].AreaName,
              id: branches[i].id,
            });
          }
        });
    },
    searchDepartment() {
      this.items = [];
      this.storeCodeBranches = [];
      this.isBranchLoading = true;
      this.$store
        .dispatch("callApiPost", {
          link: "Bagian",
          raw: {
            code: "1",
            keyword: this.storeCode,
            isi: "'R'",
            action: "not in",
            cabang: this.branchIndex.name,
          },
        })
        .then((response) => {
          let kodeToko = response.Message;
          this.storeCodeBranches = kodeToko;
          this.isBranchLoading = false;
        });
    },
    changeDefaultToTrue() {
      this.isShiftDefault = true;
    },
    async updateDHR() {
      
      if (this.position === "Store Jr. Leader (Ss)") {
        this.codeTitle = "N7K";
      } else if (this.position == "Store Jr. Leader") {
        this.codeTitle = "N7";
      }
      console.log(this.codeTitle)
      if (this.isSJLUpdated != true) {
        this.position = this.$store.getters.employeeData.jabatan;
      }
      if (this.isDefault === true) {
        this.bagian = this.$store.getters.employeeData.bagian;
        this.bagian_id = this.$store.getters.employeeData.bagian_id;
        this.tokid = this.$store.getters.employeeData.tokid;
        this.cabang = this.$store.getters.employeeData.cabang;
      } else {
        this.cabang = this.branchIndex.name;
      }

      await this.$store
        .dispatch("callApiPost", {
          link: "Transaksi",
          raw: {
            code: 10,
            nik: this.employeeDetail.NIK,
            id_dhr: this.$store.getters.id_dhr,
            isRSM: 1,
            bagian_id: this.bagian_id,
            cabang: this.cabang,
            bagian: this.bagian,
            tokid: this.tokid,
            effective_date: this.effectiveDate,
            hari_kerja: this.shift,
            jabatan: this.position,
            kode_jabatan: this.codeTitle,
            isSpecial: this.isSpecial,
            nikPejabat: this.$store.getters.nik,
          },
        })
        .then((response) => {
          console.log(response);
        });
    },
    changeDisabled() {
      if (this.branchIndex != [] && this.storeCodeIndex != " ") {
        this.isBranchEmpty = false;
      }
    },
    minDate() {
      let currentDate = new Date().toISOString().substr(0, 10);
      return moment(currentDate).format("YYYY-MM-DD");
    },
    maxDate() {
      let currentDate = new Date().toISOString().substr(0, 10);

      let nextMonth = moment(currentDate).add(30, "days").calendar();
      return moment(nextMonth).format("YYYY-MM-DD");
    },
    isLastDay() {
      this.checkIfCurrentEmployeeHasWorkedFor6Months();
      let firstDateOfCurrentMonth = moment()
        .clone()
        .startOf("month")
        .format("YYYY-MM-DD");
      let firstDateOfNextMonthUnformatted = moment(firstDateOfCurrentMonth)
        .add(1, "M")
        .format("YYYY-MM-DD");

      let lastdayOfTheMonth = moment().endOf("month").format("YYYY-MM-DD");
      let lastdayOfNextMonth = moment(firstDateOfNextMonthUnformatted)
        .endOf("month")
        .format("YYYY-MM-DD");
      if (moment(this.effectiveDate) > moment()) {
        this.$store.dispatch("setEffDateValid", true);
      }
      if (
        this.effectiveDate == lastdayOfTheMonth ||
        this.effectiveDate == lastdayOfNextMonth
      ) {
        this.$swal(
          "Tanggal efektif mutasi tidak dapat diproses diakhir bulan, karena akan sama dengan tanggal efektif terminasi, silakan pilih tanggal lainnya"
        );
        this.effectiveDate = firstDateOfNextMonthUnformatted;
        this.modal = false;
      }
    },
    checkIfCurrentEmployeeHasWorkedFor6Months() {
      let joinDateUnformatted = moment(
        this.employeeDetail.TANGGAL_BERGABUNG
      ).format("YYYY-MM-DD");
      let joinDate = moment(joinDateUnformatted, "YYYY-MM-DD");

      let effectiveDate = moment(this.effectiveDate, "YYYY-MM-DD");
      let comparison = moment.duration(effectiveDate.diff(joinDate)).asDays();
      if (
        this.employeeDetail.STATUS_KERJA == "Kontrak 1" &&
        this.nextStoreStatus === "Special Store"
      ) {
        if (comparison < 180) {
          this.$store.dispatch("setIs6Months", false);
        } else {
          this.$store.dispatch("setIs6Months", true);
        }
      } else {
        this.$store.dispatch("setIs6Months", true);
      }
    },
    saveDepartment(storeCode) {
      this.dialog = false;
      this.disableDate = false;
      this.storeCodeFinal = "";
      this.isSJLUpdated = true;

      this.storeCodeFinal = storeCode.KODETOKO + "-" + storeCode.NAMATOKO;

      this.isDefault = false;
      this.bagian = storeCode.NAMATOKO;
      this.bagian_id = storeCode.KODETOKO;
      if (
        storeCode.Tipe == "spec" &&
        (this.employeeDetail.JABATAN == "Store Crew Boy" ||
          this.employeeDetail.JABATAN == "Store Crew Girl")
      ) {
        this.isSpec = true;
        this.nextStoreStatus = "Special Store";
        this.position = "Store Jr. Leader (Ss)";
        this.codeTitle= "N7K";
        this.isSpecial = 1;
        this.isComboBox = false;
      } else if (
        storeCode.Tipe !== "spec" &&
        (this.employeeDetail.JABATAN == "Store Crew Boy (Ss)" ||
          this.employeeDetail.JABATAN == "Store Crew Girl (Ss)")
      ) {
        this.isSpec = false;
        if (storeCode.Tipe === "reg") {
          this.nextStoreStatus = "Non Special Store";
        } else {
          this.nextStoreStatus = "-";
        }
        this.position = "Store Jr. Leader (Ss)";
        this.codeTitle= "N7K";
        this.isSpecial = 0;

        this.isComboBox = false;
      } else if (
        storeCode.Tipe == "spec" &&
        (this.employeeDetail.JABATAN == "Store Crew Boy (Ss)" ||
          this.employeeDetail.JABATAN == "Store Crew Girl (Ss)")
      ) {
        this.isSpec = false;
        this.nextStoreStatus = "Special Store";
        this.position = "Store Jr. Leader (Ss)";
        this.codeTitle= "N7K";
        this.isSpecial = 0;
        this.isComboBox = false;
      } else {
        this.position = "Store Jr. Leader";
        if (storeCode.Tipe === "reg") {
          this.nextStoreStatus = "Non Special Store";
        } else {
          this.nextStoreStatus = "-";
        }
        this.codeTitle= "N7";
        this.isSpecial = 0;
        this.isSpec = false;
      }
      if (storeCode.KODETOKO === this.employeeDetail.BAGIAN) {
        let firstDateOfCurrentMonth = moment()
          .clone()
          .startOf("month")
          .format("YYYY-MM-DD");
        let firstDateOfNextMonthUnformatted = moment(firstDateOfCurrentMonth)
          .add(1, "M")
          .format("YYYY-MM-DD");
        this.effectiveDate = firstDateOfNextMonthUnformatted;
        this.disableDate = true;
      }
      this.selectedStoreCode = storeCode.NAMATOKO;
      this.selectedStoreCodeID = storeCode.KODETOKO;
      this.checkIsTrained();
      this.checkIfCurrentEmployeeHasWorkedFor6Months();
    },
  },

  created() {
    this.$store.dispatch("setFormType", "dht");
    this.getAllCabang();
    this.minDate();
    this.maxDate();
    this.checkIfStoreIsSpecial();
    this.firstStoreStatusMethod();
    this.isComboboxActive();
    if (
      this.employeeDetail.BAGIAN == this.$store.getters.employeeData.bagian_id
    ) {
      this.disableDate = true;
    }
    this.jobClass = this.$store.getters.employeeData.jobclass;
    this.shift = this.$store.getters.employeeData.hari_kerja;
    this.effectiveDate = this.$store.getters.employeeData.tanggal_efektif;
    if (moment(this.effectiveDate) < moment()) {
      this.$store.dispatch("setEffDateValid", false);
    }
    this.storeCodeFinal =
      this.$store.getters.employeeData.bagian_id +
      "-" +
      this.$store.getters.employeeData.bagian;
  },
};
</script>

<style scoped>
.w-20 {
  width: 20% !important;
}

.w-34 {
  width: 34% !important;
}

.text-right-form >>> input {
  text-align: right !important;
}
</style>

