<template>
  <div>
    <h3 class="mt-4">MASTER PENGATURAN</h3>
    <div v-if="!isMobile">

      <v-row class="ma-4">
        <v-col class="d-flex align-center" md="2"> Nama Pengaturan</v-col>
        <v-col md="4">
          <v-combobox dense outlined></v-combobox>
        </v-col>
      </v-row>
      <v-row class="ma-4">
        <v-col class="d-flex align-center" md="2"> Nilai</v-col>
        <v-col md="4">
          <v-text-field outlined dense></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ma-4">
        <v-col class="d-flex align-center" md="2"> Keterangan</v-col>
        <v-col md="4">
          <v-textarea outlined dense></v-textarea>
        </v-col>
      </v-row>
      <v-row class="ma-4">
        <v-col class="d-flex align-center" md="2"></v-col>
        <v-col md="4">
          <v-btn tile color="success">
            <v-icon left> mdi-content-save </v-icon>
            Simpan
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-end">
        <v-col md="10"> </v-col>
        <v-col class="ma-4">
          <v-text-field dense outlined v-model="search" append-icon="mdi-magnify" label="Pencarian" single-line
            hide-details></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table :headers="headers" :items="desserts" :search="search"></v-data-table>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row>
        <v-col>
          <div> Nama Pengaturan</div>
        </v-col>
      </v-row>
      <v-row class="mr-4">
        <v-col>
          <v-combobox dense outlined></v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div> Nilai</div>
        </v-col>
      </v-row>
      <v-row class="mr-4">
        <v-col>
          <v-text-field outlined dense></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div> Keterangan</div>
        </v-col>
      </v-row>
      <v-row class="mr-4">
        <v-col>
          <v-text-field outlined dense></v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>

</template>

<script>
export default {
  beforeDestroy() {
    if (typeof window === 'undefined') return

    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  mounted() {
    this.onResize()

    window.addEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
  },
  data() {
    return {

      isMobile: false,
      tabs: null,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      search: "",
      headers: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Nama Pengaturan", value: "calories" },
        { text: "Nilai", value: "fat" },
        { text: "Keterangan", value: "carbs" },
        { text: "Tanggal Input", value: "protein" },
        { text: "Tanggal Update", value: "iron" },
        { text: "Opsi Action", value: "iron" },
      ],
    };
  },
};
</script>
