<template>
  <div class="w-100">
    <div v-if="!insert" class="ma-4 mb-0 w-100">
      <v-row class="ma-4 mb-0">
        <v-col class="d-flex align-center justify-end" cols="12" sm="6" md="2">
          Jenis Permohonan
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-combobox
            @change="checkRequestIsEmpty"
            :items="typeRequest"
            v-model="requestType"
            placeholder="Pilih Tipe Permohonan"
            outlined
            dense
          ></v-combobox>
        </v-col>
      </v-row>
      <v-row class="ma-4 mb-0 mt-0">
        <v-col class="d-flex align-center justify-end" cols="12" sm="6" md="2">
          Bagian
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-combobox
            @change="checkRequestIsEmpty"
            v-model="storeCode"
            :items="storeCodes"
            item-text="name"
            placeholder="Pilih Bagian"
            outlined
            dense
          ></v-combobox>
        </v-col>
      </v-row>
      <v-row class="ml-2">
        <v-col class="d-flex align-center justify-end" cols="12" sm="6" md="2">
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-btn
            :disabled="searchDisabled"
            color="primary rounded-xl"
            @click="searchEmployee"
          >
            Cari
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-card v-if="!insert" class="ma-4 w-100">
      <v-card-title class="f-14 d-flex justify-space-between">
        Daftar Karyawan
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          class="shrink"
          single-line
          dense
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="ListEmployeeHeaders"
        :items="employeeListData"
        :search="search"
        :loading="isLoading"
      >
        <template v-slot:item.nik="{ item }">
          <a href="#" @click="getDetailEmployee(item.nik)" dark>
            {{ item.nik }}
          </a>
        </template>
      </v-data-table>
    </v-card>
    <div>
      <div
        v-if="insert && requestType === 'Promosi ke SJL'"
        class="d-flex justify-space-between container w-100"
      >
        <v-stepper v-if="!isMobile" class="w-100" v-model="e1">
          <v-stepper-header fluid class="w-100 stepper-header">
            <div class="d-flex justify-space-between w-100">
              <v-stepper-header class="step-header" fluid>
                <v-stepper-step :complete="e1 > 1" step="1">
                  Permohonan ke SJL
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 2" step="2">
                  Lihat DPM
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 3" step="3">
                  Lihat DHT
                </v-stepper-step>
                <v-divider>--</v-divider>
                <v-stepper-step :complete="e1 > 4" step="4">
                  Lihat DPB
                </v-stepper-step>
              </v-stepper-header>
              <div>
                <v-btn
                  v-if="e1 == 1"
                  color="primary ma-4 ml-1 mr-1 f-12 rounded-xl"
                  @click="insert = false"
                >
                  Sebelumnya
                </v-btn>
                <v-btn
                  v-if="e1 > 1"
                  color="primary ma-4 ml-1 mr-1 f-12 rounded-xl"
                  @click="backStepAndCheckHasUpdated(e1)"
                >
                  Sebelumnya
                </v-btn>
                <v-btn
                  :disabled="disableNextButton"
                  v-if="e1 < 4"
                  color=" primary ma-4 ml-1 mr-1 f-12 rounded-xl"
                  @click="submitStep(e1)"
                >
                  Selanjutnya
                </v-btn>
                <v-btn
                  v-if="e1 === 4 && $store.getters.role == 'USER'"
                  color=" success ma-4 ml-1 mr-1 rounded-xl f-12"
                  @click="submitStep(e1)"
                >
                  Submit
                </v-btn>
                <v-btn
                  v-if="e1 === 4 && $store.getters.role != 'USER'"
                  color=" success ma-4 ml-1 mr-1 rounded-xl f-12"
                  @click="submitStep(e1)"
                >
                  Approve
                </v-btn>
              </div>
            </div>
          </v-stepper-header>

          <v-stepper-items class="step-items">
            <v-stepper-content step="1">
              <One ref="stepOne" :employeeDetail="employeeDetail"></One>
            </v-stepper-content>

            <v-stepper-content step="2">
              <Two ref="stepTwo" :employeeDetail="employeeDetail"></Two>
            </v-stepper-content>

            <v-stepper-content step="3">
              <Three ref="stepThree" :employeeDetail="employeeDetail"></Three>
            </v-stepper-content>

            <v-stepper-content step="4">
              <Four ref="stepFour" :employeeDetail="employeeDetail"></Four>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <v-stepper
          v-if="isMobile"
          v-model="e1"
          vertical
          class="overflow-hidden"
        >
          <v-stepper-step :complete="e1 > 1" step="1">
            Approval SJL</v-stepper-step
          >

          <v-stepper-content step="1">
            <One
              ref="stepOne"
              :employeeDetail="employeeDetail"
              :DHRData="DHRResult"
            ></One>
            <v-btn
              v-if="e1 == 1"
              color="primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="insert = false"
            >
              Sebelumnya
            </v-btn>
            <v-btn
              v-if="e1 > 1"
              color="primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="backStepAndCheckHasUpdated(e1)"
            >
              Sebelumnya
            </v-btn>
            <v-btn
              :disabled="disableNextButton"
              v-if="e1 < 4"
              color=" primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="submitStep(e1)"
            >
              Selanjutnya
            </v-btn>
            <v-btn
              v-if="e1 === 4 && $store.getters.role == 'USER'"
              color=" success ma-4 ml-1 mr-1 rounded-xl f-12"
              @click="submitStep(e1)"
            >
              Submit
            </v-btn>
            <v-btn
              v-if="e1 === 4 && $store.getters.role != 'USER'"
              color=" success ma-4 ml-1 mr-1 rounded-xl f-12"
              @click="submitStep(e1)"
            >
              Approve
            </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="e1 > 2" step="2"
            >Lihat DPM
          </v-stepper-step>

          <v-stepper-content step="2">
            <Two ref="stepTwo" :employeeDetail="employeeDetail"></Two>
            <v-btn
              v-if="e1 == 1"
              color="primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="insert = false"
            >
              Sebelumnya
            </v-btn>
            <v-btn
              v-if="e1 > 1"
              color="primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="backStepAndCheckHasUpdated(e1)"
            >
              Sebelumnya
            </v-btn>
            <v-btn
              :disabled="disableNextButton"
              v-if="e1 < 4"
              color=" primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="submitStep(e1)"
            >
              Selanjutnya
            </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="e1 > 3" step="3"
            >Lihat DHT
          </v-stepper-step>

          <v-stepper-content step="3">
            <Three ref="stepThree" :employeeDetail="employeeDetail"></Three>
            <v-btn
              v-if="e1 == 1"
              color="primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="insert = false"
            >
              Sebelumnya
            </v-btn>
            <v-btn
              v-if="e1 > 1"
              color="primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="backStepAndCheckHasUpdated(e1)"
            >
              Sebelumnya
            </v-btn>
            <v-btn
              :disabled="disableNextButton"
              v-if="e1 < 4"
              color=" primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="submitStep(e1)"
            >
              Selanjutnya
            </v-btn>
          </v-stepper-content>

          <v-stepper-step step="4">Lihat DPB</v-stepper-step>
          <v-stepper-content step="4">
            <Four ref="stepFour" :employeeDetail="employeeDetail"></Four>
            <v-btn
              v-if="e1 > 1"
              color="primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="backStep(e1)"
            >
              Sebelumnya
            </v-btn>
            <v-btn
              v-if="
                e1 === 4 &&
                $store.getters.role == 'USER' &&
                $route.name != 'ViewDetail'
              "
              color=" success ma-4 ml-1 mr-1 rounded-xl f-12"
              @click="submitStep(e1)"
            >
              Submit
            </v-btn>
            <v-btn
              v-if="
                e1 === 4 &&
                $store.getters.role != 'USER' &&
                $route.name != 'ViewDetail'
              "
              color=" success ma-4 ml-1 mr-1 rounded-xl f-12"
              @click="process()"
            >
              Proses
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </div>
    </div>
    <div v-if="isNotSJL()" class="d-flex justify-end pa-5">
      <v-btn @click="goBack" color="primary mr-2">KEMBALI</v-btn>
      <v-btn color="success">PROSES</v-btn>
    </div>
    <v-divider> </v-divider>
    <dht v-if="isNotSJL()"> </dht>

  <!-- <div class="ma-10">
    <v-row class="d-flex justify-space-between" cols="12">
      <v-col>
        <h2>USULAN PENERIMAAN KARYAWAN TETAP</h2>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn small class="ml-2" rounded color="primary"
          ><v-icon class="mr-2"> mdi-arrow-left </v-icon> KEMBALI</v-btn
        >
        <v-btn small dense class="ml-2" rounded color="error"
          ><v-icon class="mr-2"> mdi-cancel </v-icon>REJECT</v-btn
        >
        <v-btn small class="ml-2" rounded color="success"
          ><v-icon class="mr-2"> mdi-check-decagram </v-icon>APPROVE</v-btn
        >
      </v-col>
    </v-row>
    <permanent> </permanent>
  </div> -->
  </div>
</template>

<script>
import One from "./steps/One.vue";
import { mapActions } from "vuex";
import Two from "./steps/Two.vue";
import Three from "./steps/Three.vue";
import Four from "./steps/Four.vue";
import dht from "../request/dht/Index.vue";
// import Permanent from "../request/type/Permanent.vue";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);
export default {
  components: {
    dht,
    One,
    Two,
    Three,
    Four,
  },
  data() {
    return {
      variable: [
        "NIK",
        "NAMA",
        "TANGGAL BERGABUNG",
        "LAMA BEKERJA",
        "STATUS KERJA",
        "KODE TOKO LAMA",
        "JABATAN",
        "LAMA MENJABAT",
        "KONTRAK BERAKHIR",
      ],
      typeResign: ["DHT", "PRO", "MUT", "PRM"],
      menu: false,
      isMobile: false,
      insert: false,
      disableNextButton: false,
      searchDisabled: true,
      value: null,
      isUpdate: false,
      storeCodes: [],
      isLoading: false,
      typeRequest: ["Promosi ke SJL"],
      modal: false,

      requestType: null,
      e1: 1,
      storeCode: null,
      date: new Date().toISOString().substr(0, 10),
      ListEmployeeHeaders: [
        { text: "No", value: "no" },
        { text: "NIK", value: "nik" },
        { text: "Nama Karyawan", value: "name" },
        { text: "Lama Bekerja", value: "join_time" },
        { text: "Jabatan", value: "position" },
        { text: "Bagian ", value: "store_code" },
        { text: "Lama Menjabat ", value: "position_time" },
      ],
      search: "",
      employeeListData: [],
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    goBack() {
      this.insert = false;
    },
    isNotSJL() {
      if (this.insert === true && this.requestType != "Promosi ke SJL") {
        return true;
      }
      return false;
    },
    menuItems() {
      return this.menu;
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    ...mapActions({
      setTotalInbox: "setTotalInbox",
    }),
    backStepAndCheckHasUpdated(step) {
      this.isUpdate = true;
      this.e1 = step - 1;
      if (step === 4) {
        this.$store.dispatch("setFormType", "dht");
      }
    },
    checkRequestIsEmpty() {
      if (this.requestType !== "RTP - Penerimaan Tetap") {
        this.pushToSelect();
      }
 
      if (this.requestType != null && this.storeCode != null) {
        this.searchDisabled = false;
      }
    },
    async submitStep(step) {
      this.disableNextButton = true;
      if (step == 1) {
        if (this.$store.getters.isTrained == true) {
          if (this.$store.getters.is6Months == true) {
            if (this.isUpdate === true) {
              await this.$refs.stepOne.updateDHR();
            } else {
              await this.$refs.stepOne.submitDraftStepOne();
            }
          } else {
            this.disableNextButton = false;
            return this.$swal.fire({
              title: "Warning !",
              html: "Transaksi tidak dapat dibuat. Sesuai dengan ketentuan Memo <b> 397-CPS-22</b> mutasi ke Kelas <b> Special Store</b> <br/> minimal wajib sudah bekerja selama <b>6</b> Bulan",
            });
          }
        } else {
          this.disableNextButton = false;
          return this.$swal({
            title: "Peringatan !",
            html:
              "Karyawan " +
              this.employeeDetail.NAMA +
              " dengan NIK <br/>" +
              this.employeeDetail.NIK +
              " Belum ada data history training SS untuk dimutasikan ke Special Store " +
              "<strong>" +
              "</strong>" +
              "<br/> , " +
              "Jika karyawan sudah mengikuti training, harap update data history training melalui menu upload training di DHR Online (Lama), kemudian proses ulang untuk NIK tersebut <br/>  ",
          });
        }

        if (
          !this.$store.getters.draft.includes("Terdapat") &&
          !this.$store.getters.draft.includes("NIK") &&
          !this.$store.getters.draft.includes("Tidak")
        ) {
          await this.$refs.stepTwo.getDPM();
        } else {
          this.disableNextButton = false;
          return this.$swal(this.$store.getters.draft);
        }
      } else if (step == 2) {
        await this.$refs.stepThree.getDHT();
      } else if (step === 3) {
        await this.$refs.stepThree.updateDHT();
        await this.$refs.stepFour.getDPB();
      } else {
        await this.$refs.stepFour.submitDraft();
        await this.getInbox();
        return this.$router.push({ name: "Inbox" });
      }
      this.e1 = step + 1;
      this.disableNextButton = false;
    },

    scrollToSomeDiv() {
      document.getElementById("list-employee-container").scrollIntoView({
        behavior: "smooth",
      });
    },
    getColor(calories) {
      if (calories > 400) return "red";
      else if (calories > 200) return "orange";
      else return "green";
    },
    pushToSelect() {
      let storeCode = this.$store.getters.storeCode;
      let storeCodes = storeCode.split(",");
      for (let i = 0; i < storeCodes.length; i++) {
        this.storeCodes.push({ name: storeCodes[i], id: i });
      }
    },
    getDetailEmployee(nik) {
      this.$store
        .dispatch("callApiPost", {
          link: "Karyawan",
          raw: {
            code: "2",
            nik: nik,
          },
        })
        .then((response) => {
          let employeeDetail = response.Message;
          this.employeeDetail = employeeDetail[0];
          if (this.employeeDetail.STATUS_KERJA === "Tetap") {
            return this.$swal.fire({
              title: "Peringatan !",
              html: "Karyawan ini tidak dapat di proses Promosi ke SJL melalui menu ini, karena status karyawan tersebut <b>Karyawan Tetap</b>. Mohon lalukan proses <b>Promosi SJL</b> pada menu DHR Online yang lama.",
              icon: "warning",
            });
          }
          this.$store.dispatch("setRequestType", this.requestType);
          this.insert = true;
        });
    },
    getInbox() {
      this.$store
        .dispatch("callApiPost", {
          link: "Inbox",
          raw: {
            code: "1",
            dhrType: "SJL",
            nik: this.$store.getters.nik,
            role: this.$store.getters.role,
          },
        })
        .then((response) => {
          let inbox = response.Message;
          this.setTotalInbox(inbox.length);
        });
    },

    searchEmployee() {
      let self = this;
      let code = this.storeCode.name.split("-");
      self.searchDisabled = true;
      self.employeeListData = [];
      self.isLoading = true;
      this.$store
        .dispatch("callApiPost", {
          link: "Karyawan",
          raw: {
            code: "1",
            kd_toko: code[0],
          },
        })
        .then((response) => {
          let employeeData = response.Message;

          for (let i = 0; i < employeeData.length; i++) {
            self.employeeListData.push({
              no: i + 1,
              nik: employeeData[i].NIK,
              name: employeeData[i].NAMA,
              position: employeeData[i].JABATAN,
              join_time: employeeData[i].LAMA_BERGABUNG,
              position_time: employeeData[i].LAMA_POSISI,
              store_code: this.storeCode.name,
            });
          }
          self.isLoading = false;
          self.searchDisabled = false; 
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css? family=Oxygen:300,400,700&display=swap");
@import url("https://fonts.googleapis.com/css? family=Comfortaa&display=swap");
.flex-0,
.v-toolbar {
  flex: none !important;
}
.mobile-flex {
  height: 550px;
  overflow: scroll;
}
html {
  font-family: "Oxygen";
}
.to-be-center > .row > .col {
  display: flex;
  align-items: center;
}
.v-select__slot {
  font-size: 12px !important;
}
.mobile-form,
.row {
  padding: 8px;
}
.v-application .silver {
  background-color: #6b666f !important;
}
.v-btn--disabled {
  border-radius: 20px !important;
}

.v-stepper {
  border-radius: unset !important;
  box-shadow: unset !important;
  width: 75%;
}
.h-40px {
  height: 40px;
}
.w-40 {
  width: 40%;
  font-weight: bold;
  margin-right: 10px;
}
.v-text-field--outlined,
.v-text-field__details {
  margin-bottom: 0px !important;
}
.v-input__control {
  flex-direction: unset !important;
  flex-wrap: unset !important;
}
.b-disabled.v-text-field--outlined > .v-input__control > .v-input__slot {
  background: #f2f2f4 !important;
}
.b-disabled.v-input .v-input__slot {
  border-radius: 4px !important;
  border-color: unset !important;
}
.w-39percent {
  width: 39% !important;
}
.w-100 {
  width: 100%;
}
.v-stepper__header {
  box-shadow: unset !important;
}
.step-header.v-stepper__header {
  width: 70% !important;
  font-size: 12px !important;
}

.b-disabled.v-text-field--outlined fieldset {
  border-color: transparent !important;
}
.row-padding-0.d-flex,
.row-padding-0.d-flex {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.mobile-form,
.row {
  padding: 0px !important;
}
</style>
