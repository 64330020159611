<template>
  <div class="h-100">
    <div class="h-100">
      <div v-if="!isMobile" class="section-container">
        <v-row class="signin">
          <v-col cols="8" class="left">
            <h1>eRequest DHR</h1>
            <div>
              <div>
                Aplikasi Web untuk Pengajuan Promosi SJL dari Karyawan Kontrak
                SCG/SCB
              </div>
              <div>Silakan masuk dengan NIK & Password ESS Anda</div>
              <img src="../../assets/images/2.png" />
            </div>
          </v-col>
          <v-col cols="4" class="right">
            <div class="d-flex align-center justify-center h-100">
              <div>
                <div class="formatHeader mb-5">
                  <div class="formatHeader2"><h4>Hello!!</h4></div>
                  <h4>Sign in to your Account</h4>
                </div>

                <validation-observer ref="observer">
                  <v-form @submit.prevent="submit">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <v-text-field
                        class="rounded-xl"
                        v-model="nik"
                        :error-messages="errors"
                        label="NIK"
                        required
                        outlined
                        dark
                        filled
                        dense
                      ></v-text-field>
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      name="email"
                      rules="required"
                    >
                      <v-text-field
                        class="rounded-xl mb-5"
                        v-model="password"
                        :error-messages="errors"
                        label="Password"
                        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPass = !showPass"
                        required
                        outlined
                        dense
                        dark
                        filled
                        :type="showPass ? 'text' : 'password'"
                      ></v-text-field>
                    </validation-provider>
                    <div class="text-center">
                      <v-btn
                        :disabled="isDisabled"
                        class="signin-btn"
                        type="submit"
                        rounded
                        color="white"
                        dark
                      >
                        Sign In
                      </v-btn>
                    </div>
                    <div class="text-center mt-5">
                      <a href type="submit" rounded dark class="forgetpassword">
                        Lupa Password ?
                      </a>
                    </div>
                  </v-form>
                </validation-observer>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </div>

      <div class="h-100" v-else>
        <div class="d-flex justify-center align-center h-100">
          <v-card class="pa-5">
            <div>
              <div class="d-flex justify-center w-100">
              <v-img
                max-height="85"
                max-width="100"
                src="../../assets/images/Indomaret-logo.svg"
              ></v-img>
              </div>
              <div class="formatHeader mb-5">
                <h4>Masuk</h4>
              </div>
            </div>

            <div class="d-flex justify-center">
              <v-text-field
                v-model="nik"
                placeholder="Username"
                outlined
                dense
              />
            </div>
            <div class="d-flex justify-center mt-2">
              <v-text-field
                v-model="password"
                type="password"
                placeholder="Password"
                outlined
                dense
              />
            </div>
            <div class="w-100 mt-5">
              <v-btn block @click="login()" color="success"> Login</v-btn>
            </div>

            <v-card-text>
              <span
                >Copyright &copy; {{ new Date().getFullYear() }} - Software
                Development II Indomaret Group v1.0</span
              >
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import {
  extend,
  ValidationProvider,
  setInteractionMode,
  ValidationObserver,
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      nik: "",
      password: null,
      showPass: false,
      isDisabled: false,
      overlay: false,
      isMobile: false,
    };
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    params() {
      return {
        nik: this.nik,
        password: this.password,
      };
    },
  },

  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 3000);
    },
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    async submit() {
      const valid = await this.$refs.observer.validate();
      if (valid) {
        this.login(this.params); // action to login
      }
    },

    login() {
      let self = this;
      self.isDisabled = true;
      self.overlay = true;
      self.$store
        .dispatch("callApiPost", {
          link: "Login",
          raw: {
            nik: this.nik,
            password: this.password,
          },
        })
        .then((response) => {
          if (response.Code == 400 || response.Code == 500) {
            this.$swal(
              "Password Anda Salah, Gunakanlah Password ESS",
              "",
              "error"
            );
            self.isDisabled = false;
          } else {
            self.$store.dispatch("setNecessaryData", response);
            this.$router.go({ name: "Inbox" });
          }
        });
    },
    clear() {
      // you can use this method to clear login form
      this.nik = "";
      this.password = null;
      this.$refs.observer.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.h-100 {
  height: 100% !important;
  div {
    min-width: 250px !important;
  }
}
html {
  height: 100%;
  margin: auto;
  overflow: scroll;
}
.v-toolbar.v-app-bar {
  height: 100% !important;
}

.forgetpassword {
  color: #ffffff;
  font-weight: bold;
}
.section-container {
  padding: 20px;
  margin: 20px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 1px 1px rgba($color: #000000, $alpha: 0.1);
  box-sizing: border-box;
  .signin {
    padding: 0;
    max-width: 1000px;
    margin: 0 auto;

    box-shadow: 0 0 1px 1px rgba($color: #000000, $alpha: 0.1);
    .left {
      padding: 30px;
      box-sizing: border-box;
      color: #3751fe;
      background-color: #f9f9f9;
    }
    .right {
      padding: 30px;
      box-sizing: border-box;
      background: #3751fe;
      color: #fff;
      h2 {
        text-align: center;
        margin: 30px 0;
      }
      .signin-btn {
        width: 100%;
        color: #3751fe;
      }
    }
  }
}
.formatHeader {
  text-align: center;
}
.formatHeader2 {
  font-size: 18px;
}
.h-100 {
  height: 100% !important;
}
.w-100 {
  width: 100%;
}
</style>
