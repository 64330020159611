<template>
  <div>
    <div v-if="!isMobile">
      <detail :employeeDetail="employeeDetail"> </detail>

      <v-row class="silver mt-4 mb-4">
        <v-col class="text-left d-flex pt-2 pb-2" cols="12" sm="6" md="5">
          <div class="w-38 d-flex -justify-end">
            <span class="white--text pl-4 font-weight-bold">Data Saat ini</span>
          </div>
        </v-col>
        <v-col class="align-center d-flex pt-2 pb-2" cols="12" sm="6" md="5">
          <div class="w-38 d-flex justify-end">
            <span class="white--text font-weight-bold"
              >Data Rehire Karyawan</span
            >
          </div>
        </v-col>
      </v-row>
      <old-and-new-data
        ref="oldAndNewData"
        :employeeDetail="employeeDetail"
        :DPBData="DPBResult"
      ></old-and-new-data>
      <v-row
        v-if="$store.getters.role == 'AM' || $store.getters.role == 'BM'"
        class="silver mt-4 mb-4 pl-6 pb-0 pt-0"
      >
        <v-col class="white--text font-weight-bold pb-2 pt-2" col="12" sm="4">
          Gaji
        </v-col>
        <v-col class="white--text font-weight-bold pb-2 pt-2" col="12" sm="4">
          Usulan Kenaikan Gaji</v-col
        >
        <v-col class="white--text font-weight-bold pb-2 pt-2" col="12" sm="4">
          Gaji Baru</v-col
        >
      </v-row>
      <salary
        v-if="
          $store.getters.role == 'AM' ||
          ($store.getters.role == 'BM' && isSalary == true)
        "
        :DPBData="DPBResult"
      >
      </salary>
    </div>
    <div v-else>
      <employee-detail-form-mobile
        :DHTResult="DHTResult"
        :employeeDetail="employeeDetail"
      />
      <old-and-new-data
        ref="oldAndNewData"
        :employeeDetail="employeeDetail"
        :DPBData="DPBResult"
      />
      <salary
        v-if="
          $store.getters.role == 'AM' ||
          ($store.getters.role == 'BM' && isSalary == true)
        "
        :DPBData="DPBResult"
      >
      </salary>
    </div>
  </div>
</template>

<script>
import Detail from "../employee/DetailForm.vue";
import EmployeeDetailFormMobile from "../employee/DetailFormMobile.vue";
import OldAndNewData from "../employee/OldAndNewData.vue";
import Salary from "../employee/Salary.vue";
export default {
  props: ["employeeDetail"],
  components: {
    Detail,
    OldAndNewData,
    Salary,
    EmployeeDetailFormMobile,
  },
  data() {
    return {
      DPBResult: [],
      isSalary: false,
      isMobile: false,
    };
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    async submitDraft() {
      await this.$refs.oldAndNewData.submitFinalDraftForAS();
    },
    async getDPB() {
      await this.$store
        .dispatch("callApiPost", {
          link: "Transaksi",
          raw: {
            code: "4",
            id_dhr: this.$store.getters.id_dhr,
          },
        })
        .then((response) => {
          this.$store.dispatch("setFormType", "dpb");
          let DPBResult = response.Message;
          this.DPBResult = DPBResult[0];
          this.$store.dispatch("setNewSalary", this.DPBResult.gaji_baru);
          this.$store.dispatch("setAllowance", this.DPBResult.tunjangan1);
          this.isSalary = true;
        });
    },
  },
};
</script>

<style lang="scss">
.row-padding-2 {
  height: 50px !important;
}
.w-38 {
  width: 38%;
}
.row-padding-2.d-flex,
.row-padding-0.d-flex {
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}
</style>

