<template>
  <div>
    <div class="silver pa-1 pl-4 mb-4">
      <span class="white--text font-weight-bold">Data Karyawan</span>
    </div>

    <v-row>
      <v-col class="align-center d-flex" cols="12" sm="3" md="2">
        <div>
          <span class="font-weight-bold"> NIK </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
        <v-text-field
          :value="employeeDetail.NIK"
          class="b-disabled text-right-form"
          dense
          disabled
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="align-center d-flex"
        cols="12"
        sm="3"
        md="2"
      >
        <div>
          <span class="font-weight-bold"> Nama </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
        <v-text-field
          :value="employeeDetail.NAMA"
          class="b-disabled text-right-form"
          dense
          disabled
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="align-center d-flex"
        cols="12"
        sm="3"
        md="2"
      >
        <div>
          <span class="font-weight-bold"> Tanggal Bergabung </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
        <v-text-field
          :value="employeeDetail.TANGGAL_BERGABUNG"
          class="b-disabled  text-right-form"
          dense
          disabled
          outlined
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        class="align-center d-flex"
        cols="12"
        sm="3"
        md="2"
      >
        <div>
          <span class="font-weight-bold"> Lama Bekerja </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
        <v-text-field
          :value="employeeDetail.LAMA_BERGABUNG"
          class="b-disabled  text-right-form"
          dense
          disabled
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="align-center d-flex"
        cols="12"
        sm="3"
        md="2"
      >
        <div>
          <span class="font-weight-bold"> Status Karyawan </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
        <v-text-field
          :value="employeeDetail.STATUS_KERJA"
          class="b-disabled  text-right-form"
          dense
          disabled
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="align-center d-flex"
        cols="12"
        sm="3"
        md="2"
      >
        <div>
          <span class="font-weight-bold"> Jabatan </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
        <v-text-field
          :value="employeeDetail.JABATAN"
          class="b-disabled  text-right-form"
          dense
          disabled
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="align-center d-flex"
        cols="12"
        sm="3"
        md="2"
      >
        <div>
          <span class="font-weight-bold"> Lama Menjabat </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
        <v-text-field
          :value="employeeDetail.LAMA_POSISI"
          class="b-disabled  text-right-form"
          dense
          disabled
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="$store.getters.formType == 'dht'"
        class="align-center d-flex text-right"
        cols="12"
        sm="3"
        md="2"
      >
        <div>
          <span class="font-weight-bold"> Tanggal Berakhir Kontrak </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="$store.getters.formType == 'dht'"
        class="align-center d-flex text-right"
        cols="12"
        sm="3"
        md="2"
      >
        <v-text-field
          :value="employeeDetail.TANGGAL_BERHENTI"
          class="b-disabled  text-right-form"
          dense
          disabled
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="$store.getters.formType == 'dpb'"
        class="align-center d-flex text-right"
        cols="12"
        sm="3"
        md="2"
      >
        <div>
          <span class="font-weight-bold"> Tanggal Berakhir Kontrak </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="$store.getters.formType == 'dpb'"
        class="align-center d-flex text-right"
        cols="12"
        sm="3"
        md="2"
      >
        <v-text-field
          :value="$store.getters.effectiveDate"
          class="b-disabled  text-right-form"
          dense
          disabled
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ["employeeDetail", "DHTResult", "type"],
};
</script>

<style scoped>
.text-right-form >>> input {
  text-align: right !important;
}
</style>
