<template>
  <div v-if="!isDPMDoesNotExist">
    <div v-if="!isMobile">
      <div>
        <EmployeeDetailForm :employeeDetail="employeeDetail" />
      </div>

      <div class="mt-4">
        <div class="silver pa-1 pl-4 d-flex justify-space-between">
          <span class="white--text">Data Karyawan Sebelumnya</span>
          <span class="white--text text-left w-39percent"
            >Usulan Perubahan
          </span>
        </div>
        <div class="d-flex">
          <div class="col-5">
            <div class="d-flex mb-4 align-center align-center">
              <div class="text-right w-40">Status Kerja:</div>

              <v-text-field
                class="b-disabled"
                :value="employeeDetail.STATUS_KERJA"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Hari Kerja:</div>
              <v-text-field
                class="b-disabled"
                :value="changeShiftValue(employeeDetail.ID_GROUP_SHIFT)"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Unit:</div>
              <v-text-field
                :value="employeeDetail.Unit"
                class="b-disabled"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Bagian:</div>
              <v-text-field
                :value="employeeDetail.BAGIAN + '-' + employeeDetail.NAMA_TOKO"
                class="b-disabled"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Jabatan:</div>
              <v-text-field
                :value="employeeDetail.JABATAN"
                class="b-disabled"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Job Class:</div>
              <v-text-field
                :value="employeeDetail.JOB_CLASS"
                class="b-disabled"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Area Manager:</div>
              <v-text-field
                :value="employeeDetail.NAMA_AM"
                class="b-disabled"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
          </div>
          <div class="col-5">
            <div class="d-flex mb-4 align-center align-center">
              <div class="text-right w-40">Status Kerja:</div>

              <v-text-field
                class="b-disabled"
                :value="newEmployeeData.status_karyawan"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Hari Kerja:</div>
              <v-text-field
                class="b-disabled"
                :value="changeShiftValue(newEmployeeData.hari_kerja)"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Unit:</div>
              <v-text-field
                class="b-disabled"
                :value="changeCompanyName(newEmployeeData.unit)"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Bagian:</div>
              <v-text-field
                class="b-disabled"
                :value="
                  newEmployeeData.bagian_id + '-' + newEmployeeData.bagian
                "
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Jabatan:</div>
              <v-text-field
                class="b-disabled"
                :value="newEmployeeData.jabatan"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>

            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Job Class:</div>
              <v-text-field
                class="b-disabled"
                :value="newEmployeeData.jobclass"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Tanggal Efektif:</div>
              <v-text-field
                class="b-disabled"
                :value="newEmployeeData.effective_date"
                dense
                disabled
                outlined
              ></v-text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div>
        <EmployeeDetailFormMobile :employeeDetail="employeeDetail">
        </EmployeeDetailFormMobile>
      </div>
      <div>
        <div class="silver pa-1 pl-4 mb-4 w-100-idx mt-8">
          <span class="white--text font-weight-bold"
            >Data Karyawan Sebelumnya</span
          >
        </div>
        <v-row>
          <v-col
            class="align-center d-flex"
            cols="12"
            sm="3"
            md="2"
          >
            <div>
              <span class="font-weight-bold"> Status Kerja </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
            <v-text-field
              class="b-disabled text-right-form"
              :value="employeeDetail.STATUS_KERJA"
              dense
              disabled
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="align-center d-flex"
            cols="12"
            sm="3"
            md="2"
          >
            <div>
              <span class="font-weight-bold"> Hari Kerja </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
            <v-text-field
              class="b-disabled text-right-form"
              :value="changeShiftValue(employeeDetail.ID_GROUP_SHIFT)"
              dense
              disabled
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="align-center d-flex"
            cols="12"
            sm="3"
            md="2"
          >
            <div>
              <span class="font-weight-bold"> Unit </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
            <v-text-field
              :value="employeeDetail.Unit"
              class="b-disabled text-right-form"
              dense
              disabled
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="align-center d-flex"
            cols="12"
            sm="3"
            md="2"
          >
            <div>
              <span class="font-weight-bold"> Bagian </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
            <v-text-field
              :value="employeeDetail.BAGIAN + '-' + employeeDetail.NAMA_TOKO"
              class="b-disabled text-right-form"
              dense
              disabled
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="align-center d-flex"
            cols="12"
            sm="3"
            md="2"
          >
            <div>
              <span class="font-weight-bold"> Jabatan </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
            <v-text-field
              :value="employeeDetail.JABATAN"
              class="b-disabled text-right-form"
              dense
              disabled
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="align-center d-flex"
            cols="12"
            sm="3"
            md="2"
          >
            <div>
              <span class="font-weight-bold"> Job Class </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
            <v-text-field
              :value="employeeDetail.JOB_CLASS"
              class="b-disabled text-right-form"
              dense
              disabled
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="align-center d-flex"
            cols="12"
            sm="3"
            md="2"
          >
            <div>
              <span class="font-weight-bold"> Area Manager </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
            <v-text-field
              :value="employeeDetail.NAMA_AM"
              class="b-disabled text-right-form"
              dense
              disabled
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <div class="silver pa-1 pl-4 mb-4 w-100-idx mt-8">
          <span class="white--text font-weight-bold">Usulan Perubahan</span>
        </div>
        <v-row>
          <v-col
            class="align-center d-flex"
            cols="12"
            sm="3"
            md="2"
          >
            <div>
              <span class="font-weight-bold"> Status Kerja </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
            <v-text-field
              class="b-disabled text-right-form"
              :value="newEmployeeData.status_karyawan"
              dense
              disabled
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="align-center d-flex"
            cols="12"
            sm="3"
            md="2"
          >
            <div>
              <span class="font-weight-bold"> Hari Kerja </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
            <v-text-field
              class="b-disabled text-right-form"
              :value="changeShiftValue(newEmployeeData.hari_kerja)"
              dense
              disabled
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="align-center d-flex"
            cols="12"
            sm="3"
            md="2"
          >
            <div>
              <span class="font-weight-bold"> Unit </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
            <v-text-field
              class="b-disabled text-right-form"
              :value="changeCompanyName(newEmployeeData.unit)"
              dense
              disabled
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="align-center d-flex"
            cols="12"
            sm="3"
            md="2"
          >
            <div>
              <span class="font-weight-bold"> Bagian </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
            <v-text-field
              class="b-disabled text-right-form"
              :value="newEmployeeData.bagian_id + '-' + newEmployeeData.bagian"
              dense
              disabled
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="align-center d-flex"
            cols="12"
            sm="3"
            md="2"
          >
            <div>
              <span class="font-weight-bold"> Jabatan </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
            <v-text-field
              class="b-disabled text-right-form"
              :value="newEmployeeData.jabatan"
              dense
              disabled
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="align-center d-flex"
            cols="12"
            sm="3"
            md="2"
          >
            <div>
              <span class="font-weight-bold"> Job Class </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
            <v-text-field
              class="b-disabled text-right-form"
              :value="newEmployeeData.jobclass"
              dense
              disabled
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="align-center d-flex"
            cols="12"
            sm="3"
            md="2"
          >
            <div>
              <span class="font-weight-bold"> Tanggal Efektif </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
            <v-text-field
              class="b-disabled text-right-form"
              :value="newEmployeeData.effective_date"
              dense
              disabled
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import EmployeeDetailForm from "../employee/DetailForm.vue";
import EmployeeDetailFormMobile from "../employee/DetailFormMobile.vue";
export default {
  components: {
    EmployeeDetailForm,
    EmployeeDetailFormMobile,
  },
  props: ["employeeDetail"],
  setup() {},
  data: () => ({
    newEmployeeData: {},
    OldEmployeeDetail: "",
    isDPMDoesNotExist: false,
    isMobile: false,
  }),
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    ...mapActions({
      setTotalInbox: "setTotalInbox",
    }),
    getInbox() {
      this.$store
        .dispatch("callApiPost", {
          link: "Inbox",
          raw: {
            code: "1",
            dhrType: "SJL",
            nik: this.$store.getters.nik,
            role: this.$store.getters.role,
          },
        })
        .then((response) => {
          let inbox = response.Message;
          this.setTotalInbox(inbox.length);
        });
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    changeShiftValue(shift) {
      if (shift === "6S") {
        return "6 Hari-Shift";
      } else if (shift === "5S") {
        return "5 Hari-Shift";
      } else if (shift === "6NS") {
        return "6 Hari-Non Shift";
      } else if (shift === "5NS") {
        return "5 Hari-Non Shift";
      }
    },
    changeCompanyName(companyName) {
      if (companyName === "IDM") {
        return "PT INDOMARCO PRISMATAMA";
      } else {
        return "PT ALBANY CORONA LESTARI";
      }
    },
    async getDPM() {
      await this.$store
        .dispatch("callApiPost", {
          link: "Transaksi",
          raw: {
            code: "5",
            id_dhr: this.$store.getters.id_dhr,
          },
        })
        .then((response) => {
          let newEmployeeData = response.Message;
          if (newEmployeeData.length === 0) {
            this.isDPMDoesNotExist = true;
            this.getInbox();
            this.$swal({
              html: "Permohonan ke SJL <strong>Tidak</strong> membentuk dokumen <strong>DPM</strong>, karena Bagian/Toko <strong>tidak</strong> berubah atau Tanggal Efektif Mutasi <strong>sama dengan</strong> Tanggal Efektif Promosi, Silakan Klik Tombol Selanjutnya",
            });
          } else {
            this.newEmployeeData = newEmployeeData[0];
            this.isDPMDoesNotExist = false;
          }
        });
    },
  },
};
</script>

<style scoped>
.w-100-idx {
  width: 100% !important;
  margin-top: 30px;
}
.text-right-form >>> input {
  text-align: right !important;
}
</style>
