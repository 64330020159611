<template>
  <div v-if="allApiHasBeenLoaded" class="w-100">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="d-flex justify-space-between container w-100">
      <v-stepper v-if="isMobile" v-model="e1" vertical class="overflow-hidden">
        <v-stepper-step :complete="e1 > 1" step="1">
          Approval SJL</v-stepper-step
        >

        <v-stepper-content step="1">
          <OneUpdate
            ref="stepOne"
            :employeeDetail="employeeDetail"
            :DHRData="DHRResult"
          ></OneUpdate>
          <div class="d-flex">
            <v-btn
              v-if="e1 > 1"
              color="primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="backStep(e1)"
            >
              Sebelumnya
            </v-btn>
            <v-btn
              v-if="e1 < 4"
              :disabled="disableNextButton"
              color=" primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="submitStep(e1)"
            >
              Selanjutnya
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-step :complete="e1 > 2" step="2">Lihat DPM </v-stepper-step>

        <v-stepper-content step="2">
          <Two ref="stepTwo" :employeeDetail="employeeDetail"></Two>
          <div class="d-flex">
            <v-btn
              v-if="e1 > 1"
              color="primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="backStep(e1)"
            >
              Sebelumnya
            </v-btn>
            <v-btn
              v-if="e1 < 4"
              :disabled="disableNextButton"
              color=" primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="submitStep(e1)"
            >
              Selanjutnya
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-step :complete="e1 > 3" step="3">Lihat DHT </v-stepper-step>

        <v-stepper-content step="3">
          <Three ref="stepThree" :employeeDetail="employeeDetail"></Three>
          <div class="d-flex">
            <v-btn
              v-if="e1 > 1"
              color="primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="backStep(e1)"
            >
              Sebelumnya
            </v-btn>
            <v-btn
              v-if="e1 < 4"
              :disabled="disableNextButton"
              color=" primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="submitStep(e1)"
            >
              Selanjutnya
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-step step="4">Lihat DPB</v-stepper-step>
        <v-stepper-content step="4">
          <Four ref="stepFour" :employeeDetail="employeeDetail"></Four>
          <div class="d-flex">
            <v-btn
              v-if="e1 > 1"
              color="primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="backStep(e1)"
            >
              Sebelumnya
            </v-btn>
            <v-btn
              v-if="e1 < 4"
              :disabled="disableNextButton"
              color=" primary ma-4 ml-1 mr-1 f-12 rounded-xl"
              @click="submitStep(e1)"
            >
              Selanjutnya
            </v-btn>
            <v-btn
              v-if="
                e1 === 4 &&
                $store.getters.role == 'USER' &&
                $route.name != 'ViewDetail'
              "
              color=" success ma-4 ml-1 mr-1 rounded-xl f-12"
              @click="submitStep(e1)"
            >
              Submit
            </v-btn>
            <v-btn
              v-if="
                e1 === 4 &&
                $store.getters.role != 'USER' &&
                $route.name != 'ViewDetail'
              "
              color=" success ma-4 ml-1 mr-1 rounded-xl f-12"
              @click="process()"
            >
              Proses
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper>
      <v-stepper v-if="!isMobile" class="w-100" v-model="e1">
        <v-stepper-header fluid class="w-100 stepper-header">
          <div class="d-flex justify-space-between w-100">
            <v-stepper-header class="step-header" fluid>
              <v-stepper-step :complete="e1 > 1" step="1">
                Approval SJL
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 2" step="2">
                Lihat DPM
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 3" step="3">
                Lihat DHT
              </v-stepper-step>
              <v-divider>--</v-divider>
              <v-stepper-step :complete="e1 > 4" step="4">
                Lihat DPB
              </v-stepper-step>
            </v-stepper-header>
            <div>
              <v-btn
                v-if="e1 > 1"
                color="primary ma-4 ml-1 mr-1 f-12 rounded-xl"
                @click="backStep(e1)"
              >
                Sebelumnya
              </v-btn>
              <v-btn
                v-if="e1 < 4"
                :disabled="disableNextButton"
                color=" primary ma-4 ml-1 mr-1 f-12 rounded-xl"
                @click="submitStep(e1)"
              >
                Selanjutnya
              </v-btn>
              <v-btn
                v-if="
                  e1 === 4 &&
                  $store.getters.role == 'USER' &&
                  $route.name != 'ViewDetail'
                "
                color=" success ma-4 ml-1 mr-1 rounded-xl f-12"
                @click="submitStep(e1)"
              >
                Submit
              </v-btn>
              <v-btn
                v-if="
                  e1 === 4 &&
                  $store.getters.role != 'USER' &&
                  $route.name != 'ViewDetail'
                "
                color=" success ma-4 ml-1 mr-1 rounded-xl f-12"
                @click="process()"
              >
                Proses
              </v-btn>
            </div>
          </div>
        </v-stepper-header>

        <v-stepper-items class="step-items overflow-height">
          <v-stepper-content step="1">
            <OneUpdate
              ref="stepOne"
              :employeeDetail="employeeDetail"
              :DHRData="DHRResult"
            ></OneUpdate>
          </v-stepper-content>

          <v-stepper-content step="2">
            <Two ref="stepTwo" :employeeDetail="employeeDetail"></Two>
          </v-stepper-content>

          <v-stepper-content step="3">
            <Three ref="stepThree" :employeeDetail="employeeDetail"></Three>
          </v-stepper-content>

          <v-stepper-content step="4">
            <Four ref="stepFour" :employeeDetail="employeeDetail"></Four>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
    <v-dialog v-model="dialog" max-width="300" height="500">
      <v-card height="50vh">
        <v-card-title class="headline"> Notes </v-card-title>
        <v-card-text class="height-super">
          <v-textarea
            @input="checkNotesEmptyOrNot"
            v-model="notes"
            class="notes"
            outlined
          >
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isApproveLoading"
            @click="toConfirmButton('approve')"
            color="success"
          >
            Approve
          </v-btn>

          <v-btn
            :disabled="disableApproveOrReject"
            @click="toConfirmButton('reject')"
            color="error"
          >
            Reject
          </v-btn>
          <v-progress-circular
            v-if="isApproveLoading"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-card-actions>
      </v-card>
      <v-overlay :value="subapproveOverlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
    <v-dialog v-model="confirmDialog" max-width="300" height="200">
      <v-card height="20vh">
        <v-card-title class="headline"> Apakah anda yakin? </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="disableConfirmButton"
            @click="processSJL(isApproveOrReject)"
            color="success"
          >
            Lanjutkan
          </v-btn>
          <v-btn
            color="error"
            @click="(confirmDialog = false), (dialog = false)"
          >
            Batal
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay :value="approveOverlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import OneUpdate from "./steps/OneUpdate.vue";

import Two from "./steps/Two.vue";
import Three from "./steps/Three.vue";
import Four from "./steps/Four.vue";
import { mapActions } from "vuex";

export default {
  components: {
    OneUpdate,
    Two,
    Three,
    Four,
  },

  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 3000);
    },
  },

  methods: {
    ...mapActions({
      setTotalInbox: "setTotalInbox",
    }),
    checkNotesEmptyOrNot() {
      if (this.notes !== "") {
        this.disableApproveOrReject = false;
      } else {
        this.disableApproveOrReject = true;
      }
    },
    toConfirmButton(isApproveOrReject) {
      if (
        (this.$store.getters.salary === null ||
          this.$store.getters.salary === "") &&
        this.$store.getters.role === "AM" &&
        isApproveOrReject == "approve"
      ) {
        return this.$swal("Gaji Baru Wajib Diisi");
      }
      if (
        this.$store.getters.salaryInvalid === "invalid" &&
        isApproveOrReject == "approve" &&
        (this.$store.getters.role === "AM" || this.$store.getters.role === "BM")
      ) {
        return this.$swal("Masukkan Gaji Yang Valid");
      } else {
        this.confirmDialog = true;
        this.isApproveOrReject = isApproveOrReject;
      }
    },
    process() {
      this.dialog = true;
    },
    async processSJL(type) {
      this.disableConfirmButton = true;
      this.approveOverlay = true;

      if (type === "approve") {
        if (
          this.$store.getters.role === "AM" ||
          this.$store.getters.role === "BM"
        ) {
          await this.updateDPB();
        }
        await this.approveForAM();
      } else {
        await this.reject();
      }
      this.dialog = false;
      this.$store.dispatch("setSalary", null);
      this.$router.push({ name: "Inbox" });
    },
    async updateDPB() {
      await this.$store
        .dispatch("callApiPost", {
          link: "Transaksi",
          raw: {
            code: "6",
            id_dhr: this.$store.getters.id_dhr,
            gaji: this.$store.getters.salary,
            tunjangan: this.$store.getters.allowance,
          },
        })
        .then((response) => {
          this.$swal(response.Message);
        });
    },
    async reject() {
      await this.$store
        .dispatch("callApiPost", {
          link: "Transaksi",
          raw: {
            code: "11",
            nikPejabat: this.$store.getters.nik,
            noDhr: "SJL-" + this.$store.getters.id_dhr,
            notes: this.notes,
          },
        })
        .then((response) => {
          this.$swal.fire({
            title: response.Message,
            text: "SJL-" + this.$store.getters.id_dhr + " sudah di reject",
            icon: "success",
          });
        });
    },
    async approveForAM() {
      await this.$store
        .dispatch("callApiPost", {
          link: "Transaksi",
          raw: {
            code: "9",
            nikPejabat: this.$store.getters.nik,
            noDhr: this.$route.params.no_dhr,
            notes: this.notes,
          },
        })
        .then((response) => {
          if (this.$store.getters.role === "HRM") {
            return this.$swal.fire({
              title: "SJL-" + this.$store.getters.id_dhr,
              html: response.Message,
            });
          }
          this.$swal.fire({
            html: response.Message,
            icon: "success",
          });
        })
        .catch((error) => {
          this.$swal.fire({
            title: error,
            text: "SJL-" + this.$store.getters.id_dhr + " gagal di proses",
            icon: "failed",
          });
        });
    },

    async getDHR() {
      await this.$store
        .dispatch("callApiPost", {
          link: "Transaksi",
          raw: {
            code: "2",
            noDhr: this.$route.params.no_dhr,
          },
        })
        .then((response) => {
          this.DHRResult = response.Message;
          this.$store.dispatch("setIdDhr", this.DHRResult[0].id_dhr);
          this.getEmployeeDetail(this.DHRResult[0].nik);
          this.$store.dispatch("setEmployeeData", this.DHRResult);
          console.log(this.DHRResult[0].jabatan == "Store Jr. Leader" ? 0 : 1);
          this.$store.dispatch(
            "setIsNextStoreSpecial",
            this.DHRResult[0].jabatan == "Store Jr. Leader" ? 0 : 1
          );
        });
    },
    async submitStep(step) {
      if (step === 1) {
        this.disableNextButton = true;
        if (this.$store.getters.isTrained == true) {
          if (this.$store.getters.role === "USER") {
            if (this.$route.name !== "ViewDetail") {
              console.log(this.$store.getters.is6Months);
              if (this.$store.getters.is6Months == false) {
                this.disableNextButton = false;
                return this.$swal.fire({
                  title: "Warning !",
                  html: "Transaksi tidak dapat dibuat. Sesuai dengan ketentuan Memo <b> 397-CPS-22</b> mutasi dari Kelas<br/> <b>Non Special Store</b> ke Kelas <b> Special Store</b> <br/> minimal wajib sudah bekerja selama <b>6</b> Bulan",
                });
              }
              if (this.$store.getters.isEffDateValid == false) {
                this.disableNextButton = false;
                return this.$swal(
                  "Tanggal Efektif tidak boleh lebih kecil daripada tanggal hari ini"
                );
              }
              await this.$refs.stepOne.updateDHR();
            }
          }
          await this.$refs.stepTwo.getDPM();
        } else {
          this.disableNextButton = false;
          return this.$swal({
            title: "Peringatan !",
            html:
              "Karyawan " +
              this.employeeDetail.NAMA +
              " dengan NIK <br/>" +
              this.employeeDetail.NIK +
              " Belum ada data history training SS untuk dimutasikan ke Special Store " +
              "<strong>" +
              "</strong>" +
              "<br/> , " +
              "Jika karyawan sudah mengikuti training, harap update data history training melalui menu upload training di DHR Online (Lama), kemudian proses ulang untuk NIK tersebut <br/>  ",
          });
        }
      } else if (step == 2) {
        this.disableNextButton = true;
        await this.$refs.stepThree.getDHT();
        this.disableNextButton = false;
      } else if (step == 3) {
        this.disableNextButton = true;
        await this.$refs.stepFour.getDPB();
        this.disableNextButton = false;
      } else {
        this.disableNextButton = true;
        await this.$refs.stepFour.submitDraft();
        this.disableNextButton = false;
        this.getInbox();
      }

      this.nextStep(step);
    },
    getInbox() {
      this.$store
        .dispatch("callApiPost", {
          link: "Inbox",
          raw: {
            code: "1",
            dhrType: "SJL",
            nik: this.$store.getters.nik,
            role: this.$store.getters.role,
          },
        })
        .then((response) => {
          let inbox = response.Message;
          this.setTotalInbox(inbox.length);
        });
    },
    backStep(step) {
      if (step === 4) {
        this.$store.dispatch("setFormType", "dht");
      }
      this.e1 = step - 1;
    },
    async nextStep(step) {
      this.e1 = step + 1;
      this.disableNextButton = false;
    },
    async getEmployeeDetail(nik) {
      await this.$store
        .dispatch("callApiPost", {
          link: "Karyawan",
          raw: { code: "2", nik: nik },
        })
        .then((response) => {
          let employeeDetail = response.Message;
          this.employeeDetail = employeeDetail[0];
          this.allApiHasBeenLoaded = true;
          this.overlay = false;
        });
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  beforeMount() {
    this.getDHR();
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  data: () => ({
    isMobile: false,
    isApproveLoading: false,
    subapproveOverlay: false,
    approveOverlay: false,
    overlay: true,
    searchDisabled: true,
    items: ["foo", "bar", "fizz", "buzz"],
    values: ["foo", "bar"],
    value: null,
    storeCodes: [],
    type: "dht",
    isLoading: false,
    DHRResult: "",
    disableNextButton: false,
    isApproveOrReject: "",
    typeRequest: ["Promosi dari SC ke SJL"],
    dialog: false,
    modal: false,
    menu: false,
    requestType: null,
    confirmDialog: false,
    branches: [],
    DhrResult: "",
    notes: "",
    employeeDetail: {},
    allApiHasBeenLoaded: false,
    disableApproveOrReject: true,
    disableConfirmButton: false,
    e1: 1,
    storeCode: null,
    date: new Date().toISOString().substr(0, 10),
    ListEmployeeHeaders: [
      { text: "No", value: "no" },
      { text: "NIK", value: "nik" },
      { text: "Nama Karyawan", value: "name" },
      { text: "Lama Bekerja", value: "join_time" },
      { text: "Jabatan", value: "position" },
      { text: "Bagian ", value: "store_code" },
      { text: "Lama Menjabat ", value: "position_time" },
    ],
    search: "",
    headers: [
      { text: "Tanggal", value: "calories" },
      { text: "Jenis SP", value: "fat" },
      { text: "Tanggal Mulai", value: "carbs" },
      { text: "Tanggal Berakhir", value: "protein" },
      { text: "Keterangan", value: "iron" },
    ],
    scoreHeaders: [
      { text: "Nomor", value: "calories" },
      { text: "Periode Penilaian", value: "fat" },
      { text: "Kategori", value: "carbs" },
    ],
    trainingHeaders: [
      { text: "No", value: "calories" },
      { text: "Tanggal Training", value: "fat" },
      { text: "Judul Training", value: "carbs" },
      { text: "Nilai", value: "protein" },
      { text: "Keterangan", value: "iron" },
    ],
    employeeListData: [],
  }),
};
</script>


<style scoped>
.v-btn--disabled {
  border-radius: 20px !important;
}
.v-textarea textarea {
  line-height: 40px !important;
}
.overflow-hidden {
  overflow: hidden !important;
  width: 100% !important;
}
.notes,
.notes.v-text-field fieldset,
.v-text-field .v-input__control,
.v-text-field .v-input__slot {
  height: 100% !important;
}
</style>