<template>
  <div class="w-100">
    <v-card class="ma-4">
      <v-card-title>
        <div class="d-flex justify-space-between w-100">
          <div>
            <span class="f-14">Kotak Masuk </span>
          </div>

          <div>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
            ></v-text-field>
          </div>
        </div>
      </v-card-title>
      <v-data-table :headers="headers" :items="inboxList" :search="search">
        <template v-slot:item.request_no="{ item }">
          <a href="#" @click="goUpdate(item.request_no)" dark>
            {{ item.request_no }}
          </a>
        </template>
        ></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  mounted() {
    this.getInbox();
  },
  methods: {
    ...mapActions(['setTotalInbox']),
    getInbox() {
      this.$store
        .dispatch("callApiPost", {
          link: "Inbox",
          raw: {
            code: "1",
            dhrType: "SJL",
            nik: this.$store.getters.nik,
            role: this.$store.getters.role,
          },
        })
        .then((response) => {
          let inbox = response.Message;
          this.setTotalInbox(inbox.length)
          for (let i = 0; i < inbox.length; i++) {
            this.inboxList.push({
              no: i + 1,
              request_no: inbox[i].typereq + "-" + inbox[i].formid,
              requestor: inbox[i].requestor_name,
              request_for: inbox[i].nama,
              position: inbox[i].jabatan,
              store_code: inbox[i].bagian_id,
              request_type: inbox[i].typereq,
            });
          }
        });
    },
    goUpdate(request_no) {
      return this.$router.push({ path: "Request/Update/" + request_no });
    },
  },

  data() {
    return {
      inboxList: [],
      search: "",
      headers: [
        { text: "No", value: "no" },
        { text: "No Permohonan", value: "request_no" },
        { text: "Pemohon", value: "requestor" },
        { text: "Permohonan Untuk", value: "request_for" },
        { text: "Jabatan", value: "position" },
        { text: "Bagian", value: "store_code" },
        { text: "Jenis Permohonan", value: "request_type" },
      ],
      scoreHeaders: [
        { text: "Nomor", value: "calories" },
        { text: "Periode Penilaian", value: "fat" },
        { text: "Kategori", value: "carbs" },
      ],
      trainingHeaders: [
        { text: "No", value: "calories" },
        { text: "Tanggal Training", value: "fat" },
        { text: "Judul Training", value: "carbs" },
        { text: "Nilai", value: "protein" },
        { text: "Keterangan", value: "iron" },
      ],
    };
  },
};
</script>
