import VueRouter from 'vue-router';
import Vue from 'vue';
import Login from '../src/components/views/Login.vue'
import Create from '../src/components/views/request/Create.vue'
import Update from '../src/components/views/request/Update.vue'
import View from '../src/components/views/request/View.vue'
import Inbox from '../src/components/views/Inbox.vue'
import Report from '../src/components/views/Report.vue'
import Setting from '../src/components/views/Setting.vue'
import Cookies from 'js-cookie';


const routes = [
  {

    path: "/Login",
    name: "Login",
    component: Login,
  },

  {
    path: "/Request/Create",
    name: "Create",
    component: Create,
  },
  {
    path: "/Request/Update/:no_dhr",
    name: "Update",
    component: Update,
  },
  {
    path: "/Request/ViewDetail/:no_dhr",
    name: "ViewDetail",
    component: Update,
  },
  {
    path: "/Request/View",
    name: "View",
    component: View,
  },
  {
    path: "/Inbox",
    name: "Inbox",
    component: Inbox,
  },
  {
    path: "/Report",
    name: "Report",
    component: Report,
  }, 
  {
    path: "/Setting",
    name: "Setting",
    component: Setting,
  }, 
]

const router = new VueRouter({
  mode: 'history',
  routes,
});

Vue.use(VueRouter);
router.beforeEach((to, from, next) => {
  if (to.path != '/login') {
      if (Cookies.get('token') == null) {
          next('login');
      } else {
          next();
      }
  } else if (to.path == '/login' && Cookies.get('token')) {
      next({
          name: 'Inbox'
      });
  } else {
      next();
  }
  next();
})
export default router;
