<template>
  <div>
    <div v-if="!isMobile" class="d-flex justify-end mt-6">
      <v-btn @click="resetFilter()" color="primary mr-5"
        ><v-icon>mdi-lock-reset</v-icon> Reset</v-btn
      >
      <v-btn :disabled="isSearching" @click="viewReport()" color="primary mr-5"
        ><v-icon>mdi-magnify</v-icon> Cari</v-btn
      >
      <v-btn :disabled="!hasSearched" color="success mr-5">
        <vue-excel-xlsx
          :data="data"
          :columns="columns"
          :filename="'filename'"
          :sheetname="'sheetname'"
        >
          <v-icon>mdi-download</v-icon> Download
        </vue-excel-xlsx>
      </v-btn>
    </div>
    <div class="d-flex justify-end mt-6 mr-4" v-if="isMobile">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on"> Menu </v-btn>
        </template>
        <v-list>
          <v-list-item @click="resetFilter">
            <v-list-item-title>Reset</v-list-item-title>
          </v-list-item>
          <v-list-item @click="viewReport()">
            <v-list-item-title>Cari</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title v-if="hasSearched">Download</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <hr />
    <div class="pa-5 form-scroll">
      <h2>Laporan</h2>
      <h3>Parameter Pencarian</h3>
      <div class="mobile-dense">
        <div v-if="!isMobile">
          <v-row class="pa-5">
            <v-col>
              <v-row class="row-unique">
                <v-col>UNIT</v-col>
                <v-col
                  ><v-autocomplete
                    v-model="unit"
                    @change="hasSearched = false"
                    item-text="name"
                    item-value="id"
                    :items="unitSelect"
                    dense
                    outlined
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row class="row-unique">
                <v-col>CABANG</v-col>
                <v-col
                  ><v-autocomplete
                    @change="hasSearched = false"
                    v-model="branchIndex"
                    item-value="id"
                    item-text="name"
                    :items="branches"
                    dense
                    outlined
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row class="row-unique">
                <v-col>TANGGAL BUAT</v-col>
                <v-col class="d-flex align-center">
                  <v-dialog
                    ref="dialog1"
                    v-model="startDateStartModal"
                    :return-value.sync="startDateStart"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDateStart"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="hasSearched = false"
                      v-model="startDateStart"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="startDateStartModal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog1.save(startDateStart)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <span class="pa-2 mt-2"> s/d </span>
                  <v-dialog
                    ref="dialog2"
                    v-model="startDateEndModal"
                    :return-value.sync="startDateEnd"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="startDateStart === ''"
                        v-model="startDateEnd"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="hasSearched = false"
                      :min="startDateStart"
                      v-model="startDateEnd"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="startDateEndModal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog2.save(startDateEnd)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row class="row-unique">
                <v-col>TANGGAL EFEKTIF</v-col>
                <v-col class="d-flex align-center">
                  <v-dialog
                    ref="dialog3"
                    v-model="startEffectiveDateModal"
                    :return-value.sync="startEffectiveDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startEffectiveDate"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="hasSearched = false"
                      v-model="startEffectiveDate"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="startEffectiveDateModal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog3.save(startEffectiveDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <span class="pa-2 mt-2"> s/d </span>
                  <v-dialog
                    ref="dialog4"
                    v-model="endEffectiveDateModal"
                    :return-value.sync="endEffectiveDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        @change="hasSearched = false"
                        :disabled="startEffectiveDate === ''"
                        v-model="endEffectiveDate"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :min="startEffectiveDate"
                      v-model="endEffectiveDate"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="endEffectiveDateModal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog4.save(endEffectiveDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row class="row-unique">
                <v-col>
                  <span class="mr-2">NOMOR PERMOHONAN </span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on"
                        >mdi-information-outline</v-icon
                      >
                    </template>
                    <span> Contoh :SJL-225</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  ><v-text-field
                    @change="hasSearched = false"
                    v-model="requestNo"
                    dense
                    outlined
                  ></v-text-field
                ></v-col>
              </v-row>
            </v-col>
            <v-col md="1"> </v-col>
            <v-col>
              <v-row class="row-unique">
                <v-col>JENIS PERMOHONAN</v-col>
                <v-col
                  ><v-autocomplete
                    item-text="name"
                    item-value="id"
                    @change="hasSearched = false"
                    :items="requestType"
                    dense
                    outlined
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row class="row-unique">
                <v-col>ALUR FLOW</v-col>
                <v-col
                  ><v-autocomplete
                    @change="returnRequestType"
                    item-text="name"
                    item-value="id"
                    v-model="typeFlowIndex"
                    :items="typeFlow"
                    dense
                    outlined
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row class="row-unique">
                <v-col>STATUS TRANSAKSI</v-col>
                <v-col
                  ><v-autocomplete
                    @change="hasSearched = false"
                    v-model="status"
                    item-text="name"
                    item-value="id"
                    :items="typeStatus"
                    dense
                    outlined
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row class="row-unique">
                <v-col>
                  <span class="mr-2"> NIK </span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on"
                        >mdi-information-outline</v-icon
                      >
                    </template>
                    <span>NIK Karyawan</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  ><v-text-field
                    type="text"
                    @change="hasSearched = false"
                    @keypress="isNumber($event)"
                    dense
                    outlined
                    counter
                    maxlength="10"
                    v-model="nik"
                /></v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div v-else class="pa-4 mt-5">
          <v-row>UNIT</v-row>
          <v-row
            ><v-autocomplete
              v-model="unit"
              @change="hasSearched = false"
              item-text="name"
              item-value="id"
              :items="unitSelect"
              dense
              outlined
            ></v-autocomplete
          ></v-row>
          <v-row>CABANG</v-row>
          <v-row
            ><v-autocomplete
              v-model="branchIndex"
              @change="hasSearched = false"
              item-value="id"
              item-text="name"
              :items="branches"
              dense
              outlined
            ></v-autocomplete
          ></v-row>
          <v-row>TANGGAL BUAT</v-row>
          <v-row>
            <v-dialog
              ref="dialog1"
              v-model="startDateStartModal"
              :return-value.sync="startDateStart"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDateStart"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @change="hasSearched = false"
                v-model="startDateStart"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="startDateStartModal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog1.save(startDateStart)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-dialog
              ref="dialog2"
              v-model="startDateEndModal"
              :return-value.sync="startDateEnd"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :disabled="startDateStart === ''"
                  v-model="startDateEnd"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @change="hasSearched = false"
                :min="startDateStart"
                v-model="startDateEnd"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="startDateEndModal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog2.save(startDateEnd)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-row>
          <v-row> TANGGAL EFEKTIF</v-row>
          <v-row>
            <v-dialog
              ref="dialog3"
              v-model="startEffectiveDateModal"
              :return-value.sync="startEffectiveDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startEffectiveDate"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @change="hasSearched = false"
                v-model="startEffectiveDate"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="startEffectiveDateModal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog3.save(startEffectiveDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-dialog
              ref="dialog4"
              v-model="modal"
              :return-value.sync="endEffectiveDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :disabled="startEffectiveDate === ''"
                  v-model="endEffectiveDate"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @change="hasSearched = false"
                :min="startEffectiveDate"
                v-model="endEffectiveDate"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="endEffectiveDateModal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog4.save(endEffectiveDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog></v-row
          >
          <v-row>NOMOR PERMOHONAN</v-row>
          <v-row class="font-example"> *(CTH : SJL-225 , SJL-001)</v-row>
          <v-row
            ><v-text-field
              @change="hasSearched = false"
              v-model="requestNo"
              dense
              outlined
            ></v-text-field
          ></v-row>
          <v-row>JENIS PERMOHONAN</v-row>
          <v-row
            ><v-autocomplete
              @change="hasSearched = false"
              item-text="name"
              item-value="id"
              :items="requestType"
              return-object
              dense
              outlined
            ></v-autocomplete
          ></v-row>
          <v-row>ALUR FLOW</v-row>
          <v-row
            ><v-autocomplete
              item-text="name"
              item-value="id"
              v-model="typeFlowIndex"
              :items="typeFlow"
              dense
              @change="returnRequestType"
              outlined
            ></v-autocomplete
          ></v-row>

          <v-row>STATUS TRANSAKSI</v-row>
          <v-row
            ><v-autocomplete
              v-model="status"
              item-text="name"
              item-value="id"
              :items="typeStatus"
              @change="hasSearched = false"
              dense
              outlined
            ></v-autocomplete
          ></v-row>
          <v-row>
            <span>NIK (NIK Karyawan)</span>
          </v-row>
          <v-row
            ><v-text-field
              v-model="nik"
              type="text"
              @change="hasSearched = false"
              @keypress="isNumber($event)"
              dense
              outlined
              counter
              maxlength="10"
            ></v-text-field>
          </v-row>
        </div>
        <v-row class="ma-2">
          <v-col md="4" class="pa-3 d-flex flex-column">
            <v-card class="elevation-5 flex d-flex flex-column">
              <v-card-text class="flex">
                <div class="d-flex justify-space-between">
                  <div>
                    <h2 class="mb-10">Jumlah Permohonan</h2>
                    <div class="mb-5">Periode : -</div>
                    <div>
                      Promosi SJL <b>{{ totalRequest }}</b>
                    </div>
                  </div>
                  <div class="d-flex align-center">
                    <h1>
                      <v-icon class="card-icon"
                        >mdi-clipboard-edit-outline</v-icon
                      >
                    </h1>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="4" class="pa-3 d-flex flex-column">
            <v-card class="elevation-5 flex d-flex flex-column">
              <v-card-text class="flex">
                <div class="d-flex justify-space-between">
                  <div>
                    <h2 class="mb-10">Permohonan Selesai</h2>
                    <div class="mb-5">Periode: -</div>
                    <div>
                      Promosi SJL <b>{{ complete }}</b>
                    </div>
                  </div>
                  <div class="d-flex align-center">
                    <h1>
                      <v-icon class="card-icon"
                        >mdi-checkbox-marked-outline
                      </v-icon>
                    </h1>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="4" class="pa-3 d-flex flex-column">
            <v-card class="elevation-5 flex d-flex flex-column">
              <v-card-text class="flex">
                <div class="d-flex justify-space-between">
                  <div>
                    <h2 class="mb-10">Permohonan Pending</h2>
                    <div class="mb-5">Periode -</div>
                    <div>
                      Promosi SJL <b>{{ pending }}</b>
                    </div>
                  </div>
                  <div class="d-flex align-center">
                    <h1>
                      <v-icon class="card-icon">mdi-account-clock</v-icon>
                    </h1>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div class="d-flex justify-end">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            class="shrink"
            single-line
            dense
            hide-details
          ></v-text-field>
        </div>
        <v-data-table :headers="headers" :items="reportList" :search="search">
          <template v-slot:item.request_step="{ item }">
            <a href="#" @click="viewStep(item.request_no)" dark>{{
              item.request_step
            }}</a>
          </template>
          <template v-slot:item.request_no="{ item }">
            <a href="#" @click="viewRequest(item.request_no)" dark>{{
              item.request_no
            }}</a>
          </template>
          ></v-data-table
        >
        <v-dialog width="800px" v-model="dialog">
          <v-card class="step-dialog">
            <h2>Riwayat Approval</h2>
            <v-timeline>
              <v-timeline-item
                v-for="(step, index) in steps"
                v-bind:key="index"
              >
                <template v-slot:icon>
                  <v-icon> mdi-account </v-icon>
                </template>
                <template v-slot:opposite>
                  <h3>{{ step.nama_alur }}</h3>
                </template>
                <v-card class="elevation-2">
                  <v-card-title class="fs-12">
                    {{ returnStatus(step.nik_pic) }}
                  </v-card-title>
                  <v-card-text class="fs-12 pa-0 pl-4"
                    >{{ step.nik_pic }} - {{ step.name_pic }}
                  </v-card-text>
                  <v-card-text class="fs-12">{{ step.tanggal }}</v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Vue from "vue";
import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);
export default {
  mounted() {
    this.getInbox();
    this.onResize();
    this.getAllCabang();
    this.getSteps();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    ...mapActions(["setTotalInbox"]),
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },

    getAllCabang() {
      this.$store
        .dispatch("callApiPost", {
          link: "Bagian",
          raw: {
            code: "2",
          },
        })
        .then((response) => {
          let branches = response.Message;
          if (this.$store.getters.role === "HO") {
            for (let i = 0; i < branches.length; i++) {
              this.branches.push({
                name: branches[i].AreaName,
                id: branches[i].id,
              });
            }
          } else if (this.$store.getters.role === "USER") {
            this.branches.push({
              name: this.$store.getters.cabang,
              id: this.$store.getters.cabang,
            });
          } else {
            this.branches.push({
              name: this.$store.getters.bagian,
              id: this.$store.getters.bagian,
            });
          }
        });
    },
    viewStep(id_dhr) {
      this.$store
        .dispatch("callApiPost", {
          link: "Status",
          raw: {
            code: "4",
            id_dhr: id_dhr.substring(4),
          },
        })
        .then((response) => {
          this.steps = response.Message;
          this.dialog = true;
        });
    },
    viewRequest(id_dhr) {
      return this.$router.push({ path: "Request/ViewDetail/" + id_dhr });
    },
    returnStatus(nik_pic) {
      if (nik_pic === "") {
        return "Pending";
      } else {
        return "Completed";
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    viewReport() {
      this.isSearching = true;
      this.reportList = [];
      this.data = [];
      this.$store
        .dispatch("callApiPost", {
          link: "Status",
          raw: {
            code: "6",
            unit: this.unit,
            role: this.$store.getters.role,
            nikPejabat: this.$store.getters.nik,
            noPermohonan: this.requestNo,
            cabang: this.branchIndex,
            ke:
              this.startDateEnd === ""
                ? this.startDateStart
                : this.startDateEnd,
            dari: this.startDateStart,
            tanggalEfektifDari: this.startEffectiveDate,
            tanggalEfektifKe:
              this.endEffectiveDate === ""
                ? this.startEffectiveDate
                : this.endEffectiveDate,
            jenisPermohonan: "SJL",
            alur: this.typeFlowIndex,
            status: this.status,
            nik: this.nik,
          },
        })
        .then((response) => {
          for (let i = 0; i < response.Message.length; i++) {
            this.data.push(response.Message[i]);
          }
        });

      this.$store
        .dispatch("callApiPost", {
          link: "Status",
          raw: {
            code: "3",
            unit: this.unit,
            role: this.$store.getters.role,
            nikPejabat: this.$store.getters.nik,
            noPermohonan: this.requestNo,
            cabang: this.branchIndex,
            ke:
              this.startDateEnd === ""
                ? this.startDateStart
                : this.startDateEnd,
            dari: this.startDateStart,
            tanggalEfektifDari: this.startEffectiveDate,
            tanggalEfektifKe:
              this.endEffectiveDate === ""
                ? this.startEffectiveDate
                : this.endEffectiveDate,
            jenisPermohonan: "SJL",
            alur: this.typeFlowIndex,
            status: this.status,
            nik: this.nik,
          },
        })
        .then((response) => {
          let report = response.Message.detail;
          this.pending = response.Message.pending;
          this.complete = response.Message.complete;
          this.totalRequest = response.Message.total;

          for (let i = 0; i < report.length; i++) {
            this.reportList.push({
              no: i + 1,
              request_no: report[i].nopermohonan,
              request_type: "SJL",
              request_unit: report[i].unit,
              branch: report[i].homebase,
              department: report[i].bagian,
              request_for: report[i].nama,
              request_date: report[i].tanggal_dibuat,
              effective_date: report[i].effective_date,
              request_step: report[i].alur,
              status: report[i].status,
              request_pic: report[i].next_pic,
            });
          }
        });
      this.isSearching = false;
      this.hasSearched = true;
    },

    getInbox() {
      this.$store
        .dispatch("callApiPost", {
          link: "Inbox",
          raw: {
            code: "1",
            dhrType: "SJL",
            nik: this.$store.getters.nik,
            role: this.$store.getters.role,
          },
        })
        .then((response) => {
          let inbox = response.Message;
          this.setTotalInbox(inbox.length);
          for (let i = 0; i < inbox.length; i++) {
            this.inboxList.push({
              no: i + 1,
              request_no: inbox[i].typereq + "-" + inbox[i].formid,
              requestor: inbox[i].requestor_name,
              request_for: inbox[i].nama,
              position: inbox[i].jabatan,
              store_code: inbox[i].bagian_id,
              request_type: inbox[i].typereq,
            });
          }
        });
    },
    goUpdate(request_no) {
      return this.$router.push({ path: "Request/Update/" + request_no });
    },
    resetFilter() {
      (this.hasSearched = false),
        (this.date = ""),
        (this.unit = ""),
        (this.status = ""),
        (this.startDateStart = ""),
        (this.startDateEnd = ""),
        (this.startEffectiveDate = ""),
        (this.startEffectiveDateModal = ""),
        (this.endEffectiveDateModal = ""),
        (this.endEffectiveDate = ""),
        (this.nik = ""),
        (this.typeFlowIndex = ""),
        (this.requestNo = ""),
        (this.branchIndex = ""),
        (this.requestType = "");
    },
    getSteps() {
      this.$store
        .dispatch("callApiPost", {
          link: "Status",
          raw: {
            code: "5",
          },
        })
        .then((response) => {
          let steps = response.Message;
          for (let i = 0; i < steps.length; i++) {
            this.typeFlow.push({ id: steps[i].role, name: steps[i].nama_alur });
          }
        });
    },
    returnRequestType() {
      this.hasSearched = false;
      if (this.typeFlowIndex === "USER") {
        this.typeStatus = [
          { id: "D", name: "Draft" },
          { id: "R", name: "Reject" },
        ];
      } else if (this.typeFlowIndex === "AM" || this.typeFlowIndex === "BM") {
        this.typeStatus = [
          { id: "W", name: "Pending" },
          { id: "R", name: "Reject" },
        ];
      } else if (this.typeFlowIndex === "HRM") {
        this.typeStatus = [
          { id: "W", name: "Pending" },
          { id: "R", name: "Reject" },
          { id: "C", name: "Selesai" },
        ];
      }
    },
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  data() {
    return {
      columns: [
        {
          label: "ID_SJL",
          field: "id",
        },
        {
          label: "TGL_INPUT",
          field: "tglinput",
        },
        {
          label: "NAMA CABANG",
          field: "homebase",
        },
        {
          label: "NO DPM(ACL)",
          field: "actioniddpm",
        },
        {
          label: "TANGGAL EFEKTIF DPM",
          field: "effectivedpm",
        },
        {
          label: "NO DHT(ACL)",
          field: "actioniddht",
        },
        {
          label: "TANGGAL EFEKTIF DHT",
          field: "effectivedht",
        },
        {
          label: "NO DPB(IDM)",
          field: "actioniddpb",
        },
        {
          label: "TANGGAL EFEKTIF DPB",
          field: "effectivedpb",
        },
        {
          label: "KODE TOKO ASAL",
          field: "bagian",
        },
        {
          label: "KODE TOKO TUJUAN",
          field: "tokotujuan",
        },
        {
          label: "NIK KARYAWAN",
          field: "nik",
        },
        {
          label: "NAMA KARYAWAN",
          field: "nama",
        },
        {
          label: "NIK AS",
          field: "nikas",
        },
        {
          label: "TANGGAL PROSES AM",
          field: "approveam",
        },
        {
          label: "TANGGAL PROSES BM",
          field: "approvebm",
        },
        {
          label: "TANGGAL PROSES HRM",
          field: "approvehrm",
        },
        {
          label: "STATUS",
          field: "nama_alur",
        },
        {
          label: "CURRENT USER",
          field: "curruser",
        },
      ],
      data: [],
      steps: "",
      items: [
        { id: 1, name: "A", description: "Short", showButtons: true },
        { id: 2, name: "B", description: "Short", showButtons: false },
        {
          id: 3,
          name: "C",
          description: "Longer text that wraps onto multiple lines",
          showButtons: true,
        },
      ],
      pending: "0",
      complete: "0",
      requestNo: "",
      totalRequest: "0",
      startDateStartModal: false,
      startDateEndModal: false,
      date: "",
      unit: "",
      status: "",
      startDateStart: "",
      hasSearched: false,
      startDateEnd: "",
      startEffectiveDate: "",
      startEffectiveDateModal: "",
      endEffectiveDateModal: "",
      endEffectiveDate: "",
      nik: "",
      typeFlowIndex: "",
      modal: false,
      unitSelect: [
        { id: "IDM", name: "PT INDOMARCO PRISMATAMA" },
        { id: "ACL", name: "PT ALBANY CORONA LESTARI" },
        { id: "", name: "ALL" },
      ],
      requestType: [{ id: "SJL", name: "Promosi Ke SJL" }],
      typeFlow: [],
      isMobile: false,
      dialog: false,
      inboxList: [],
      search: "",
      branches: [],
      reportList: [],
      branchIndex: "",
      typeStatus: [],
      isSearching: false,
      headers: [
        { text: "No", value: "no" },
        { text: "No Permohonan", value: "request_no" },
        { text: "Jenis Permohonan", value: "request_type" },
        { text: "Unit", value: "request_unit" },
        { text: "Cabang", value: "branch" },
        { text: "Bagian", value: "department" },
        { text: "Permohonan Untuk", value: "request_for" },
        { text: "Tanggal dibuat", value: "request_date" },
        { text: "Tanggal Efektif", value: "effective_date" },
        { text: "Alur", value: "request_step" },
        { text: "Status", value: "status" },
        { text: "PIC", value: "request_pic" },
      ],

      scoreHeaders: [
        { text: "Nomor", value: "calories" },
        { text: "Periode Penilaian", value: "fat" },
        { text: "Kategori", value: "carbs" },
      ],
      trainingHeaders: [
        { text: "No", value: "calories" },
        { text: "Tanggal Training", value: "fat" },
        { text: "Judul Training", value: "carbs" },
        { text: "Nilai", value: "protein" },
        { text: "Keterangan", value: "iron" },
      ],
    };
  },
};
</script>


<style lang="scss" scoped>
.fs-12 {
  font-size: 12px !important;
}
.step-dialog {
  padding: 2rem !important;
}
.row-unique {
  display: flex !important;
  align-items: center !important;
}
.card-icon {
  font-size: 54px !important;
}
hr {
  margin-top: 10px;
  border-bottom: 1px solid silver !important;
}
.form-scroll {
  height: 80vh;
  overflow: scroll;
}
.mobile-dense {
  height: fit-content;
  overflow: scroll;
}
html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
}
html {
  overflow: -moz-scrollbars-none;
}
.fz-10 {
  font-size: 10px !important;
}
.font-example {
  font-size: 10px;
  color: red !important;
}
</style>