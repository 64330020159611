<template>
  <div>
    <div v-if="!isMobile" class="mt-4">
      <v-row class="row-padding-2">
        <v-col
          class="align-center d-flex  justify-end pb-0 pt-0"
          cols="12"
          sm="6"
          md="2"
        >
          <div>
            <span class="font-weight-bold">Unit </span>
          </div>
        </v-col>

        <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
          <v-text-field
            class="b-disabled"
            :value="employeeDetail.Unit"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>

        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold">Unit </span>
          </div>
        </v-col>

        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            :value="changeCompanyName(DPBData.unit)"
            class="b-disabled"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="row-padding-2">
        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold"> Bagian </span>
          </div>
        </v-col>

        <v-col cols="12" sm="3" md="3" class="pb-0 pt-0 align-center d-flex">
          <v-text-field
            :value="employeeDetail.BAGIAN + '-' + employeeDetail.NAMA_TOKO"
            class="b-disabled"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>

        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold"> Bagian </span>
          </div>
        </v-col>

        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            :value="DPBData.bagian_id + '-' + DPBData.bagian"
            class="b-disabled"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="row-padding-2">
        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold"> Jabatan </span>
          </div>
        </v-col>

        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            :value="employeeDetail.JABATAN"
            class="b-disabled"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>

        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold"> Jabatan </span>
          </div>
        </v-col>

        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            :value="DPBData.jabatan"
            class="b-disabled"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="row-padding-2">
        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold">Job Class</span>
          </div>
        </v-col>

        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            :value="employeeDetail.JOB_CLASS"
            class="b-disabled"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>

        <v-col
          class="align-center d-flex justify-end pb-0 pt-0 text-lg-right"
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold">Job Class</span>
          </div>
        </v-col>

        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            :value="DPBData.jobclass"
            class="b-disabled"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="row-padding-2">
        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="2"
        >
        </v-col>
        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="3"
        >
        </v-col>

        <v-col
          class="align-center d-flex justify-end pb-0 pt-0 text-lg-right"
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold"> Tipe Karyawan </span>
          </div>
        </v-col>

        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            :value="DPBData.status_karyawan"
            class="b-disabled"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="row-padding-2">
        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="2"
        >
        </v-col>
        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="3"
        >
        </v-col>

        <v-col
          class="align-center d-flex justify-end pb-0 pt-0 text-lg-right"
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold"> Tanggal Mulai Bekerja </span>
          </div>
        </v-col>

        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            :value="DPBData.effective_date"
            class="b-disabled"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="row-padding-2">
        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="2"
        >
        </v-col>
        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="3"
        >
        </v-col>

        <v-col
          class="align-center d-flex justify-end pb-0 pt-0 text-lg-right"
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold"> Tanggal Berakhir Kontrak</span>
          </div>
        </v-col>

        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            :value="endDate"
            class="b-disabled"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-col
        class="align-center d-flex justify-end pb-0 pt-0"
        cols="12"
        sm="3"
        md="2"
      >
      </v-col>
      <v-col
        class="align-center d-flex justify-end pb-0 pt-0"
        cols="12"
        sm="3"
        md="3"
      >
      </v-col>
      <v-container class="d-flex justify-end">
        <v-row style="height: 100px">
          <v-col
            class="align-center d-flex justify-end pb-0 pt-0"
            cols="12"
            sm="3"
            md="2"
          >
          </v-col>
          <v-col
            class="align-center d-flex justify-end pb-0 pt-0"
            cols="12"
            sm="3"
            md="3"
          >
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <div class="silver pa-1 pl-4 mb-4 w-100-idx mt-8">
        <span class="white--text font-weight-bold">Data Saat Ini</span>
      </div>
      <v-row>
        <v-col
          class="align-center d-flex justify-end "
          cols="12"
          sm="6"
          md="2"
        >
          <div>
            <span class="font-weight-bold">Unit </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class=" align-center d-flex">
          <v-text-field
            class="b-disabled text-right-form"
            :value="employeeDetail.Unit"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="align-center d-flex  "
          cols="12"
          sm="6"
          md="2"
        >
          <div>
            <span class="font-weight-bold">Bagian </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class=" align-center d-flex">
          <v-text-field
            :value="employeeDetail.BAGIAN + '-' + employeeDetail.NAMA_TOKO"
            class="b-disabled text-right-form"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="align-center d-flex  "
          cols="12"
          sm="6"
          md="2"
        >
          <div>
            <span class="font-weight-bold">Jabatan </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class=" align-center d-flex">
          <v-text-field
            :value="employeeDetail.JABATAN"
            class="b-disabled text-right-form"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="align-center d-flex  "
          cols="12"
          sm="6"
          md="2"
        >
          <div>
            <span class="font-weight-bold">Job Class </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class=" align-center d-flex">
          <v-text-field
            :value="employeeDetail.JOB_CLASS"
            class="b-disabled text-right-form"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>


<script>
export default {
  props: ["DPBData", "employeeDetail"],
  created() {
    this.notes = this.DPBData.memo;
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    async submitFinalDraftForAS() {
      await this.$store
        .dispatch("callApiPost", {
          link: "Transaksi",
          raw: {
            code: "8",
            nikPejabat: this.$store.getters.nik,
            id_dhr: this.$store.getters.id_dhr,
            notes: this.notes,
          },
        })
        .then((response) => {
          console.log(response);
          this.$swal("SJL-" + this.$store.getters.id_dhr + " Sudah diproses");
          this.$router.push({ name: "Inbox" });
        });
    },
    changeCompanyName(companyName) {
      if (companyName === "IDM") {
        return "PT INDOMARCO PRISMATAMA";
      } else {
        return "PT ALBANY CORONA LESTARI";
      }
    },
  },

  data() {
    return {
      newUnit: this.DPBData.unit,
      newBagian: this.DPBData.bagian_id + this.DPBData.bagian,
      newPosition: this.DPBData.jabatan,
      newCodePosition: this.DPBData.kode_jabatan,
      newJobClass: this.DPBData.jobclass,
      newEmployeeType: this.DPBData.status_karyawan,
      effectiveDate: this.DPBData.effective_date,
      endDate: "31 Dec 2154",
      notes: this.notes,
      isMobile:false,
    };
  },
};
</script>


<style scoped>
.w-68 {
  width: 68%;
}
.text-right-form >>> input {
  text-align: right !important;
}
</style>