<template>
  <div>
    <div v-if="!isMobile">
      <employee-detail-form :employeeDetail="employeeDetail"></employee-detail-form>

      <div class="mt-4">
        <div>
          <div class="silver pa-1 pl-4 mt-4 d-flex justify-center">
            <span class="font-weight-bold white--text mr-14">Data Usulan Perubahan</span>
          </div>
          <v-row class="mt-2" col="12">
            <v-col class="d-flex" md="6">
              <v-row cols="6">
                <v-col class="d-flex justify-center" md="6">
                  <span>Data Saat Ini</span>
                </v-col>
                <v-col md="3">
                  <span></span>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-flex justify-center" md="6">
              <v-row cols="6">
                <v-col class="d-flex" md="3">
                  <span>Data Usulan</span>
                </v-col>
                <v-col md="3">
                  <span></span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="d-flex">
          <div class="col-5">
            <div class="d-flex mb-4 align-center align-center">
              <div class="text-right w-40">Status Kerja:</div>

              <v-text-field class="b-disabled" :value="employeeDetail.STATUS_KERJA" dense disabled outlined>
              </v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Hari Kerja:</div>
              <v-text-field class="b-disabled" :value="changeShiftValue(employeeDetail.ID_GROUP_SHIFT)" dense disabled
                outlined></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Unit:</div>
              <v-text-field class="b-disabled" :value="employeeDetail.Unit" dense disabled outlined></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Bagian:</div>
              <v-text-field class="b-disabled" :value="employeeDetail.BAGIAN + '-' + employeeDetail.NAMA_TOKO" dense
                disabled outlined></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Kelas Toko</div>
              <v-text-field class="b-disabled" v-model="firstStoreStatus" dense disabled outlined></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Jabatan :</div>
              <v-text-field :value="employeeDetail.JABATAN" class="b-disabled" dense disabled outlined></v-text-field>
            </div>

            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Job Class:</div>
              <v-text-field :value="employeeDetail.JOB_CLASS" class="b-disabled" dense disabled outlined></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Area Manager:</div>
              <v-text-field class="b-disabled" :value="employeeDetail.NAMA_AM" dense disabled outlined></v-text-field>
            </div>
          </div>
          <div class="col-5">
            <div class="d-flex mb-4 align-center align-center">
              <div class="text-right w-40">Status Kerja:</div>

              <v-combobox outlined v-model="contractType" placeholder="Pilih Status Kerja"
                :items="$store.getters.contractTypeSelect" item-text="name" return-object dense solo></v-combobox>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Hari Kerja:</div>
              <v-autocomplete outlined v-model="shift" :disabled="$store.getters.role != 'USER'"
                placeholder="Pilih Hari Kerja" :items="$store.getters.shiftSelect" item-text="name" item-value="value"
                dense solo></v-autocomplete>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Unit:</div>
              <v-combobox outlined v-model="unit" placeholder="Pilih Unit" :items="$store.getters.unitSelect"
                item-text="name" item-value="id" return-object dense solo></v-combobox>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Bagian:</div>
              <v-text-field append-icon="mdi-magnify" @click:append="dialog = true" v-if="isDefault"
                @mouseover="showFullName" @mouseleave="showBranch = false"
                :value="employeeDetail.BAGIAN + '-' + employeeDetail.NAMA_TOKO" readonly
                :disabled="$store.getters.role != 'USER'" dense outlined class="b-disabled">
              </v-text-field>

              <v-text-field v-if="!isDefault" @click:append="dialog = true" @mouseover="showNewBranch = true"
                @mouseleave="showNewBranch = false" v-model="storeCodeFinal" append-icon="mdi-magnify"
                :disabled="$store.getters.role != 'USER'" dense outlined class="b-disabled">
              </v-text-field>

              <div>
                <v-row justify="center">
                  <v-dialog v-model="dialog" scrollable max-width="600px">
                    <v-card>
                      <v-card-title>Pilih Bagian</v-card-title>
                      <v-divider></v-divider>
                      <v-card-text style="height: 700px">
                        <div class="d-flex align-center">
                          <div class="d-flex justify-end w-20">Kode Toko</div>
                          <v-text-field label="Ketik Nama/ Kode Toko" class="ml-2" @input="changeDisabled"
                            v-model="storeCode" dense outlined>
                          </v-text-field>
                        </div>
                        <div class="d-flex align-center">
                          <div class="d-flex justify-end w-20">Cabang</div>

                          <v-autocomplete outlined class="choose-branch mt-2 ml-2" label="Pilih Cabang"
                            @change="changeDisabled" v-model="branchIndex" item-value="id" item-text="name"
                            :items="branches" dense>
                          </v-autocomplete>
                        </div>
                        <div class="w-20 d-flex mt-4 w-34 justify-end">
                          <v-btn :disabled="isBranchEmpty" @click="searchDepartment" color="primary mt-2 rounded-lg ">
                            Cari</v-btn>
                        </div>
                        <div class="overflow-table">
                          <v-row class="border-table">
                            <v-col cols="12" md="4"> Kode Toko </v-col>
                            <v-col cols="12" md="8"> Nama Toko </v-col>
                          </v-row>
                          <v-row v-if="isBranchLoading" class="d-flex justify-center mt-10">
                            <v-progress-circular :size="150" color="primary" indeterminate>
                            </v-progress-circular>
                          </v-row>
                          <v-row v-for="(code, index) in storeCodeBranches" v-bind:key="index">
                            <v-col cols="12" md="4">{{ code.KODETOKO }}</v-col>
                            <v-col class="d-flex justify-space-between align-center" cols="12" md="8">
                              <div>
                                <span>{{ code.NAMATOKO }}</span>
                              </div>
                              <v-btn @click="saveDepartment(code)">
                                Pilih
                              </v-btn>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card-text>x
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-btn color="blue darken-1" text @click="dialog = false">
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </div>
            </div>
            <div v-show="showBranch" class="hover-class">
              <p>
                {{ employeeDetail.BAGIAN + "-" + employeeDetail.NAMA_TOKO }}
              </p>
            </div>
            <div v-show="showNewBranch" class="hover-class">
              <p>{{ storeCodeFinal }}</p>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Kelas Toko</div>
              <v-text-field class="b-disabled" v-model="nextStoreStatus" dense disabled outlined></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Jabatan</div>
              <v-combobox outlined dense @change="checkIsTrained()" v-if="isComboBox" v-model="position"
                :items="positionType" item-text="name" item-value="name" solo :return-object="false">
              </v-combobox>
              <v-text-field v-else class="b-disabled" v-model="position" dense disabled outlined></v-text-field>
            </div>
            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Job Class:</div>
              <v-combobox outlined disabled v-model="jobClass" placeholder="Pilih Job Class"
                :items="$store.getters.jobClassSelect" item-text="name" item-value="name" dense solo></v-combobox>
            </div>

            <div class="d-flex mb-4 align-center">
              <div class="text-right w-40">Tanggal Efektif:</div>
              <div @click="giveWarning()">
                <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="computedDateFormattedMomentjs" label="Picker in dialog"
                      prepend-icon="mdi-calendar" :disabled="disableDate" v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker :min="minDate()" :max="maxDate()" v-model="effectiveDate" @change="isLastDay()"
                    scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.dialog.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <employee-detail-form-mobile :employeeDetail="employeeDetail" />
      <v-row>
        <v-col>
          <div class="silver pa-1 pl-4 mt-4">
            <span class="font-weight-bold white--text mr-14">Data Usulan Perubahan</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <span>Data Saat Ini</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex justify-end pb-0 pt-0" cols="12" sm="3" md="2">
          <div class="text-right">
            <b>Status Kerja</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field class="b-disabled" :value="employeeDetail.STATUS_KERJA" dense disabled outlined></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex justify-end pb-0 pt-0" cols="12" sm="3" md="2">
          <div class="text-right">
            <b>Hari Kerja</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field class="b-disabled" :value="changeShiftValue(employeeDetail.ID_GROUP_SHIFT)" dense disabled
            outlined></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex justify-end pb-0 pt-0" cols="12" sm="3" md="2">
          <div class="text-right">
            <b>Unit</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field class="b-disabled" :value="employeeDetail.Unit" dense disabled outlined></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex justify-end pb-0 pt-0" cols="12" sm="3" md="2">
          <div class="text-right">
            <b>Bagian</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field class="b-disabled" :value="employeeDetail.BAGIAN + '-' + employeeDetail.NAMA_TOKO" dense
            disabled outlined></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex justify-end pb-0 pt-0" cols="12" sm="3" md="2">
          <div class="text-right">
            <b>Kelas Toko</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field class="b-disabled" v-model="firstStoreStatus" dense disabled outlined></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex justify-end pb-0 pt-0" cols="12" sm="3" md="2">
          <div class="text-right">
            <b>Jabatan</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field :value="employeeDetail.JABATAN" class="b-disabled" dense disabled outlined></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex justify-end pb-0 pt-0" cols="12" sm="3" md="2">
          <div class="text-right">
            <b>Job Class</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field :value="employeeDetail.JOB_CLASS" class="b-disabled" dense disabled outlined></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex justify-end pb-0 pt-0" cols="12" sm="3" md="2">
          <div class="text-right">
            <b>Area Manajer</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field :value="employeeDetail.NAMA_AM" class="b-disabled" dense disabled outlined></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <span>Data Usulan</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex justify-end pb-0 pt-0" cols="12" sm="3" md="2">
          <div class="text-right">
            <b>Status Kerja</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-combobox outlined v-model="contractType" placeholder="Pilih Status Kerja"
            :items="$store.getters.contractTypeSelect" item-text="name" return-object dense solo></v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex justify-end pb-0 pt-0" cols="12" sm="3" md="2">
          <div class="text-right">
            <b>Hari Kerja</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete outlined v-model="shift" :disabled="$store.getters.role != 'USER'"
            placeholder="Pilih Hari Kerja" :items="$store.getters.shiftSelect" item-text="name" item-value="value" dense
            solo></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex justify-end pb-0 pt-0" cols="12" sm="3" md="2">
          <div class="text-right">
            <b>Unit</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-combobox outlined v-model="unit" placeholder="Pilih Unit" :items="$store.getters.unitSelect"
            item-text="name" item-value="id" return-object dense solo></v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex justify-end pb-0 pt-0" cols="12" sm="3" md="2">
          <div class="text-right">
            <b>Bagian</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field append-icon="mdi-magnify" @click:append="dialog = true" v-if="isDefault"
            @mouseover="showFullName" @mouseleave="showBranch = false"
            :value="employeeDetail.BAGIAN + '-' + employeeDetail.NAMA_TOKO" readonly
            :disabled="$store.getters.role != 'USER'" dense outlined class="b-disabled">
          </v-text-field>
          <v-text-field v-if="!isDefault" @click:append="dialog = true" @mouseover="showNewBranch = true"
            @mouseleave="showNewBranch = false" v-model="storeCodeFinal" append-icon="mdi-magnify"
            :disabled="$store.getters.role != 'USER'" dense outlined class="b-disabled">
          </v-text-field>
          <div>
            <v-row justify="center">
              <v-dialog v-model="dialog" scrollable max-width="600px">
                <v-card>
                  <v-card-title>Pilih Bagian</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text style="height: 40vh">
                    <div class="d-flex align-center">
                      <div class="d-flex justify-end mb-5">Kode Toko</div>
                    </div>
                    <v-row>
                      <v-text-field label="Ketik Nama/ Kode Toko" class="ml-2" @input="changeDisabled"
                        v-model="storeCode" dense outlined>
                      </v-text-field>
                    </v-row>

                    <div class="d-flex align-center">
                      <div class="d-flex mt-8">Cabang</div>
                    </div>
                    <v-row>
                      <v-col>
                        <v-autocomplete outlined class="choose-branch mt-2" label="Pilih Cabang"
                          @change="changeDisabled" v-model="branchIndex" item-value="id" item-text="name"
                          :items="branches" dense>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <div class="d-flex mt-4 justify-end">
                      <v-btn :disabled="isBranchEmpty" @click="searchDepartment" color="primary mt-2 rounded-lg ">Cari
                      </v-btn>
                    </div>
                    <div class="overflow-table">
                      <v-row class="border-table">
                        <v-col> Kode Toko </v-col>
                        <v-col> Nama Toko </v-col>
                      </v-row>
                      <v-row v-if="isBranchLoading" class="d-flex justify-center mt-10">
                        <v-progress-circular :size="150" color="primary" indeterminate>
                        </v-progress-circular>
                      </v-row>
                      <v-row v-for="(code, index) in storeCodeBranches" v-bind:key="index">
                        <v-col cols="12" md="4">{{ code.KODETOKO }}</v-col>
                        <v-col class="d-flex justify-space-between align-center" cols="12" md="8">
                          <div>
                            <span>{{ code.NAMATOKO }}</span>
                          </div>
                          <v-btn @click="saveDepartment(code)"> Pilih </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex justify-end pb-0 pt-0" cols="12" sm="3" md="2">
          <div class="mt-4 text-right">
            <b>Kelas Toko</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field class="b-disabled" v-model="nextStoreStatus" dense disabled outlined></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex justify-end pb-0 pt-0" cols="12" sm="3" md="2">
          <div class="mt-4 text-right">
            <b>Jabatan</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-combobox outlined dense @change="checkIsTrained()" v-if="isComboBox" v-model="position"
            :items="positionType" item-text="name" item-value="name" solo :return-object="false">
          </v-combobox>
          <v-text-field v-if="!isComboBox" class="b-disabled" v-model="position" dense disabled outlined></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex justify-end pb-0 pt-0" cols="12" sm="3" md="2">
          <div class="mt-4 text-right">
            <b>Job Class</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-combobox outlined disabled v-model="jobClass" placeholder="Pilih Job Class"
            :items="$store.getters.jobClassSelect" item-text="name" item-value="name" dense solo></v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="align-center d-flex justify-end pb-0 pt-0" cols="12" sm="3" md="2">
          <div class="mt-4 text-right">
            <b>Tanggal Efektif</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div @click="giveWarning()">
            <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="effectiveDate" :value="computedDateFormattedMomentjs" label="Picker in dialog"
                  prepend-icon="mdi-calendar" :disabled="disableDate" v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker :min="minDate()" :max="maxDate()" v-model="effectiveDate" @change="isLastDay()" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import EmployeeDetailForm from "../employee/DetailForm.vue";
import EmployeeDetailFormMobile from "../employee/DetailFormMobile.vue";
export default {
  components: {
    EmployeeDetailForm,
    EmployeeDetailFormMobile,
  },

  props: ["employeeDetail", "responseResult"],
  created() {
    this.jobClass = this.employeeDetail.JOB_CLASS == "A1" ? "A2" : "A3";
    this.$store.dispatch("setFormType", "dht");
    this.minDate();
    (this.selectedStoreCode = this.employeeDetail.NAMA_TOKO),
      (this.selectedStoreCodeID = this.employeeDetail.BAGIAN);
    this.branchIndex = this.employeeDetail.NAMA_CABANG;
    this.shift = this.employeeDetail.ID_GROUP_SHIFT;

    this.maxDate();
    this.getAllCabang();
    this.setDefaultDate();
    this.checkIfEmployeeFromSpecialStore();
    this.checkIsTrainedInitially();
    this.checkIfCurrentEmployeeHasWorkedFor6Months();
    this.nextStoreStatusMethod();
    this.codeTitlePosition();
    this.isComboboxActive();
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.effectiveDate
        ? moment(this.effectiveDate).format("DD MMMM YYYY")
        : "";
    },
  },

  methods: {
    showFullName: function () {
      this.showBranch = !this.showBranch;
    },
    isComboboxActive() {
      if (
        this.employeeDetail.JABATAN == "Store Crew Boy" ||
        this.employeeDetail.JABATAN == "Store Crew Girl"
      ) {
        this.isComboBox = true;
      }
    },

    checkIsTrainedInitially() {
      let self = this;
      if (self.position == "Store Jr. Leader (Ss)") {
        this.$store
          .dispatch("callApiPost", {
            link: "Karyawan",
            raw: {
              code: "3",
              nik: this.employeeDetail.NIK,
            },
          })
          .then((response) => {
            self.isTrained = response.Message == "" ? false : true;
            if (this.isTrained == false) {
              this.$store.dispatch("setIsTrained", false);
            } else {
              this.$store.dispatch("setIsTrained", true);
            }
          });
      } else {
        this.$store.dispatch("setIsTrained", true);
      }
    },

    checkIsTrained() {
      let self = this;
      if (self.position == "Store Jr. Leader (Ss)") {
        this.$store
          .dispatch("callApiPost", {
            link: "Karyawan",
            raw: {
              code: "3",
              nik: this.employeeDetail.NIK,
            },
          })
          .then((response) => {
            self.isTrained = response.Message;
            if (this.isTrained === "") {
              this.$store.dispatch("setIsTrained", false);
              this.$swal({
                title: "Peringatan !",
                html:
                  "Karyawan " +
                  this.employeeDetail.NAMA +
                  " dengan NIK " +
                  this.employeeDetail.NIK +
                  " Belum ada data history training SS untuk dimutasikan ke Special Store " +
                  "<strong>" +
                  this.storeCodeFinal +
                  "</strong>" +
                  "<br/>  " +
                  "Jika karyawan sudah mengikuti training, harap update data history training melalui menu upload training di DHR Online (Lama), kemudian proses ulang untuk NIK tersebut <br/>  ",
                showCloseButton: true,
              });
            } else {
              this.$store.dispatch("setIsTrained", true);
            }
          });
      } else {
        this.$store.dispatch("setIsTrained", true);
      }
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    giveWarning() {
      if (this.disableDate === true && this.$store.getters.role == "USER") {
        this.$swal({
          customClass: "swal-wide",
          text: "Tanggal tidak bisa diubah, karena bagian tidak berubah, Permohonan SJL hanya dianggap sebagai transaksi Promosi dengan tanggal efektif setiap tanggal 1 awal bulan",
        });
      }
    },
    changeDisabled() {
      if (this.branchIndex != " " && this.storeCodeIndex != " ") {
        this.isBranchEmpty = false;
      }
    },
    setDefaultDate() {
      this.disableDate = true;
      let firstDateOfCurrentMonth = moment()
        .clone()
        .startOf("month")
        .format("YYYY-MM-DD");
      let firstDateOfNextMonthUnformatted = moment(firstDateOfCurrentMonth)
        .add(1, "M")
        .format("YYYY-MM-DD");

      this.effectiveDate = firstDateOfNextMonthUnformatted;
    },
    isLastDay() {
      this.checkIfCurrentEmployeeHasWorkedFor6Months();
      let firstDateOfCurrentMonth = moment()
        .clone()
        .startOf("month")
        .format("YYYY-MM-DD");
      let firstDateOfNextMonthUnformatted = moment(firstDateOfCurrentMonth)
        .add(1, "M")
        .format("YYYY-MM-DD");

      let lastdayOfTheMonth = moment().endOf("month").format("YYYY-MM-DD");
      let lastdayOfNextMonth = moment(firstDateOfNextMonthUnformatted)
        .endOf("month")
        .format("YYYY-MM-DD");

      if (
        this.effectiveDate == lastdayOfTheMonth ||
        this.effectiveDate == lastdayOfNextMonth
      ) {
        this.$swal(
          "Tanggal efektif mutasi tidak dapat diproses diakhir bulan, karena akan sama dengan tanggal efektif terminasi, silakan pilih tanggal lainnya"
        );
        this.effectiveDate = firstDateOfNextMonthUnformatted;
        this.modal = false;
      }
    },
    checkIfCurrentEmployeeHasWorkedFor6Months() {
      let joinDateUnformatted = moment(
        this.employeeDetail.TANGGAL_BERGABUNG
      ).format("YYYY-MM-DD");
      let joinDate = moment(joinDateUnformatted, "YYYY-MM-DD");

      let effectiveDate = moment(this.effectiveDate, "YYYY-MM-DD");
      let comparison = moment.duration(effectiveDate.diff(joinDate)).asDays();
      if (
        this.employeeDetail.STATUS_KERJA == "Kontrak 1" &&
        this.nextStoreStatus === "Special Store"
      ) {
        if (comparison < 180) {
          this.$store.dispatch("setIs6Months", false);
        } else {
          this.$store.dispatch("setIs6Months", true);
        }
      } else {
        this.$store.dispatch("setIs6Months", true);
      }
    },
    saveDepartment(storeCode) {
      this.isDefault = false;
      this.disableDate = false;

      this.storeCodeFinal = storeCode.KODETOKO + "-" + storeCode.NAMATOKO;
      if (
        storeCode.Tipe == "spec" &&
        (this.employeeDetail.JABATAN == "Store Crew Boy" ||
          this.employeeDetail.JABATAN == "Store Crew Girl")
      ) {
        this.isSpec = true;
        this.nextStoreStatus = "Special Store";
        this.position = "Store Jr. Leader (Ss)";
        this.codeTitle = "N7K";
        this.isSpecial = 1;
        this.isComboBox = false;
      } else if (
        storeCode.Tipe !== "spec" &&
        (this.employeeDetail.JABATAN == "Store Crew Boy (Ss)" ||
          this.employeeDetail.JABATAN == "Store Crew Girl (Ss)")
      ) {
        this.isSpec = false;
        if (storeCode.Tipe === "reg") {
          this.nextStoreStatus = "Non Special Store";
        } else {
          this.nextStoreStatus = "-";
        }

        this.position = "Store Jr. Leader (Ss)";
        this.codeTitle = "N7K";
        this.isSpecial = 0;
      } else if (
        storeCode.Tipe == "spec" &&
        (this.employeeDetail.JABATAN == "Store Crew Boy (Ss)" ||
          this.employeeDetail.JABATAN == "Store Crew Girl (Ss)")
      ) {
        this.isSpec = false;
        this.nextStoreStatus = "Special Store";
        this.position = "Store Jr. Leader (Ss)";
        this.codeTitle = "N7K";
        this.isSpecial = 0;
        this.isComboBox = false;
      } else {
        this.position = "Store Jr. Leader";

        if (storeCode.Tipe === "reg") {
          this.nextStoreStatus = "Non Special Store";
          this.isComboBox = true;
        } else {
          this.nextStoreStatus = "-";
        }
        this.codeTitle = "N7";
        this.isSpecial = 0;
        this.isSpec = false;
      }
      if (storeCode.KODETOKO === this.employeeDetail.BAGIAN) {
        let firstDateOfCurrentMonth = moment()
          .clone()
          .startOf("month")
          .format("YYYY-MM-DD");
        let firstDateOfNextMonthUnformatted = moment(firstDateOfCurrentMonth)
          .add(1, "M")
          .format("YYYY-MM-DD");
        this.effectiveDate = firstDateOfNextMonthUnformatted;
        this.disableDate = true;
      }
      this.selectedStoreCode = storeCode.NAMATOKO;
      this.selectedStoreCodeID = storeCode.KODETOKO;
      this.dialog = false;
      this.checkIfCurrentEmployeeHasWorkedFor6Months();
      this.checkIsTrained();
    },

    getAllCabang() {
      this.$store
        .dispatch("callApiPost", {
          link: "Bagian",
          raw: {
            code: "2",
            action: "not in",
            keyword: "R",
          },
        })
        .then((response) => {
          let branches = response.Message;

          for (let i = 0; i < branches.length; i++) {
            this.branches.push({
              name: branches[i].AreaName,
              id: branches[i].id,
            });
          }
        });
    },
    async updateDHR() {
      let tokid = this.employeeDetail.TOK_ID;
      if (this.position == "Store Jr. Leader (Ss)") {
        this.codeTitle = "N7K";
      } else if (this.position === "Store Jr. Leader") {
        this.codeTitle = "N7"
      }
      await this.$store
        .dispatch("callApiPost", {
          link: "Transaksi",
          raw: {
            code: "10",
            id_dhr: this.$store.getters.id_dhr,
            nik: this.employeeDetail.NIK,
            bagian_id: this.selectedStoreCodeID,
            kode_jabatan: this.codeTitle,
            jabatan: this.position,
            isSpecial: this.isSpecial,
            cabang: this.branchIndex,
            bagian: this.selectedStoreCode,
            tokid: tokid,
            effective_date: this.effectiveDate,
            hari_kerja: this.shift,
            nikPejabat: this.$store.getters.nik,
          },
        })
        .then((response) => {
          console.log(response);
        });
    },

    async submitDraftStepOne() {
      if ((this.position === "Store Jr. Leader (Ss)")) {
        this.codeTitle = "N7K";
      } else if (this.position === "Store Jr. Leader") {
        this.codeTitle = "N7"
      }

      let tokid = this.employeeDetail.TOK_ID;
      let nik = this.employeeDetail.NIK;
      if (this.isDefault) {
        this.bagian = this.employeeDetail.NAMA_TOKO;
        this.bagian_id = this.employeeDetail.BAGIAN;
      } else {
        this.bagian = this.selectedStoreCode;
        this.bagian_id = this.selectedStoreCodeID;
      }

      await this.$store
        .dispatch("callApiPost", {
          link: "Transaksi",
          raw: {
            tranParm: {
              code: "1",
              typereq: "SJL",
              nikAS: this.$store.getters.nik,
              tokid: tokid,
            },
            req: {
              nik: nik,
              jabatan: this.position,
              unit: this.unit.id,
              bagian: this.bagian,
              bagian_id: this.bagian_id,
              kode_jabatan: this.codeTitle,
              isSpecial: this.isSpecial,
              hari_kerja: this.shift,
              jobclass: this.jobClass,
              effDate: this.effectiveDate,
              alasanresign: "Promosi SJL",
              deskripsi: "Promosi SJL menggunakan Request SCGB Ke SJL",
              gaji: 0,
              tunjangan: 0,
              cabang: this.branchIndex,
              status_karyawan: this.contractType.name,
              jenis_kontrak: "Tetap",
            },
          },
        })
        .then((response) => {
          this.$store.dispatch("setIdDhr", response.Message);
          this.$store.dispatch("returnDraft", response.Message);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeShiftValue(shift) {
      if (shift === "6S") {
        return "6 Hari-Shift";
      } else if (shift === "5S") {
        return "5 Hari-Shift";
      } else if (shift === "6NS") {
        return "6 Hari-Non Shift";
      } else if (shift === "5NS") {
        return "5 Hari-Non Shift";
      }
    },
    minDate() {
      let currentDate = new Date().toISOString().substr(0, 10);
      return moment(currentDate).format("YYYY-MM-DD");
    },
    maxDate() {
      let currentDate = new Date().toISOString().substr(0, 10);

      let nextMonth = moment(currentDate).add(30, "days").calendar();
      return moment(nextMonth).format("YYYY-MM-DD");
    },
    searchDepartment() {
      this.storeCodeBranches = [];
      this.isBranchLoading = true;
      this.items = [];
      this.$store
        .dispatch("callApiPost", {
          link: "Bagian",
          raw: {
            code: "1",
            keyword: this.storeCode,
            isi: "'R'",
            action: "not in",
            cabang: this.branchIndex,
          },
        })
        .then((response) => {
          this.isBranchLoading = false;
          let kodeToko = response.Message;
          this.storeCodeBranches = kodeToko;
        });
    },
    checkIfEmployeeFromSpecialStore() {
      if (
        this.employeeDetail.JABATAN == "Store Crew Boy" ||
        this.employeeDetail.JABATAN == "Store Crew Girl"
      ) {
        this.position = "Store Jr. Leader";
      } else {
        this.position = "Store Jr. Leader (Ss)";
      }
    },
    nextStoreStatusMethod() {
      if (this.employeeDetail.SpecialStore === "Y") {
        this.nextStoreStatus = "Special Store";
        this.firstStoreStatus = "Special Store";
      } else if (this.employeeDetail.SpecialStore === "N") {
        this.nextStoreStatus = "Non Special Store";
        this.firstStoreStatus = "Non Special Store";
      } else {
        this.nextStoreStatus = "-";
        this.firstStoreStatus = "-";
      }
    },
    codeTitlePosition() {
      if (
        this.employeeDetail.JABATAN == "Store Crew Boy (Ss)" ||
        this.employeeDetail.JABATAN == "Store Crew Girl (Ss)"
      ) {
        this.codeTitle = "N7K";
      } else {
        this.codeTitle = "N7";
      }
    },
  },
  data() {
    return {
      isMobile: false,
      isSpec: "",
      isTrained: "",
      contractType: { id: "permanentEmployee", name: "Karyawan Tetap" },
      unit: { id: "IDM", name: "PT INDOMARCO PRISMATAMA" },
      shift: this.employeeDetail.ID_GROUP_SHIFT,
      jobClass: "",
      positionType: [
        { value: "Store Jr. Leader", name: "Store Jr. Leader" },
        { value: "Store Jr. Leader (Ss)", name: "Store Jr. Leader (Ss)" },
      ],
      disableDate: false,
      dialogm1: "",
      statusWorker: [{ name: "" }],
      dialog: false,
      items: [],
      values: ["foo", "bar"],
      value: null,
      modal: false,
      isBranchLoading: false,
      showNewBranch: false,
      date: "",
      branches: [],
      branchIndex: "",
      selectedStoreCode: "",
      storeCode: "",
      codeTitle: "",
      position: "",
      nextStoreStatus: "",
      isBranchEmpty: true,
      positionCode: [],
      storeCodeBranches: [],
      firstStoreStatus: "",
      storeCodeFinal: "",
      showBranch: false,
      storeCodeIndex: "",
      codePosition: [],
      isDefault: true,
      search: "",
      menu: false,
      isComboBox: false,
      e1: 1,
      effectiveDate: new Date().toISOString().substr(0, 10),
    };
  },
};
</script>


<style lang="scss">
.hover-class {
  position: absolute;
  z-index: 99;
  display: flex;
  font-size: 10px;
  width: 50%;
  text-align: right;
  justify-content: center;
  background: white;
}

.combo-box.v-text-field.v-text-field--enclosed {
  width: 32% !important;
  margin-right: 10px !important;
}

.bagian-box.v-text-field--outlined>.v-input__control>.v-input__slot {
  width: 100%;
}

.choose-branch label {
  font-size: 10px !important;
}

.btn-search-dpt {
  height: 25px !important;
  border-radius: 9px;
  /* padding-bottom: 10px; */
  margin-bottom: 6p;
}

.border-table {
  background: #6b666f !important;
  height: 30px;
  display: flex;
  align-content: center;
  font-size: 12px;
  margin-top: 20px;
  border-radius: 4px;
  color: white !important;
  padding: 2px;
}

.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: black !important;
}

.w-20 {
  width: 20% !important;
}

.w-34 {
  width: 34% !important;
}

.overflow-hidden {
  overflow: hidden !important;
  width: 100% !important;
}

.swal-wide {
  width: 30rem !important;
  height: 25vh !important;
}
</style>

