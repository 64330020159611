<template>
  <div>
    <div class="pa-10 pt-0" v-if="!isMobile">
      <div v-if="$store.getters.requestType === 'DHT'" class="ml-4 mt-4">
        <b>Buat Permohonan Karyawan Berhenti</b>
      </div>
      <div v-if="$store.getters.requestType === 'PRO'" class="ml-4 mt-4">
        <b>Buat Permohonan Promosi Karyawan</b>
      </div>
      <div
        v-if="
          $store.getters.requestType === 'PRM' ||
          $store.getters.requestType === 'MUT'
        "
        class="ml-4 mt-4"
      >
        <b>Buat Permohonan Mutasi Karyawan</b>
      </div>
      <v-row>
        <v-col>
          <div class="mt-4 ml-4"><b>DATA KARYAWAN</b></div>
        </v-col>
        <v-col>
          <div v-if="$store.getters.requestType === 'PRO'" class="mt-4 ml-4">
            <b>DATA USULAN PROMOSI KARYAWAN</b>
          </div>
          <div v-if="$store.getters.requestType === 'DHT'" class="mt-4 ml-4">
            <b>USULAN BERHENTI</b>
          </div>
          <div
            v-if="
              $store.getters.requestType === 'PRM' ||
              $store.getters.requestType === 'MUT'
            "
            class="mt-4 ml-4"
          >
            <b>DATA USULAN MUTASI KARYAWAN</b>
          </div>
        </v-col>
      </v-row>
      <v-row class="pa-5">
        <v-col class="to-be-center">
          <v-row v-for="(variable, index) in desktopVariables" :key="index">
            <v-col> {{ variable.type }} </v-col>
            <v-col>
              <v-text-field
                class="b-disabled"
                :value="variable.value"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="to-be-center">
          <v-row>
            <v-col> TANGGAL EFEKTIF </v-col>
            <v-col>
              <v-text-field dense outlined> </v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="
              $store.getters.requestType === 'MUT' ||
              $store.getters.requestType === 'PRM'
            "
          >
            <v-col> UNIT </v-col>
            <v-col>
              <v-text-field dense outlined> </v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="
              $store.getters.requestType === 'MUT' ||
              $store.getters.requestType === 'PRM'
            "
          >
            <v-col> CABANG </v-col>
            <v-col>
              <v-text-field dense outlined> </v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="
              $store.getters.requestType === 'MUT' ||
              $store.getters.requestType === 'PRM'
            "
          >
            <v-col> LEVEL BAGIAN </v-col>
            <v-col>
              <v-text-field dense outlined> </v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="$store.getters.requestType === 'PRO'">
            <v-col> JABATAN </v-col>
            <v-col>
              <v-text-field dense outlined> </v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="
              $store.getters.requestType === 'PRO' ||
              $store.getters.requestType === 'PRM'
            "
          >
            <v-col> JOB CLASS </v-col>
            <v-col>
              <v-autocomplete dense outlined></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col> HARI KERJA </v-col>
            <v-col>
              <v-autocomplete dense outlined></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col> KETERANGAN </v-col>
            <v-col>
              <v-textarea dense outlined> </v-textarea>
            </v-col>
          </v-row>
        </v-col>
        <!-- <v-col class="to-be-center">
            <v-row>
              <v-col> TANGGAL EFEKTIF </v-col>
              <v-col>
                <v-text-field dense outlined> </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col> JENIS BERHENTI </v-col>
              <v-col>
                <v-text-field dense outlined> </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col> KETERANGAN </v-col>
              <v-col>
                <v-textarea dense outlined> </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col> JENIS PAKLARING </v-col>
              <v-col>
                <v-autocomplete dense outlined></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col> CATATAN </v-col>
              <v-col>
                <v-textarea dense outlined> </v-textarea>
              </v-col>
            </v-row>
          </v-col> -->
      </v-row>
    </div>
    <div class="mt-10 pa-10 mobile-flex" v-else>
      <div v-if="$store.getters.requestType === 'DHT'" class="ml-4 mt-4">
        <b>Buat Permohonan Karyawan Berhenti</b>
      </div>
      <div v-if="$store.getters.requestType === 'PRO'" class="ml-4 mt-4">
        <b>Buat Permohonan Promosi Karyawan</b>
      </div>
      <div
        v-if="
          $store.getters.requestType === 'PRM' ||
          $store.getters.requestType === 'MUT'
        "
        class="ml-4 mt-4"
      >
        <b>Buat Permohonan Mutasi Karyawan</b>
      </div>
      <div class="mobile-form">
        <div v-for="(variable, index) in desktopVariables" :key="index">
          <v-row class="mt-6"> {{ variable.type }} </v-row>
          <v-row>
            <v-text-field class="b-disabled" dense outlined> </v-text-field>
          </v-row>
        </div>
        <div v-if="$store.getters.requestType === 'PRO'" class="mt-4 ml-4">
          <b>DATA USULAN PROMOSI KARYAWAN</b>
        </div>
        <div v-if="$store.getters.requestType === 'DHT'" class="mt-4 ml-4">
          <b>USULAN BERHENTI</b>
        </div>
        <div
          v-if="
            $store.getters.requestType === 'PRM' ||
            $store.getters.requestType === 'MUT'
          "
          class="mt-4 ml-4"
        >
          <b>DATA USULAN MUTASI KARYAWAN</b>
        </div>

        <v-row>
          <v-col> TANGGAL EFEKTIF </v-col>
          <v-col> </v-col>
        </v-row>
        <v-row>
          <v-text-field dense outlined> </v-text-field>
        </v-row>
        <v-row
          v-if="
            $store.getters.requestType === 'MUT' ||
            $store.getters.requestType === 'PRM'
          "
        >
          <v-col> UNIT </v-col>
        </v-row>
        <v-row
          v-if="
            $store.getters.requestType === 'MUT' ||
            $store.getters.requestType === 'PRM'
          "
        >
        </v-row>
        <v-row
          v-if="
            $store.getters.requestType === 'MUT' ||
            $store.getters.requestType === 'PRM'
          "
        >
          <v-text-field dense outlined> </v-text-field>
        </v-row>

        <v-row
          v-if="
            $store.getters.requestType === 'MUT' ||
            $store.getters.requestType === 'PRM'
          "
        >
          <v-col> CABANG </v-col>
        </v-row>
        <v-row
          v-if="
            $store.getters.requestType === 'MUT' ||
            $store.getters.requestType === 'PRM'
          "
        >
          <v-col>
            <v-text-field dense outlined> </v-text-field>
          </v-col>
        </v-row>
        <v-row
          v-if="
            $store.getters.requestType === 'MUT' ||
            $store.getters.requestType === 'PRM'
          "
        >
          <v-col> LEVEL BAGIAN </v-col>
        </v-row>

        <v-row
          v-if="
            $store.getters.requestType === 'MUT' ||
            $store.getters.requestType === 'PRM'
          "
        >
          <v-col>
            <v-text-field dense outlined> </v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="$store.getters.requestType === 'PRO'">
          <v-col> JABATAN </v-col>
        </v-row>
        <v-row v-if="$store.getters.requestType === 'PRO'">
          <v-col>
            <v-text-field dense outlined> </v-text-field>
          </v-col>
        </v-row>

        <v-row
          v-if="
            $store.getters.requestType === 'PRO' ||
            $store.getters.requestType === 'PRM'
          "
        >
          <v-col> JOB CLASS </v-col>
        </v-row>
        <v-row
          v-if="
            $store.getters.requestType === 'PRO' ||
            $store.getters.requestType === 'PRM'
          "
        >
          <v-col>
            <v-autocomplete dense outlined></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col> HARI KERJA </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete dense outlined></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col> KETERANGAN </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea dense outlined> </v-textarea>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>    


<script>
export default {
  data() {
    return {
      isMobile: false,
      menu: false,
      desktopVariables: [
        { type: "NIK", value: "20162121" },
        { type: "NAMA", value: "ANDRES" },
        { type: "UNIT", value: "Indomaret" },
        { type: "CABANG", value: "Indomaret" },
        { type: "BAGIAN", value: "Indomaret" },
        { type: "STATUS", value: "Indomaret" },
        { type: "TGL PENGANGKATAN TETAP", value: "Indomaret" },
        { type: "MASA KERJA", value: "Indomaret" },
        { type: "JABATAN", value: "Indomaret" },
        { type: "TGL AWAL MENJABAT", value: "Indomaret" },
        { type: "LAMA MENJABAT", value: "Indomaret" },
        { type: "HARI KERJA", value: "Indomaret" },
        { type: "JOB CLASS", value: "Indomaret" },
      ],
    };
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    menuItems() {
      return this.menu;
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
  },
};
</script>
