import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import store from "./store"
import router from './router';
import vuetify from './plugins/vuetify';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "./plugins/vuetify-money.js";
import VueMobileDetection from "vue-mobile-detection"
import VueExcelXlsx from "vue-excel-xlsx";



const components = {
  //...
}

Vue.use(VueRouter, components, VueSweetalert2, VueMobileDetection,VueExcelXlsx)

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app');
