<template>
  <v-app>
    <v-app id="inspire">
      <v-navigation-drawer
        v-if="showNav"
        class="pt-2"
        :value="showDrawer"
        app
        clipped
      >
        <template v-slot:prepend>
          <v-list-item two-line>
            <v-list-item-content>
              <div class="d-flex justify-center">
                <v-img
                  max-height="85"
                  max-width="100"
                  src="../src/assets/images/Indomaret-logo.svg"
                ></v-img>
              </div>
              <div class="d-flex justify-center mt-4">
              <span class="mr-2 f-14">
                <b
                  >Hi!,
                  <a href="#">{{
                    showName
                  }}</a></b
                ></span
              >
              </div>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item-group
            v-model="group"
            active-class="white--text blue darken-1 border-style"
          >
            <v-list-item
              :class="
                $route.name === 'Update'
                  ? 'white--text blue darken-1 border-style v-list-item--active v-list-item v-list-item--link theme--light'
                  : ''
              "
              to="/Inbox"
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-email-open</v-icon>
              </v-list-item-icon>

              <v-list-item-title>
                <div class="d-flex justify-space-between">
                  <div>Kotak Masuk</div>
                  <div class="total-inbox">{{ getTotalInbox }}</div>
                </div>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              to="/Request/Create"
              active-class="white--text blue darken-1 border-style v-list-item--active v-list-item v-list-item--link theme--light"
            >
              <v-list-item-icon>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Buat Permohonan</v-list-item-title>
            </v-list-item>

            <v-list-item
              :class="
                $route.name === 'ViewDetail'
                  ? 'white--text blue darken-1 border-style v-list-item--active v-list-item v-list-item--link theme--light'
                  : ''
              "
              to="/Request/View"
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-view-list</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Lihat Permohonan</v-list-item-title>
            </v-list-item>
            <v-list-item to="/Report" link>
              <v-list-item-icon>
                <v-icon>mdi-text-box-multiple-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Laporan</v-list-item-title>
            </v-list-item>
                <v-list-item to="/Setting" link>
              <v-list-item-icon>
                <v-icon>mdi-cog-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Pengaturan</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-list-item-icon>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar height="55px" class="tool-bar" v-if="showNav" app clipped-left>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="w-100">
          <div>DHR Online</div>
        </v-toolbar-title>
      </v-app-bar>

      <v-main>
        <router-view @change="checkRoutes()" />
      </v-main>

      <v-footer app>
        <span
          >Copyright &copy; {{ new Date().getFullYear() }} - Software
          Development II Indomaret Group v1.0</span
        >
      </v-footer>
    </v-app>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",

  methods: {
    ...mapActions({
      setTotalInbox: "setTotalInbox",
    }),
    logout() {
      this.$store.commit("REMOVE_COOKIE");
      this.$router.go({ name: "Login" });
    },
    checkAuth() {
      if (this.$store.getters.isAuthenticated) {
        return true;
      }
      return false;
    },
    getInbox() {
      this.$store
        .dispatch("callApiPost", {
          link: "Inbox",
          raw: {
            code: "1",
            dhrType: "SJL",
            nik: this.$store.getters.nik,
            role: this.$store.getters.role,
          },
        })
        .then((response) => {
          let inbox = response.Message;
          this.setTotalInbox(inbox.length);
        });
    },
  },

  // mounted() {
  //   this.showName()
  // },
  data() {
    return {
      drawer: true,
      group: null,
      isAuthenticated: this.$store.getters.isAuthenticated,
    };
  },
  computed: {
    ...mapGetters({
      totalInbox: "totalInbox",
    }),

    getTotalInbox: {
      get() {
        return this.totalInbox;
      },

      set(val) {
        this.setTotalInbox(val);
      },
    },
    showNav() {
      return this.$store.getters.isAuthenticated;
    },
    showName() {
      return this.$store.getters.name;
    },
    showDrawer() {
      return this.drawer;
    },
    // this.isAuthenticated= this.$store.getters.isAuthenticated,
    // this.name= this.$store.getters.name
  },
};
</script>

<style lang="scss">
.position-fixed button {
  position: fixed;
}
.border-style.v-list-item {
  border-radius: 0 10px 10px 0px;
  margin-right: 20px;
}
.v-toolbar__content,
.tool-bar,
.v-toolbar__content {
  height: 55px !important;
  box-sizing: unset !important;
  background: white !important;
  border-bottom: 1px solid silver;
}

.theme--light.v-icon:focus::after {
  opacity: 0 !important;
}

.sub-header.v-subheader {
  height: 53px;
}
</style>
