import Vue from 'vue'
import Vuex from 'vuex'
import cookie from 'js-cookie';
import axios from 'axios';  
import router from '../router.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    is6Months: true,
    shift: '',
    requestType: '',
    token: cookie.get('token') || '',
    name: cookie.get('name'),
    totalInbox: 0,
    store_code: cookie.get('code'),
    nik: cookie.get('nik'),
    DHRResult: [],
    isEffDateValid: true,
    id_dhr: '',
    salary : null,
    salaryInvalid: 'invalid',
    role: cookie.get('role'),
    DHTEffectiveDate: '',
    formType: 'dht',
    newSalary: 0,
    allowance: '',
    jobClassSelect :  [
      { value: "A2", name: "A2" },
      { value: "A3", name: "A3" },
    ],
    unitSelect: [{ value: "IDM", name: "PT INDOMARCO PRISMATAMA" }],
    contractTypeSelect: [
      { value: "permanentEmployee", name: "Karyawan Tetap" },
      { value: "contractEmployee", name: "Karyawan Kontrak" },
    ],
    shiftSelect: [
      { value: "5S", name: "5 Hari-Shift" },
      { value: "6S", name: "6 Hari-Shift" }],
    draft: '',
    cabang: cookie.get('cabang'),
    id_cabang:  cookie.get('id_cabang'),
    bagian:  cookie.get('bagian'),
    isTrained: true,
    isNextStoreSpecial: 0,
  },
  mutations: {
    SET_SHIFT_VALUE(state, shift) {

      if (shift === "6S") {
        state.shift =   "6 Hari-Shift";
      } else if (shift === "5S") {
        state.shift = "5 Hari-Shift";
      } else if (shift === '6NS') {
        state.shift = "6 Hari-Non Shift";
      } else if (shift === '5NS') {
        state.shift=  "5 Hari-Non Shift";
      }
    },
    SET_TOKEN(state, token) {
      var oneHour = 0.04167;
      cookie.set('token', token, {
        expires: oneHour
      });
      state.token = token
    },
    SET_NAME(state, name) {
      cookie.set('name', name)
      state.name = name
    },
    SET_STORE_CODE(state, store_code) {
      cookie.set('code', store_code)
      state.store_code = store_code
    },
   RETURN_DRAFT(state, draft) {
      state.draft = draft
    },
    SET_EMPLOYEE_DATA(state, employee_data) {
      state.DHRResult = employee_data
    },
    SET_EFECTIVE_DATE(state, efective_date) {
      state.DHRResult[0].tanggal_efektif = efective_date
    },
    SET_STORECODE_FINAL(state, store_code) {
      state.DHRResult[0].bagian_id = store_code
    },
    SET_ID_DHR(state, id_dhr) {
      state.id_dhr= id_dhr
    },

    SET_TOTAL_INBOX(state, totalInbox) {
      state.totalInbox = totalInbox
    },
    SET_REQUEST_TYPE(state,requestType) {
      state.requestType = requestType
    },
  
    SET_NECESSARY_DATA(state, necessary_data) {
      let oneHour = 0.04167;
      cookie.set('code', necessary_data.kodeToko,{
        expires: oneHour
      } )
   
      cookie.set('token', necessary_data.token, {
        expires: oneHour
      });
      cookie.set('name', necessary_data.nama, {
        expires: oneHour
      });
      cookie.set('nik', necessary_data.nik , {
        expires: oneHour
      })
      cookie.set('role', necessary_data.kodeRole , {
        expires: oneHour
      })
      cookie.set('cabang', necessary_data.cabang , {
        expires: oneHour
      }),
      cookie.set('id_cabang', necessary_data.idCabang , {
        expires: oneHour
      })
      cookie.set('bagian', necessary_data.bagian , {
        expires: oneHour
      })

      state.nik = necessary_data.nik
      state.store_code = necessary_data.kodeToko,
      state.name = necessary_data.nama
      state.token = necessary_data.token
      state.role = necessary_data.kodeRole
      state.cabang = necessary_data.cabang
      state.id_cabang = necessary_data.idCabang
      state.bagian = necessary_data.bagian
    },
    SET_FORM_TYPE(state, formType) {
      state.formType = formType
    },
    SET_SALARY(state, salary ) {
      state.salary = salary
    },
    SET_ALLOWANCE(state, allowance ) {
      state.allowance = allowance
    },
    REMOVE_COOKIE() {
      cookie.remove('code')
      cookie.remove('name')
      cookie.remove('token')
      cookie.remove('nik')
      cookie.remove('role')
      cookie.remove('bagian')
      cookie.remove('id_cabang')
      cookie.remove('cabang')
    },
    SET_NEW_SALARY(state, newSalary){
      state.newSalary = newSalary
    },
    SET_DHT_DATE(state, date) {
      state.DHTEffectiveDate =date
    },
    SET_SALARY_INVALID(state,salaryInvalid) {
      state.salaryInvalid = salaryInvalid
    },
    SET_EFF_DATE_VALID(state,EffDateValid ){
      state.isEffDateValid = EffDateValid
    },
    SET_IS_TRAINED(state, isTrained) {
      state.isTrained = isTrained
    },
    SET_IS_NEXT_STORE_SPECIAL(state, isNextStoreSpecial){
      state.isNextStoreSpecial = isNextStoreSpecial
    },
    SET_IS_6_MONTHS(state, is6Months ) {
      state.is6Months = is6Months
    }
  },
  actions: {
    setIsTrained(context, payload) {
      context.commit('SET_IS_TRAINED', payload)
    },
    setEffDateValid(context, payload){
      context.commit('SET_EFF_DATE_VALID', payload);
    },
    setToken(context, payload) {
      context.commit('SET_TOKEN', payload)
    },
    setName(context, payload) {
      context.commit('SET_NAME', payload)
    },
    setStoreCode(context, payload) {
      context.commit('SET_STORE_CODE', payload)
    },
    returnDraft(context,payload) {
      context.commit('RETURN_DRAFT', payload)
    },
    setNecessaryData(context,payload) {
      context.commit('SET_NECESSARY_DATA', payload);
    },
    async setEmployeeData(context,payload) {
      await context.commit('SET_EMPLOYEE_DATA', payload)
    },
    setEfectiveDate(context,payload) {
      context.commit('SET_EFECTIVE_DATE', payload)
    },
    setStoreCodeFinal(context,payload) {
      context.commit('SET_STORECODE_FINAL', payload)
    },
    setIdDhr(context, payload) {
      context.commit('SET_ID_DHR', payload)
    },
    setFormType(context, payload) {
      context.commit('SET_FORM_TYPE', payload);
    },
    setSalary(context, payload) {
      context.commit('SET_SALARY', payload);
    },
    setAllowance(context, payload) {
      context.commit('SET_ALLOWANCE', payload);
    },
    setNewSalary(context, payload) {
      context.commit('SET_NEW_SALARY', payload);
    },
    setDHTEffectiveDate(context, payload) {
      context.commit('SET_DHT_DATE', payload);
    },
    setSalaryInvalid(context, payload) {
      context.commit('SET_SALARY_INVALID', payload)
    },

    setTotalInbox(context, payload) {
      context.commit('SET_TOTAL_INBOX', payload)
    },
    setShiftValue(context,payload) {
      context.commit('SET_SHIFT_VALUE', payload)
    },
    setRequestType(context,payload) {
      context.commit('SET_REQUEST_TYPE', payload)
    },
    setIsNextStoreSpecial(context,payload) {
      context.commit('SET_IS_NEXT_STORE_SPECIAL', payload)
    },
    setIs6Months(context, payload) {
      context.commit('SET_IS_6_MONTHS', payload)
    },

    callApiPost(context, payload) {
      return new Promise((resolve, reject) => {
        let obj = {"Data": {}, "nik": cookie.get("nik")}
        obj.Data = payload.raw
        axios.post(process.env.VUE_APP_URL_API + payload.link, obj, {
          // headers: {
          //   token: context.state.token
          // }
          withCredentials:true

        }).then(response => {
          if(response.data.Code === 400 && payload.link != "Login" ) {
            cookie.remove('code')
            cookie.remove('name')
            cookie.remove('token')
            cookie.remove('nik')
            cookie.remove('role')
            cookie.remove('bagian')
            cookie.remove('id_cabang')
            cookie.remove('cabang')
            router.go({ name: "Login" });

          }
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  getters: {
    isAuthenticated: state => !!state.token,
    name: state => state.name,
    storeCode: state => state.store_code,
    draft: state => state.draft,
    nik: state => state.nik,
    contractTypeSelect: state => state.contractTypeSelect,
    shiftSelect: state => state.shiftSelect,
    unitSelect:state => state.unitSelect,
    jobClassSelect: state=> state.jobClassSelect,
    employeeData: state=> state.DHRResult[0],
    id_dhr: state=> state.id_dhr,
    role: state => state.role,
    formType: state => state.formType,
    salary: state => state.salary,
    allowance: state => state.allowance,
    newSalary: state=> state.newSalary,
    effectiveDate: state=> state.DHTEffectiveDate,
    totalInbox: state => state.totalInbox,
    salaryInvalid: state => state.salaryInvalid,
    shift: state=> state.shift,
    requestType: state=> state.requestType,
    cabang: state => state.cabang,
    id_cabang: state => state.id_cabang,
    bagian: state=>state.bagian,
    isEffDateValid: state=> state.isEffDateValid,
    isTrained: state=> state.isTrained,
    isNextStoreSpecial: state=> state.isNextStoreSpecial,
    is6Months: state=> state.is6Months
  }
})
