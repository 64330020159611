<template>
  <div>
    <div v-if="!isMobile">
      <EmployeeDetailForm
        :employeeDetail="employeeDetail"
        :DHTResult="DHTResult"
      >
        ></EmployeeDetailForm
      >
      <div class="silver pa-1 pl-4 mb-4 mt-4">
        <span class="white--text font-weight-bold">Data DHT</span>
      </div>
      <v-row class="row-padding-2">
        <v-col
          class="align-center d-flex justify-end   "
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold">Tanggal Berakhir Bekerja</span>
          </div>
        </v-col>

        <v-col
          class="align-center d-flex  "
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            :value="DHTResult.effective_date"
            class="b-disabled"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="row-padding-2">
        <v-col
          class="align-center d-flex justify-end  "
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold">Unit</span>
          </div>
        </v-col>

        <v-col
          class="align-center d-flex "
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            :value="employeeDetail.Unit"
            class="b-disabled"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="row-padding-2">
        <v-col
          class="align-center d-flex justify-end  "
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold">Job</span>
          </div>
        </v-col>

        <v-col
          class="align-center d-flex "
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            :value="employeeDetail.JOB_CLASS"
            class="b-disabled"
            dense
            disabled
            outlined
            >Job</v-text-field
          >
        </v-col>
      </v-row>
      <v-row class="row-padding-2">
        <v-col
          class="align-center d-flex justify-end  "
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold">Department</span>
          </div>
        </v-col>

        <v-col
          class="align-center d-flex "
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            :value="DHTResult.bagian"
            class="b-disabled"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="row-padding-2">
        <v-col
          class="align-center d-flex justify-end "
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold">Alasan Berhenti</span>
          </div>
        </v-col>

        <v-col
          class="align-center d-flex "
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            value="Promosi SJL"
            class="b-disabled"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="height-160">
        <v-col
          class="align-center d-flex justify-end "
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold">Deskripsi</span>
          </div>
        </v-col>

        <v-col
          class="align-center d-flex "
          cols="12"
          sm="3"
          md="3"
        >
          <v-textarea
            value="Promosi SJL menggunakan Request SCGB Ke SJL"
            class="b-disabled"
            dense
            disabled
            outlined
          ></v-textarea>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <EmployeeDetailFormMobile :employeeDetail="employeeDetail" />
      <div class="silver pa-1 pl-4 mb-4 w-100-idx mt-8">
        <span class="white--text font-weight-bold">Data DHT</span>
      </div>
      <v-row>
        <v-col
          class="align-center d-flex "
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold"> Tanggal Berakhir Bekerja </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class=" align-center d-flex">
          <v-text-field
            :value="DHTResult.effective_date"
            class="b-disabled text-right-form"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="align-center d-flex "
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold"> Unit </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class=" align-center d-flex">
          <v-text-field
            :value="employeeDetail.Unit"
            class="b-disabled text-right-form"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="align-center d-flex "
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold"> Job </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class=" align-center d-flex">
          <v-text-field
            :value="employeeDetail.JOB_CLASS"
            class="b-disabled text-right-form"
            dense
            disabled
            outlined
            >Job</v-text-field
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="align-center d-flex "
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold"> Department </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class=" align-center d-flex">
          <v-text-field
            :value="DHTResult.bagian"
            class="b-disabled text-right-form"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="align-center d-flex "
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold"> Alasan Berhenti </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class=" align-center d-flex">
          <v-text-field
            value="Promosi SJL"
            class="b-disabled text-right-form"
            dense
            disabled
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="align-center d-flex "
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <span class="font-weight-bold"> Deskripsi </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class=" align-center d-flex">
          <v-textarea
            value="Promosi SJL menggunakan Request SCGB Ke SJL"
            class="b-disabled text-right-form"
            dense
            disabled
            outlined
          ></v-textarea>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import EmployeeDetailForm from "../employee/DetailForm.vue";
import EmployeeDetailFormMobile from "../employee/DetailFormMobile.vue";
export default {
  components: {
    EmployeeDetailForm,
    EmployeeDetailFormMobile,
  },
  props: ["employeeDetail"],
  data() {
    return {
      DHTResult: "",
      isMobile: false,
    };
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    async getDHT() {
      await this.$store
        .dispatch("callApiPost", {
          link: "Transaksi",
          raw: {
            code: "3",
            id_dhr: this.$store.getters.id_dhr,
          },
        })
        .then((response) => {
          let DHTResult = response.Message;
          this.DHTResult = DHTResult[0];

          this.$store.dispatch(
            "setDHTEffectiveDate",
            this.DHTResult.effective_date
          );
        });
    },
    async updateDHT() {
      await this.$store
        .dispatch("callApiPost", {
          link: "Transaksi",
          raw: {
            code: "7",
            id_dhr: this.$store.getters.id_dhr,
            alasan: "Promosi SJL",
            deskripsi: "Promosi SJL menggunakan Request SCGB Ke SJL",
          },
        })
        .then((response) => {
          console.log(response);
        });
    },
  },
};
</script>
<style lang="scss">
.row-padding-2 {
  height: 65px !important;
}
.height-160 {
  height: 160px !important;
  .v-text-field.v-text-field--enclosed {
    font-size: 12px !important;
  }
}
.row-padding-2.d-flex,
.row-padding-0.d-flex {
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}

</style>
<style scoped>
.text-right-form >>> input {
  text-align: right !important;
}
</style>