<template>
  <div>
    <div v-if="!isMobile">
      <v-row class="pl-6">
        <v-col class="d-flex align-center font-weight-bold" col="12" sm="4">
          <div class="mr-4 w-30">Gaji Pokok</div>
          <div class="d-flex">
            <vuetify-money
              dense
              :v-model="salary"
              :value="DPBData.gaji_pokok"
              v-bind:label="label"
              v-bind:placeholder="placeholder"
              v-bind:readonly="true"
              v-bind:outlined="outlined"
              v-bind:clearable="clearable"
              v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
              v-bind:options="options"
              v-bind:properties="properties"
            />
          </div>
        </v-col>
        <v-col class="d-flex align-center" col="12" sm="4">
          <vuetify-money
            dense
            class="recommend"
            @input="countPercent()"
            v-model="totalIncreasingSalary"
            v-bind:label="label"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
            v-bind:properties="properties"
          />

          <v-text-field
            dense
            type="number"
            @input="countSalaryByPercent()"
            v-model="percent"
            class="percentage"
            outlined
            :error="isMessageSalary"
          >
          </v-text-field>
          <div class="ml-2">%</div>
        </v-col>
        <v-col class="d-flex align-center" col="12" sm="4">
          <vuetify-money
            @input="countPercentAndSalaryPercent()"
            dense
            v-model="newSalary"
            v-bind:label="label"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
            v-bind:properties="properties"
          />
        </v-col>
      </v-row>
      <v-row class="pa-0 pl-12" v-if="isMessageSalary">
        <v-col class="pa-0" col="12" sm="4"> </v-col>
        <v-col class="pa-0 red--text" col="12" sm="4">
          {{ this.messageSalary }}
        </v-col>
      </v-row>
      <v-row class="pl-6">
        <v-col class="d-flex align-center font-weight-bold" col="12" sm="4">
          <div class="mr-4 w-30">Tunjangan 1</div>
          <vuetify-money
            dense
            :v-model="value"
            backgroundColor="#f4f4f4"
            :value="DPBData.tunjangan1"
            v-bind:label="label"
            v-bind:placeholder="placeholder"
            v-bind:readonly="true"
            v-bind:disabled="true"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
            v-bind:properties="properties"
          />
        </v-col>
        <v-col class="d-flex align-center" col="12" sm="4"> </v-col>
        <v-col class="d-flex align-center" col="12" sm="4">
          <vuetify-money
            dense
            backgroundColor="#f4f4f4"
            :value="DPBData.tunjangan1"
            v-bind:label="label"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="true"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
            v-bind:properties="properties"
          />
        </v-col>
      </v-row>
      <v-row class="pl-6">
        <v-col class="d-flex align-center font-weight-bold" col="12" sm="4">
          <div class="mr-4 w-30">Tunjangan 2</div>
          <vuetify-money
            :v-model="tunjangan2"
            backgroundColor="#f4f4f4"
            dense
            :value="DPBData.tunjangan2"
            v-bind:label="label"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="true"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
            v-bind:properties="properties"
          />
        </v-col>
        <v-col class="d-flex align-center" col="12" sm="4"> </v-col>
        <v-col class="d-flex align-center" col="12" sm="4">
          <vuetify-money
            dense
            class="b-disabled"
            v-bind:label="label"
            backgroundColor="#f4f4f4"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="true"
            v-bind:outlined="outlined"
            color="red"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
            v-bind:properties="properties"
          />
        </v-col>
      </v-row>
      <v-row v-if="this.$store.getters.isNextStoreSpecial == 1" class="pl-6">
        <v-col class="d-flex align-center font-weight-bold" col="12">
          <div class="">Tunjangan SS</div>
        </v-col>
        <v-col class="d-flex align-center font-weight-bold" col="12" md="11">
          <v-text-field
            class="ml-10"
            value="Mendapat Tunjangan Special Store, untuk besaran tunjangan sesuai dengan perhitungan dari Payroll"
            outlined
            dense
          />
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row class="silver mt-4 mb-4 pl-6 pb-0 pt-0">
        <v-col class="white--text font-weight-bold pb-2 pt-2" col="12" sm="4">
          Gaji
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <b> Gaji Pokok </b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <vuetify-money
            :v-model="salary"
            dense
            :value="DPBData.gaji_pokok"
            v-bind:label="label"
            v-bind:placeholder="placeholder"
            v-bind:readonly="true"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
            v-bind:properties="properties"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <b> Gaji Usulan Kenaikan Gaji </b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <vuetify-money
            dense
            class="recommend"
            @input="countPercent()"
            v-model="totalIncreasingSalary"
            v-bind:label="label"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
            v-bind:properties="properties"
          />

          <v-text-field
            type="number"
            dense
            @input="countSalaryByPercent()"
            v-model="percent"
            outlined
            :error="isMessageSalary"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="2"
        >
          <div>
            <b> Gaji Baru </b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <vuetify-money
            @input="countPercentAndSalaryPercent()"
            v-model="newSalary"
            v-bind:label="label"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
            v-bind:properties="properties"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          {{ this.messageSalary }}
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="2"
        >
          <b>Tunjangan 1 </b>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <vuetify-money
            :v-model="value"
            backgroundColor="#f4f4f4"
            :value="DPBData.tunjangan1"
            v-bind:label="label"
            v-bind:placeholder="placeholder"
            v-bind:readonly="true"
            v-bind:disabled="true"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
            v-bind:properties="properties"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="2"
        >
          <b>Tunjangan 2 </b>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <vuetify-money
            dense
            :v-model="tunjangan2"
            backgroundColor="#f4f4f4"
            :value="DPBData.tunjangan2"
            v-bind:label="label"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="true"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
            v-bind:properties="properties"
          />
        </v-col>
      </v-row>
      <v-row v-if="this.$store.getters.isNextStoreSpecial == 1">
        <v-col
          class="align-center d-flex justify-end pb-0 pt-0"
          cols="12"
          sm="3"
          md="2"
        >
          <b>Tunjangan SS </b>
        </v-col>
      </v-row>
      <v-row v-if="this.$store.getters.isNextStoreSpecial == 1">
        <v-col>
          <v-textarea
            disabled
            value="Mendapat Tunjangan Special Store, untuk besaran tunjangan sesuai dengan perhitungan dari Payroll"
            outlined
            dense
          ></v-textarea>
        </v-col>
      </v-row>
    </div>
  </div>
</template>



<style lang="scss" scoped>
.salary.v-input__slot,
.salary.v-text-field.v-text-field--enclosed {
  width: 70% !important;
  padding: 10px !important;
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused).v-input--is-disabled
  > .v-input__control
  > .v-input__slot
  fieldset {
  border-color: transparent !important;
}
.salary {
  margin-left: 20px;
  padding: 10px !important;
}
.salary.v-text-field__slot {
  padding-left: 5px !important;
}
// .recommend.percentage.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
//   width: 70% !important;
// }
.w-30 {
  width: 30% !important;
}

.recommend > .v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 34px !important;
}

.recommend > .v-input {
  padding-top: 4px !important;
}

.recommend.v-text-field__details,
.percentage.v-text-field__details {
  display: none !important;
  margin-right: 10px !important;
}
.recommend.v-text-field.v-text-field--enclosed {
  width: 70% !important;
  margin-right: 10px;
}
.percentage.v-text-field.v-text-field--enclosed {
  width: 10% !important;
}
</style>

<script>
export default {
  props: ["DPBData"],
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  created() {
    if (this.$store.getters.role === "AM") {
      this.percent = 0;
      this.totalIncreasingSalary = 0;
    } else {
      this.$store.dispatch("setSalaryInvalid", "valid");
      this.totalIncreasingSalary =
        this.DPBData.gaji_baru - this.DPBData.gaji_pokok;
      this.$store.dispatch("setSalary", this.DPBData.gaji_baru);
      this.percent =
        (this.totalIncreasingSalary / this.DPBData.gaji_pokok) * 100;
    }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    countSalaryByPercent() {
      if (this.percent != 0) {
        this.totalIncreasingSalary =
          (this.percent * this.DPBData.gaji_pokok) / 100;
        this.newSalary =
          parseInt(this.totalIncreasingSalary) +
          parseInt(this.DPBData.gaji_pokok);
        this.$store.dispatch("setSalary", this.newSalary);
        this.checkInputtedSalaryIsValid();
      }
    },
    countPercentAndSalaryPercent() {
      this.totalIncreasingSalary =
        parseInt(this.newSalary) - parseInt(this.DPBData.gaji_pokok);
      this.percent =
        (this.totalIncreasingSalary / this.DPBData.gaji_pokok) * 100;
      this.checkInputtedSalaryIsValid();
      this.$store.dispatch("setSalary", this.newSalary);
    },
    countPercent() {
      this.percent =
        (this.totalIncreasingSalary / this.DPBData.gaji_pokok) * 100;
      this.newSalary =
        parseInt(this.DPBData.gaji_pokok) +
        parseInt(this.totalIncreasingSalary);
      this.checkInputtedSalaryIsValid();
      this.$store.dispatch("setSalary", this.newSalary);
    },
    checkInputtedSalaryIsValid() {
      if (this.percent < 3 || this.percent > 50) {
        this.isMessageSalary = true;
        if (this.percent < 3) {
          this.messageSalary = "Persentase Kenaikan Gaji Terlalu Kecil";
        } else if (this.percent > 50) {
          this.messageSalary = "Persentase Kenaikan Gaji Terlalu Besar";
        }
        this.$store.dispatch("setSalaryInvalid", "invalid");
      } else {
        this.$store.dispatch("setSalaryInvalid", "valid");
        this.isMessageSalary = false;
      }
    },
  },
  data() {
    return {
      percent: (this.DPBData.gaji_baru / this.DPBData.gaji_pokok) * 100,
      totalIncreasingSalary: "",
      newSalary: this.DPBData.gaji_baru,
      salary: this.DPBData.gaji_pokok,
      value: "0",
      label: "",
      placeholder: " ",
      isMessageSalary: false,
      messageSalary: "",
      tunjangan2: "",
      tunjangan1: "",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: false,
      valueWhenIsEmpty: "",
      isMobile: false,
      options: {
        locale: "pt-BR",
        prefix: "Rp",
        suffix: "",
        length: 11,
        precision: 2,
      },
      properties: {
        hint: "",
        // You can add other v-text-field properties, here.
      },
    };
  },
};
</script>
